import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSchool } from "@fortawesome/free-solid-svg-icons";

import {
  SidebarIcon,
  SidebarIconEnrolment,
  SidebarIconAttendance,
} from "components/SidebarIcon";
import SidebarItem from "components/SidebarItem";
import SidebarDivider from "components/SidebarDivider";

import { getFilteredAttendanceTotal } from "helpers/attendance";
import { getStockStatus } from "helpers/stock";
import { getFilteredEnrolmentTotalOnDate } from "helpers/enrolment";
import {
  getCurrentSchoolYear,
  getAttendanceStores,
  getAllStores,
} from "data-handler/ducks/stores";
import {
  getCurrentSchool,
  getCurrentSchoolProfileCommoditiesWithHistory,
  getCurrentSchoolProfileCommodities,
  getCurrentSchoolProfileWfpCommodities,
} from "data-handler/ducks/schools";

import styles from "./stock.module.scss";
import {
  deliveryCategory,
  purchaseDetailCategory,
  takeHomeRationCategory,
} from "SCConstants";
import MeasureUnitValue from "../MeasureUnitValue";

const StockSidebar = () => {
  const params: {
    details: string;
    item: string;
    schoolId: string;
  } = useParams();
  const allStores = useSelector(getAllStores);
  const currentSchool = useSelector(getCurrentSchool);
  const currentSchoolYear = useSelector(getCurrentSchoolYear);
  const currentSchoolYearStartsOn = moment(currentSchoolYear?.starts_on);
  const currentSchoolYearEndsOn = moment(currentSchoolYear?.ends_on);

  const enrolmentTotal = useSelector(
    getFilteredEnrolmentTotalOnDate({ date: moment() })
  );
  const allAttendances = useSelector(getAttendanceStores);

  const currentSchoolProfileCommodities = useSelector(
    getCurrentSchoolProfileCommodities
  );

  const currentSchoolProfileWfpCommodities = useSelector(
    getCurrentSchoolProfileWfpCommodities
  );

  const category =
    params.details === "purchase"
      ? purchaseDetailCategory
      : params.details === "takeHomeRations"
      ? takeHomeRationCategory
      : deliveryCategory;

  const commoditiesListWithHistory = useSelector(
    getCurrentSchoolProfileCommoditiesWithHistory,
    category as any
  );

  const stockStatus = useSelector(
    getStockStatus(moment().format("YYYY-MM-DD"))
  );

  // TODO figure out if we need to only check wfp commodities or if we can actualy just show thr if there is any thr commodity present.
  // There are thr wfp commodities that have Nonzero values.
  const thrInStock = currentSchoolProfileWfpCommodities.some(
    (commodity: { id: number }) =>
      Number(stockStatus.takehomeration[commodity.id] || "0") > 0
  );
  // There are purchasedetail wfp commodities that have Nonzero values.
  // we are using wfp school profile commodities for thrinStock
  // but all school profile commodities for school purchase.
  // This was how it was before so I decided to keep same value to
  // not have any change on the app.
  const purchaseInStock = currentSchoolProfileCommodities.some(
    (commodity: { id: number }) =>
      Number(stockStatus.purchasedetail[commodity.id] || "0") > 0
  );

  const currentSchoolEnabledPurchases = useMemo(
    () => currentSchool?.profile?.enable_cash_purchases || purchaseInStock,
    [currentSchool, purchaseInStock]
  );

  const currentSchoolEnabledTHR = useMemo(
    () => currentSchool?.profile?.enable_take_home_rations || thrInStock,
    [currentSchool, thrInStock]
  );

  const checkCommodityHasActivityInCurrentSchoolYear = (
    commodity: any,
    category: any
  ) => {
    let commodityCategory: any;
    category === purchaseDetailCategory
      ? (commodityCategory = commodity.lastPurchaseDate)
      : category === deliveryCategory
      ? (commodityCategory = commodity.lastDeliveryDate)
      : category === takeHomeRationCategory
      ? (commodityCategory = commodity.lastTakeHomeRationsDate)
      : (commodityCategory = undefined);
    return (
      [
        commodityCategory,
        commodity.lastConsumptionDate,
        commodity.lastIncidentDate,
        commodity.lastTakeHomeRationsDate,
      ]
        .filter((date) => date !== undefined && commodityCategory !== undefined)
        .filter((date) =>
          moment(date).isBetween(
            currentSchoolYearStartsOn,
            currentSchoolYearEndsOn,
            "days",
            "[]"
          )
        ).length > 0 && currentSchoolYear !== undefined
    );
  };

  const filteredCommodityListWithHistory = (
    category:
      | typeof deliveryCategory
      | typeof takeHomeRationCategory
      | typeof purchaseDetailCategory
  ) =>
    commoditiesListWithHistory.filter((commodity: any) => {
      return (
        checkCommodityHasActivityInCurrentSchoolYear(commodity, category) ||
        Number(stockStatus[category][commodity.id] || "0") > 0
      );
    });

  const todaysAttendance = allAttendances.find((attendance) =>
    moment().isSame(attendance.occurred_on, "day")
  );
  const todaysAttendanceTotal =
    todaysAttendance &&
    getFilteredAttendanceTotal({ attendance: todaysAttendance });

  const stockPurchasedItems = useMemo(() => {
    return filteredCommodityListWithHistory(purchaseDetailCategory).map(
      (commodity: any) => {
        const selected =
          parseInt(params.item) === commodity.id &&
          params.details === "purchase";
        return (
          <SidebarItem
            key={commodity.id}
            selected={selected}
            id={commodity.client_id}
            icon={<SidebarIcon commodity={commodity.id} selected={selected} />}
            title={commodity.name}
            to={`/school/${params.schoolId}/stock/${commodity.id}/purchase`}
            subTitle={
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {stockStatus.purchasedetail[commodity.id]}
              </MeasureUnitValue>
            }
          ></SidebarItem>
        );
      }
    );
    // exhaustive-deps due to filteredCommodityListWithHistory which is not an essential dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, params.details, params.item, params.schoolId]);

  const stockTakeHomeRationsItems = useMemo(() => {
    return filteredCommodityListWithHistory(takeHomeRationCategory).map(
      (commodity: any) => {
        const selected =
          parseInt(params.item) === commodity.id &&
          params.details === "takeHomeRations";

        return (
          <SidebarItem
            key={commodity.id}
            selected={selected}
            id={commodity.client_id}
            icon={<SidebarIcon commodity={commodity.id} selected={selected} />}
            title={commodity.name}
            to={`/school/${params.schoolId}/stock/${commodity.id}/takeHomeRations`}
            subTitle={
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {stockStatus.takehomeration[commodity.id]}
              </MeasureUnitValue>
            }
          ></SidebarItem>
        );
      }
    );
    // exhaustive-deps due to filteredCommodityListWithHistory which is not an essential dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, params.item, params.details, params.schoolId]);

  const stockDeliveredItems = useMemo(() => {
    return filteredCommodityListWithHistory(deliveryCategory).map(
      (commodity: any) => {
        const selected =
          parseInt(params.item) === commodity.id &&
          params.details === "delivery";

        return (
          <SidebarItem
            key={commodity.id}
            selected={selected}
            id={commodity.client_id}
            icon={<SidebarIcon commodity={commodity.id} selected={selected} />}
            title={commodity.name}
            to={`/school/${params.schoolId}/stock/${commodity.id}/delivery`}
            subTitle={
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {stockStatus.delivery[commodity.id]}
              </MeasureUnitValue>
            }
            comment={
              <>
                {commodity.lastDelivery && (
                  <>
                    <FormattedMessage
                      id="StockSidebar.lastDelivery"
                      defaultMessage="last delivery"
                    />{" "}
                    {moment(commodity.lastDelivery.delivered_at).fromNow()}
                  </>
                )}
              </>
            }
          ></SidebarItem>
        );
      }
    );
    // exhaustive-deps due to filteredCommodityListWithHistory which is not an essential dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, params.item, params.details, params.schoolId]);

  return (
    <div>
      {currentSchool && (
        <div className={styles.stock__summary}>
          <div className={styles.stock__summary__icon}>
            <FontAwesomeIcon icon={faSchool} />
          </div>
          <div className={styles.stock__summary__name}>
            {currentSchool.name}
            <span>
              {currentSchool.legal_address_postal_code}{" "}
              {currentSchool.adm_1?.name}
            </span>
            <span>{currentSchool.adm_4?.name}</span>
          </div>
        </div>
      )}
      <SidebarDivider>
        <FormattedMessage
          id="StockSidebar.enrolmentDivider"
          defaultMessage="Enrolment"
        />
      </SidebarDivider>
      <SidebarItem
        icon={<SidebarIconAttendance />}
        to={`/school/${params.schoolId}/stock/overview`}
        selected={params.item === "overview"}
        title={
          <FormattedMessage
            id="StockSidebar.attendanceTitle"
            defaultMessage="Attendance"
          />
        }
        subTitle={
          todaysAttendanceTotal !== undefined && (
            <>
              <FormattedMessage
                id="StockSidebar.todaysAttendance"
                defaultMessage="Today's attendance:"
              />{" "}
              {todaysAttendanceTotal}
            </>
          )
        }
      ></SidebarItem>
      <SidebarItem
        icon={<SidebarIconEnrolment />}
        to={`/school/${params.schoolId}/stock/enrolment`}
        selected={params.item === "enrolment"}
        title={
          <FormattedMessage
            id="StockSidebar.enrolmentTitle"
            defaultMessage="Enrolment"
          />
        }
        subTitle={
          <>
            <FormattedMessage
              id="StockSidebar.currentEnrolment"
              defaultMessage="Current enrolment:"
            />{" "}
            {enrolmentTotal}
          </>
        }
      ></SidebarItem>
      <SidebarDivider>
        <FormattedMessage
          id="StockSidebar.stockWFPDivider"
          defaultMessage="Store (WFP)"
        />
      </SidebarDivider>
      {stockDeliveredItems}
      {currentSchoolEnabledPurchases && (
        <div>
          <SidebarDivider>
            <FormattedMessage
              id="StockSidebar.stockPurchasesDivider"
              defaultMessage="Store (Purchases)"
            />
          </SidebarDivider>
          {stockPurchasedItems}
        </div>
      )}
      {currentSchoolEnabledTHR && (
        <div>
          <SidebarDivider>
            <FormattedMessage
              id="StockSidebar.stockTHRDivider"
              defaultMessage="Store (Take Home Rations)"
            />
          </SidebarDivider>
          {stockTakeHomeRationsItems}
        </div>
      )}
    </div>
  );
};

export default StockSidebar;
