import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal, Blockquote } from "@wfp/ui";

import useStore from "helpers/useStore";
import { CommodityStatus, getDetailedStockStatus } from "helpers/stock";
import {
  deliveryCategory,
  purchaseDetailCategory,
  studentAttendanceCategory,
  takeHomeRationCategory,
} from "SCConstants";
import {
  isDeliveryStore,
  isPurchaseDetailStore,
  Store,
} from "data-handler/ducks/stores";
import { useSelector } from "react-redux";
import moment from "moment";

export type DeleteModalSection = "attendance" | "consumption" | undefined;

type DeleteModalStateProps = {
  state: boolean;
  setState: (state: boolean) => void;
  currentStoreData: Store;
  section: DeleteModalSection;
  customRedirectPath?: string;
};

const DeleteModal: React.ElementType<DeleteModalStateProps> = ({
  state,
  setState,
  currentStoreData,
  section,
  customRedirectPath = undefined,
}) => {
  const { updateStore, resetStore }: any = useStore();

  const intl = useIntl();

  const id =
    currentStoreData && currentStoreData.client_id
      ? currentStoreData.client_id
      : undefined;

  const onSubmit = () => {
    // Flag for partially deleting on-site attendance objects if only
    // deleting one section (when both levels and consumption are in the object)
    const onSiteAttendancePartialDeletion =
      currentStoreData.model === "attendance" &&
      currentStoreData.category !== takeHomeRationCategory &&
      currentStoreData.category !== studentAttendanceCategory &&
      currentStoreData.levels &&
      currentStoreData.consumption &&
      section !== undefined;

    currentStoreData.sync === false &&
    !currentStoreData.id &&
    !onSiteAttendancePartialDeletion
      ? resetStore(id, customRedirectPath)
      : updateStore(
          {
            id: id,
            values: currentStoreData,
            model: currentStoreData.model,
            type: "delete",
            section,
            category: currentStoreData.category,
          },
          customRedirectPath
        );
    setState(false);
  };

  const stockStatus = useSelector(
    getDetailedStockStatus(moment().format("YYYY-MM-DD"))
  );

  const getUndeletableStoreCommodities = (
    store: Store,
    commodityStatus: CommodityStatus,
    category: string
  ) => {
    const result: Set<number> = new Set();
    if (isDeliveryStore(store) || isPurchaseDetailStore(store)) {
      // All store commodities that has filtered category
      const originalCommodities = (store?.commodities || []).filter(
        (storeCommodity) => storeCommodity.category === category
      );

      for (const commodity of originalCommodities) {
        const commodityId = commodity.id;
        const finalQuantity = Number(commodityStatus[commodityId] || "0");
        const storeQuantity = Number(commodity.quantity);
        // If storeQuantity is larger than what we have right now,
        // that means some of the quantity is used. So we should not be able to
        // delete this commodity from this store. If we delete it, final quantity will be
        // negative.
        if (storeQuantity > finalQuantity) {
          result.add(commodityId);
        }
      }
    }
    return result;
  };

  // List of delivery commodities that cannot be deleted because if we delete them,
  // final quantity will be negative. They are used somewhere.
  const undeletableDeliveryCommodityIds: Set<number> = useMemo(() => {
    return getUndeletableStoreCommodities(
      currentStoreData,
      stockStatus.delivery,
      deliveryCategory
    );
  }, [currentStoreData, stockStatus.delivery]);

  // List of takehomeration commodities that cannot be deleted because if we delete them,
  // final quantity will be negative. They are used somewhere.
  const undeletableTHRCommodityIds: Set<number> = useMemo(() => {
    return getUndeletableStoreCommodities(
      currentStoreData,
      stockStatus.takehomeration,
      takeHomeRationCategory
    );
  }, [currentStoreData, stockStatus.takehomeration]);

  // List of purchase detail commodities that cannot be deleted because if we delete them,
  // if we delete them, final quantity will be negative. They are used somewhere.
  const undeletablePurchaseDetailCommodityIds: Set<number> = useMemo(() => {
    return getUndeletableStoreCommodities(
      currentStoreData,
      stockStatus.purchasedetail,
      purchaseDetailCategory
    );
  }, [currentStoreData, stockStatus.purchasedetail]);

  const isStoreUndeletable: boolean =
    undeletableTHRCommodityIds.size > 0 ||
    undeletableDeliveryCommodityIds.size > 0 ||
    undeletablePurchaseDetailCommodityIds.size > 0;

  return (
    <Modal
      open={state}
      modalHeading={intl.formatMessage({
        id: "sync.deleteTitle",
        defaultMessage: "Delete",
      })}
      primaryButtonText={intl.formatMessage({
        id: "Report.actionSubmit",
        defaultMessage: "Submit",
      })}
      secondaryButtonText={intl.formatMessage({
        id: "ModalExtended.cancel",
        defaultMessage: "Cancel",
      })}
      onSecondarySubmit={() => setState(false)}
      onRequestSubmit={() => onSubmit()}
      onRequestClose={() => setState(false)}
      danger={true}
      primaryButtonDisabled={isStoreUndeletable}
    >
      {!isStoreUndeletable && (
        <Blockquote kind="error">
          <p>
            <FormattedMessage
              id="sync.delete"
              defaultMessage="Are you sure you want to delete this {model}?"
              values={{ model: currentStoreData.model }}
            />
          </p>
        </Blockquote>
      )}
      {isStoreUndeletable && (
        <Blockquote kind="error">
          <p>
            <FormattedMessage
              id="sync.modelInUse"
              defaultMessage="This {model} has already been used, please delete relevant consumption and incident for this commodity"
              values={{ model: currentStoreData.model }}
            />
          </p>
        </Blockquote>
      )}
    </Modal>
  );
};

export default DeleteModal;
