import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";
import { isEmpty, difference } from "lodash";
import CreateableSelect from "react-select/creatable";

import {
  TextInput,
  RadioButtonGroup,
  Checkbox,
  Blockquote,
  RadioButton,
  Input,
  InlineLoading,
} from "@wfp/ui";
import { faWheatAwn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Empty from "components/Empty";
import ModalExtended from "components/ModalExtended";
import CommoditySelect from "components/CommoditySelect";
import { Repeater, RepeaterItem } from "components/Repeater";
import QuantityInput from "components/QuantityInput";
import DateInput from "components/DateInput";
import TimeInput from "components/TimeInput";
import CommoditiesAddButton from "components/RepeaterAddButton";
import QrCodeReader from "components/QrCodeReader";

import deliveryIssuesDuck from "data-handler/ducks/deliveryIssues";
import {
  clearBatchNumberOptions,
  getBatchNumbers,
  requestBatchNumbers,
  getBatchNumbersIsFetching,
} from "data-handler/ducks/batchNumbers";

import {
  checkDeliveryDatetimeIsUniquePerDatetime,
  Delivery,
  DeliveryCommodity,
  getAllStores,
  getCurrentSchoolYear,
  getPreviousSchoolYears,
} from "data-handler/ducks/stores";
import useStore from "helpers/useStore";

import {
  getCurrentSchool,
  getCurrentSchoolProfile,
  requestAllCommoditySources,
  getAllCommoditySources,
  getAllCommoditySourcesFetching,
} from "data-handler/ducks/schools";
import {
  deliveryCategory,
  selectContainerStyles,
  takeHomeRationCategory,
} from "SCConstants";
import ErrorList from "components/ErrorList";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getCurrentSchoolProfileWfpCommodities } from "../../data-handler/ducks/schools";

import {
  currentSchoolYearDateValidation,
  ERROR_MESSAGES_INCIDENTS,
  isReportApprovedOrSubmitted,
  isReportClosedOrRejected,
  schoolYearDateValidation,
} from "components/IncidentEdit";
import Camera from "components/Camera";
import OnlineOffline from "components/OnlineOffline";

import { RootState } from "data-handler/rootReducer";
import { CommoditySource } from "data-handler/ducks/schools2";
import { getCategory } from "helpers/category";
import { getLoggedInOffline } from "data-handler/ducks/auth";
import { getReportableMonthsBySyncables, YearMonth } from "components/Report";
import { getDetailedStockStatus } from "helpers/stock";

import "./_deliveries-edit.scss";

const unknownLabel = (
  <FormattedMessage id="DeliveriesEdit.unknownLabel" defaultMessage="Unknown" />
);

export const ERROR_MESSAGES = {
  missingCategory: (
    <FormattedMessage
      id="DeliveryEditError.missingCategory"
      defaultMessage="Select a category"
    />
  ),
  missingCommodity: (
    <FormattedMessage
      id="DeliveryEditError.missingCommodity"
      defaultMessage="Select a commodity"
    />
  ),
  missingDate: (
    <FormattedMessage
      id="DeliveryEditError.missingDate"
      defaultMessage="Enter delivery date"
    />
  ),
  missingTime: (
    <FormattedMessage
      id="DeliveryEditError.missingTime"
      defaultMessage="Enter delivery time"
    />
  ),
  missingQuantity: (
    <FormattedMessage
      id="DeliveryEditError.missingQuantity"
      defaultMessage="Enter a quantity"
    />
  ),
  negativeQuantity: (
    <FormattedMessage
      id="DeliveryEditError.negativeQuantity"
      defaultMessage="Modified quantity is invalid with current meal data"
    />
  ),
  usedCommodityRemoved: (
    <FormattedMessage
      id="DeliveryEditError.usedCommodityRemoved"
      defaultMessage="Commodity cannot be removed due to associated records"
    />
  ),
  missingBatchNo: (
    <FormattedMessage
      id="DeliveryEditError.missingBatchNo"
      defaultMessage="Select Batch Number"
    />
  ),
  missingWaybill: (
    <FormattedMessage
      id="DeliveryEditError.missingWaybill"
      defaultMessage="Required when you haven't selected the checkbox below"
    />
  ),
};

const makeNewCommodity = () => ({
  category: deliveryCategory,
  issues: [],
});

/** Handles validation for ALL form values */
const validationResolver = (
  values: any,
  {
    state,
    currentSchoolEnabledTHR,
    currentSchoolYearStore,
    previousSchoolYears,
    deliveryCurrentStoreData,
    stockStatus,
  }: any
) => {
  type Errors = {
    [fieldName: string]: {
      message: JSX.Element;
      requiredMessage?: JSX.Element;
    };
  };

  const isSelectedDateInSchoolYearValid = schoolYearDateValidation(
    values.date,
    currentSchoolYearStore,
    previousSchoolYears
  );

  const originalCommodities = deliveryCurrentStoreData?.commodities || [];
  const modifiedCommodities = values.commodities;

  /**
   * Returns the quantity change caused by the current edit
   * for given commodity id.
   */
  const getOriginalCommodityChange = (
    commodityId: number,
    category: string
  ) => {
    const original = originalCommodities.find(
      (c: { id: number; category: string }) =>
        c.id === commodityId && c.category === category
    );
    const modified = modifiedCommodities.find(
      (c: { id: number; category: string }) =>
        c.id === commodityId && c.category === category
    );
    const originalQuantity = original ? Number(original.quantity) : 0;
    const modifiedQuantity = modified ? Number(modified.quantity) : 0;
    const change = originalQuantity - modifiedQuantity;

    return change;
  };

  // List of commodity ids that has negative stock after their
  // quantity are updated or removed.
  const negativeStockDeliveryCommodities: Set<number> = new Set();
  for (const [commodityIdStr, latestQuantityStr] of Object.entries(
    stockStatus.delivery
  )) {
    const commodityId = Number(commodityIdStr);
    const latestQuantity = Number(latestQuantityStr);
    const quantityChange = getOriginalCommodityChange(
      commodityId,
      deliveryCategory
    );
    const resultQuantity = latestQuantity - quantityChange;
    if (resultQuantity < 0) {
      negativeStockDeliveryCommodities.add(commodityId);
    }
  }

  const negativeStockTHRCommodities: Set<number> = new Set();
  for (const [commodityIdStr, latestQuantityStr] of Object.entries(
    stockStatus.takehomeration
  )) {
    const commodityId = Number(commodityIdStr);
    const latestQuantity = Number(latestQuantityStr);
    const quantityChange = getOriginalCommodityChange(
      commodityId,
      takeHomeRationCategory
    );

    const resultQuantity = latestQuantity - quantityChange;
    if (resultQuantity < 0) {
      negativeStockTHRCommodities.add(commodityId);
    }
  }

  const errors: Errors = {};
  if (!isSelectedDateInSchoolYearValid && values.date) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["invalidDate"],
    };
  }
  if (isReportClosedOrRejected(state, values.date) && values.date) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["reportClosedOrRejected"],
    };
  }
  if (isReportApprovedOrSubmitted(state, values.date) && values.date) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["reportApprovedOrSubmitted"],
    };
  }
  if (!values.date) {
    errors[`date`] = {
      message: ERROR_MESSAGES["missingDate"],
    };
  }
  if (!values.time) {
    errors[`time`] = {
      message: ERROR_MESSAGES["missingTime"],
    };
  }
  if (!values.waybill_no && !values.waybill_info) {
    errors[`waybill_no`] = {
      message: ERROR_MESSAGES["missingWaybill"],
    };
  }

  // `commodity` is required on each commodity row
  values.commodities.forEach((commodity: any, i: number) => {
    if (currentSchoolEnabledTHR && !commodity.category) {
      errors["category"] = {
        message: ERROR_MESSAGES["missingCategory"],
        requiredMessage: ERROR_MESSAGES["missingCategory"],
      };
    }
    if (!commodity.commodity) {
      errors[`commodities[${i}].commodity`] = {
        message: ERROR_MESSAGES["missingCommodity"],
      };
    }

    // `quantity` is required on each commodity row
    if (!commodity.quantity) {
      errors[`commodities[${i}].quantity`] = {
        message: ERROR_MESSAGES["missingQuantity"],
      };
    }

    if (
      commodity.category === deliveryCategory &&
      negativeStockDeliveryCommodities.has(commodity.id)
    ) {
      errors[`commodities[${i}].quantity`] = {
        message: ERROR_MESSAGES["negativeQuantity"],
      };
    }

    if (
      commodity.category === takeHomeRationCategory &&
      negativeStockTHRCommodities.has(commodity.id)
    ) {
      errors[`commodities[${i}].quantity`] = {
        message: ERROR_MESSAGES["negativeQuantity"],
      };
    }

    if (!commodity.batch_no) {
      errors[`commodities[${i}].batch_no`] = {
        message: ERROR_MESSAGES["missingBatchNo"],
      };
    }
  });

  // Those are commodities that are used in a consumption or incident
  // and then removed from the delivery completely even though they are
  // already used.
  const removedUsedDeliveryCommodities = difference(
    Array.from(negativeStockDeliveryCommodities),
    values.commodities
      .filter(
        (deliveryCommodity: { category: string }) =>
          deliveryCommodity.category === deliveryCategory
      )
      .map((commodity: { id: number }) => commodity.id)
  );

  const removedUsedTHRCommodities = difference(
    Array.from(negativeStockTHRCommodities),
    values.commodities
      .filter(
        (thrCommodity: { category: string }) =>
          thrCommodity.category === takeHomeRationCategory
      )
      .map((commodity: { id: number }) => commodity.id)
  );

  if (
    !isEmpty(removedUsedDeliveryCommodities) ||
    !isEmpty(removedUsedTHRCommodities)
  ) {
    errors["commodities"] = {
      message: ERROR_MESSAGES["usedCommodityRemoved"],
    };
  }

  return {
    values: !isEmpty(errors) ? {} : values,
    errors,
  };
};

const storeToFormValues = (store: Delivery) => {
  const values: any = JSON.parse(JSON.stringify(store)); // deep copy
  // To properly map the delivery syncable to the form's default values,
  // we also have to reshape issues from a list of ints to an array of bools
  values.commodities.forEach((commodity: any) => {
    const issues: boolean[] = [];
    commodity.issues.forEach((issueId: number) => {
      issues[issueId] = true;
    });
    for (let i = 0; i < issues.length; i++) {
      issues[i] = issues[i] === true; // Empties receive `false`
    }
    commodity.issues = issues;
    const batch_no = commodity.batch_no;
    commodity.batch_no = commodity.batch_no
      ? { value: batch_no, label: batch_no }
      : {
          label: unknownLabel,
          value: "unknown",
        };
  });

  // Split `delivered_at` into `date` and `time` fields
  values.date = moment(values.delivered_at).format("YYYY-MM-DD");
  values.time = moment(values.delivered_at).format("HH:mm");

  values.waybill_info = values.waybill_no === "" ? true : false;

  return values;
};

const formValuesToStore = (
  values: any,
  deliveryIssues: { id: number }[],
  deliveryCurrentStoreData: Delivery,
  currentSchoolEnabledTHR: boolean,
  getValues: () => { commodities: { category: string }[] }
) => {
  // Convert issues to an array of ints (foreign keys)
  values.commodities.forEach((commodity: any) => {
    const issues = deliveryIssues
      .map((issue) =>
        commodity.issues[issue.id] === true ? issue.id : undefined
      )
      .filter((issue) => issue !== undefined);
    commodity.issues = issues;
    const batch_no = commodity.batch_no;
    commodity.batch_no =
      batch_no !== 0
        ? batch_no.hasOwnProperty("value")
          ? batch_no.value
          : commodity.batch_no
        : "unknown";

    commodity.category = currentSchoolEnabledTHR
      ? commodity.category
      : deliveryCategory;

    values.category = getCategory(getValues);

    values.waybill_info =
      values.waybill_no === "" || !values.waybill_no ? true : false;
  });

  // Merge `date` and `time` fields into `delivered_at` datetime
  values.delivered_at = moment(
    values.date + " " + values.time,
    "YYYY-MM-DD HH:mm"
  ).toISOString();
  delete values.date;
  delete values.time;

  values.is_initial_stock = deliveryCurrentStoreData?.is_initial_stock || false;
  values.is_negative_stock_rebalance =
    deliveryCurrentStoreData?.is_negative_stock_rebalance || false;
  return values;
};

type DeliveriesEditProps = {
  deliveryCurrentStoreData: Delivery;
};

const DeliveriesEdit: React.ComponentType<DeliveriesEditProps> = ({
  deliveryCurrentStoreData,
}) => {
  const {
    updateStore,
    closeModal,
    submitBtnRef,
    submitViaBtnRefClick,
  } = useStore();
  const state = useSelector((state) => state) as RootState;
  const location = useLocation();
  const urlParams = queryString.parse(location.search);
  const intl = useIntl();
  const dispatch = useDispatch();
  const deliveryIssues = useSelector(deliveryIssuesDuck.getList);
  const currentSchoolYearStore = useSelector(getCurrentSchoolYear);
  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  // TODO remove local state for selected commodity ids.
  const [selectedCommodityId, setSelectedCommodityId] = useState<string>();
  const [
    prevSelectedCommodityId,
    setPrevSelectedCommodityId,
  ] = useState<string>();

  const [initialBatchNosLoaded, setInitialBatchNosLoaded] = useState(false);

  const {
    adm_1,
    country: { iso2 },
  } = useSelector(getCurrentSchool);
  const currentSchool = useSelector(getCurrentSchoolProfile);
  const currentSchoolEnabledTHR = currentSchool.enable_take_home_rations;
  const batchNumbers = useSelector(getBatchNumbers);
  const batchNumberIsFetching = useSelector(getBatchNumbersIsFetching);

  const allStores = useSelector(getAllStores);
  const yearsMonths = useSelector((state: RootState) =>
    getReportableMonthsBySyncables(allStores, state)
  );

  const lastYearMonth = yearsMonths[yearsMonths.length - 1];
  let previousYearMonth: YearMonth | undefined = undefined;
  if (yearsMonths.length > 1) {
    previousYearMonth = yearsMonths[yearsMonths.length - 2];
  }
  // If previous month is in a different school year that means we are
  // at the begining of current school year. So previous month is undefined
  if (lastYearMonth.schoolYearId !== previousYearMonth?.schoolYearId) {
    previousYearMonth = undefined;
  }

  const stockStatus = useSelector(
    getDetailedStockStatus(moment().format("YYYY-MM-DD"))
  );

  // List of delivery commodities that cannot be deleted because if we delete them,
  // final quantity will be negative. They are used somewhere.
  const undeletableDeliveryCommodityIds: Set<number> = useMemo(() => {
    const result: Set<number> = new Set();
    const originalCommodities = (
      deliveryCurrentStoreData?.commodities || []
    ).filter(
      (deliveryCommodity) => deliveryCommodity.category === deliveryCategory
    );

    for (const commodity of originalCommodities) {
      const commodityId = commodity.id;
      const finalQuantity = Number(stockStatus.delivery[commodityId] || "0");
      const deliveryQuantity = Number(commodity.quantity);
      // If deliveryQuantity is larger than what we have right now,
      // that means some of the quantity is used. So we should not be able to
      // delete this commodity from delivery. If we delete it, final quantity will be
      // negative.
      if (deliveryQuantity > finalQuantity) {
        result.add(commodityId);
      }
    }
    return result;
  }, [deliveryCurrentStoreData, stockStatus.delivery]);

  // List of takehomeration commodities that cannot be deleted because if we delete them,
  // final quantity will be negative. They are used somewhere.
  const undeletableTHRCommodityIds: Set<number> = useMemo(() => {
    const result: Set<number> = new Set();
    const originalCommodities = (
      deliveryCurrentStoreData?.commodities || []
    ).filter(
      (deliveryCommodity) =>
        deliveryCommodity.category === takeHomeRationCategory
    );

    for (const commodity of originalCommodities) {
      const commodityId = commodity.id;
      const finalQuantity = Number(
        stockStatus.takehomeration[commodityId] || "0"
      );
      const thrQuantity = Number(commodity.quantity);
      // If thrQuantity is larger than what we have right now,
      // that means some of the quantity is used. So we should not be able to
      // delete this commodity from delivery. If we delete it, final quantity will be
      // negative.
      if (thrQuantity > finalQuantity) {
        result.add(commodityId);
      }
    }
    return result;
  }, [deliveryCurrentStoreData, stockStatus.takehomeration]);

  const defaultValues = deliveryCurrentStoreData
    ? storeToFormValues(deliveryCurrentStoreData)
    : { commodities: [makeNewCommodity()] };

  const methods = useForm({
    resolver: validationResolver,
    defaultValues: defaultValues,
    context: {
      state,
      deliveryCurrentStoreData,
      currentSchoolEnabledTHR,
      currentSchoolYearStore,
      previousSchoolYears,
      stockStatus,
    },
  });

  const {
    control,
    handleSubmit,
    errors,
    register,
    getValues,
    reset,
    setValue,
    watch,
  } = methods;

  const selectedDate = watch("date");
  const selectedTime = watch("time");
  const selectedInfo = watch("waybill_info");
  const commodities = watch("commodities");
  const isCommoditySelected = watch("commodities").find(
    (i: DeliveryCommodity) => i.commodity !== undefined
  );
  const isDeliveryUnique = useSelector(
    checkDeliveryDatetimeIsUniquePerDatetime(
      moment(`${selectedDate}T${selectedTime}`),
      deliveryCurrentStoreData
    )
  );

  const allCommoditySources: CommoditySource[] =
    useSelector(getAllCommoditySources) || [];

  const isAllCommoditySourcesFetching = useSelector(
    getAllCommoditySourcesFetching
  );

  const isOffline = useSelector(getLoggedInOffline);

  const categoryOptions = useMemo(
    () => {
      if (allCommoditySources.length === 0) {
        return [];
      }

      const allCommoditySourcesMap: {
        [categoryCode: string]: CommoditySource;
      } = {};
      for (let source of allCommoditySources) {
        allCommoditySourcesMap[source.code] = source;
      }

      const categoryOptionCodes = [deliveryCategory];

      if (currentSchoolEnabledTHR) {
        categoryOptionCodes.push(takeHomeRationCategory);
      }

      const commoditySources = categoryOptionCodes.map(
        (code: string) => allCommoditySourcesMap[code]
      );
      commoditySources.sort((a, b) => a.order - b.order);

      const categoryOptions = commoditySources.map(
        (commoditySource: CommoditySource) => ({
          value: commoditySource.code,
          name: commoditySource.code,
          label: commoditySource.name,
        })
      );

      return categoryOptions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allCommoditySources.length]
  );

  const isSelectedDateInSchoolYearValid = schoolYearDateValidation(
    selectedDate,
    currentSchoolYearStore!,
    previousSchoolYears
  );
  const isErrorListEmpty = isEmpty(errors);
  const currentSchoolYearDate = currentSchoolYearDateValidation(
    currentSchoolYearStore!,
    previousSchoolYears
  );
  const isInitialStock = deliveryCurrentStoreData?.is_initial_stock === true;

  useEffect(() => {
    if (!isOffline) {
      dispatch(requestAllCommoditySources());
    }
  }, [dispatch, isOffline]);

  const requestBatchNumbersForCommodities = () => {
    let commoditiesIds = commodities.map(
      (item: DeliveryCommodity) => item.commodity
    );
    commoditiesIds = commoditiesIds.filter(
      (item: DeliveryCommodity, index: number) =>
        commoditiesIds.indexOf(item) === index
    );

    if (!isOffline && commoditiesIds.length > 0) {
      dispatch(
        requestBatchNumbers(
          commoditiesIds,
          iso2,
          adm_1?.name,
          selectedDate?.slice(0, -3)
        )
      );
    }
  };

  useEffect(() => {
    // This happens only one time when the delivery page (only on edit) is opened
    // to load the batch numbers for the commodities already saved on the delivery
    if (deliveryCurrentStoreData && !initialBatchNosLoaded) {
      requestBatchNumbersForCommodities();
      setInitialBatchNosLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, initialBatchNosLoaded]);

  useEffect(() => {
    const batchNumberContainId =
      batchNumbers && batchNumbers.hasOwnProperty(selectedCommodityId)
        ? true
        : false;
    if (
      prevSelectedCommodityId !== selectedCommodityId &&
      !batchNumberContainId
    ) {
      setPrevSelectedCommodityId(selectedCommodityId);
      if (!isOffline) {
        dispatch(
          requestBatchNumbers(
            [selectedCommodityId],
            iso2,
            adm_1?.name,
            selectedDate?.slice(0, -3)
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, iso2, selectedCommodityId, batchNumbers, adm_1]);

  useEffect(() => {
    // When the selected date is changed, refetch the batch numbers according to the new date
    if (
      isCommoditySelected &&
      (!deliveryCurrentStoreData || initialBatchNosLoaded)
    ) {
      requestBatchNumbersForCommodities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedDate]);

  const { fields: commodityRows, append, remove } = useFieldArray({
    control,
    name: "commodities",
  });

  const onSubmit = (values: any) => {
    dispatch(clearBatchNumberOptions());
    updateStore({
      id:
        (deliveryCurrentStoreData && deliveryCurrentStoreData.client_id) ||
        undefined,
      values: formValuesToStore(
        values,
        deliveryIssues,
        deliveryCurrentStoreData,
        currentSchoolEnabledTHR,
        getValues
      ),
      model: "delivery",
      type:
        deliveryCurrentStoreData && deliveryCurrentStoreData.client_id
          ? "update"
          : "create",
      category: currentSchoolEnabledTHR
        ? getCategory(getValues)
        : deliveryCategory,
    });
  };

  const defaultBatchNumberOption = [{ label: unknownLabel, value: "unknown" }];

  const commoditiesListWfpFromSchoolProfile = useSelector(
    getCurrentSchoolProfileWfpCommodities
  );
  const commoditiesListWfpFromSchoolProfileIds = commoditiesListWfpFromSchoolProfile.map(
    (item: DeliveryCommodity) => item.id
  );

  return (
    <ModalExtended
      open
      onRequestSubmit={submitViaBtnRefClick}
      onRequestClose={() => closeModal()}
      modalLabel={intl.formatMessage({
        id: "DeliveriesEdit.delivery",
        defaultMessage: "Delivery",
      })}
      modalHeading={intl.formatMessage({
        id: "DeliveriesEdit.deliveryInformation",
        defaultMessage: "Delivery information",
      })}
      wide
      primaryButtonText={intl.formatMessage({
        id: "DeliveriesEdit.saveDelivery",
        defaultMessage: "Save delivery",
      })}
      primaryButtonDisabled={!isDeliveryUnique && !isInitialStock}
      secondaryButtonText={intl.formatMessage({
        id: "Form.cancel",
        defaultMessage: "Cancel",
      })}
      selectorPrimaryFocus={false}
      className="deliveries-edit"
    >
      {isAllCommoditySourcesFetching ? (
        <InlineLoading />
      ) : (
        <FormProvider {...methods}>
          {!isDeliveryUnique && (
            <Blockquote kind="warning" className="not-unique-error ">
              <p>
                <FormattedMessage
                  id="errorGeneric.deliveryDateTimeMustBeUnique"
                  defaultMessage="You can only create one delivery on a specific day and time. To add information, please edit the existing delivery."
                />
              </p>
            </Blockquote>
          )}
          {!isSelectedDateInSchoolYearValid && isErrorListEmpty && (
            <Blockquote kind="warning" className="date-not-valid">
              {ERROR_MESSAGES_INCIDENTS.invalidDate}
            </Blockquote>
          )}
          {isReportClosedOrRejected(state, selectedDate) && isErrorListEmpty && (
            <Blockquote kind="warning" className="date-not-valid">
              {ERROR_MESSAGES_INCIDENTS.reportClosedOrRejected}
            </Blockquote>
          )}
          {isReportApprovedOrSubmitted(state, selectedDate) &&
            isErrorListEmpty && (
              <Blockquote kind="warning" className="date-not-valid">
                {ERROR_MESSAGES_INCIDENTS.reportApprovedOrSubmitted}
              </Blockquote>
            )}
          {!selectedDate && !isCommoditySelected && (
            <Blockquote kind="warning" className="not-unique-error">
              <p>
                <FormattedMessage
                  id="errorGeneric.selectedDateCommodity"
                  defaultMessage="You need to select a date to see commodities options."
                />
              </p>
            </Blockquote>
          )}
          <ErrorList errors={errors} />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="wfp--form wfp--form-long"
          >
            <div className="waybill">
              <TextInput
                labelText={
                  <FormattedMessage
                    id="DeliveriesEdit.waybillNumber"
                    defaultMessage="Waybill number"
                  />
                }
                autoComplete="off"
                name="waybill_no"
                disabled={selectedInfo}
                inputRef={register}
                invalid={errors[`waybill_no`]}
                invalidText={errors[`waybill_no`]?.message}
              />
              <div className="checkboxWaybill">
                <label className="wfp--label">
                  <FormattedMessage
                    id="DeliveryEdit.waybillInfo"
                    defaultMessage="I don't have a waybill number"
                  />{" "}
                </label>
                <Controller
                  render={({ onChange, value }) => (
                    <Checkbox
                      className="checkbox"
                      name="waybill_info_checkbox"
                      id="waybill_info"
                      onChange={(e: BaseSyntheticEvent) => {
                        setValue(`waybill_no`, "", {
                          shouldDirty: true,
                        });
                        onChange(e.target.checked);
                      }}
                      checked={value || false}
                    />
                  )}
                  id="waybill_info"
                  name={`waybill_info`}
                  control={control}
                />
              </div>
            </div>
            <div className="wfp--form-item">
              <Controller
                control={control}
                render={({ onChange, value }) => (
                  <Camera onChange={onChange} image={value} />
                )}
                name={"picture"}
              />
            </div>
            <Controller
              as={
                <DateInput
                  labelText={
                    <FormattedMessage
                      id="DeliveriesEdit.deliveryDate"
                      defaultMessage="Date"
                    />
                  }
                  min={currentSchoolYearDate.min}
                  max={currentSchoolYearDate.max}
                />
              }
              name="date"
              invalid={errors.date}
              invalidText={errors.date && errors.date.message}
            />
            <TimeInput
              labelText={
                <FormattedMessage
                  id="DeliveriesEdit.deliveryTime"
                  defaultMessage="Time"
                />
              }
              type="time"
              name="time"
              inputRef={register({
                required: intl.formatMessage({
                  id: "DeliveriesEdit.deliveryError",
                  defaultMessage: "Enter time",
                }),
              })}
              invalid={errors.time}
              invalidText={errors.time && errors.time.message}
            />

            <>
              <div>
                {commodityRows.length === 0 && (
                  <Empty
                    title="No commodities added yet"
                    icon={<FontAwesomeIcon icon={faWheatAwn} size="1x" />}
                  >
                    <FormattedMessage
                      id="DeliveriesEdit.addaCommodityForThisDelivery"
                      defaultMessage="Add a commodity for this delivery"
                    />
                  </Empty>
                )}
                {errors.commodities && (
                  <Blockquote kind="error">
                    {errors.commodities.message}
                  </Blockquote>
                )}

                <Repeater>
                  {commodityRows.map((commodityRow, index) => (
                    <RepeaterItem
                      key={commodityRow.id}
                      removeAction={() => remove(index)}
                      disableRemove={
                        commodityRows.length === 1 ||
                        (commodityRow.commodity &&
                          deliveryCurrentStoreData &&
                          !commoditiesListWfpFromSchoolProfileIds.includes(
                            commodityRow.commodity
                          )) ||
                        (undeletableDeliveryCommodityIds.has(
                          Number(commodityRow.id)
                        ) &&
                          commodityRow.category === deliveryCategory) ||
                        (undeletableTHRCommodityIds.has(
                          Number(commodityRow.id)
                        ) &&
                          commodityRow.category === takeHomeRationCategory)
                      }
                      className="delivery-edit__commodities"
                      disableItem={
                        commodityRow.commodity &&
                        deliveryCurrentStoreData &&
                        !commoditiesListWfpFromSchoolProfileIds.includes(
                          commodityRow.commodity
                        )
                      }
                      extraTopRightChild={
                        <Controller
                          control={control}
                          render={({ onChange, value }) => (
                            <Camera onChange={onChange} image={value} />
                          )}
                          name={`commodities[${index}].picture`}
                        />
                      }
                    >
                      {/** This is here just so that the id for each entry in values.commodities is included */}
                      <>
                        <Controller
                          name={`commodities[${index}].id`}
                          control={control}
                        />
                        <Controller
                          name={`commodities[${index}].measure_unit`}
                          control={control}
                        />
                      </>

                      <Controller
                        render={({ onChange, value }) => (
                          <div
                            className={
                              currentSchoolEnabledTHR ? "container" : "disabled"
                            }
                          >
                            <RadioButtonGroup
                              labelText={
                                <FormattedMessage
                                  id="DeliveriesEdit.commoditySource"
                                  defaultMessage="Commodity Source"
                                />
                              }
                            >
                              {categoryOptions.map((category) => (
                                <RadioButton
                                  key={category.value}
                                  labelText={category.label}
                                  id={`commodities[${index}][${category.value}]`}
                                  name={`commodities[${index}].category`}
                                  value={category.value}
                                  checked={category.value === value}
                                  onChange={onChange}
                                />
                              ))}
                            </RadioButtonGroup>
                          </div>
                        )}
                        control={control}
                        rules={{ required: currentSchoolEnabledTHR }}
                        name={`commodities[${index}].category`}
                      />

                      <Controller
                        render={({ onBlur, onChange, value }) => {
                          return (
                            <CommoditySelect
                              isDisabled={!selectedDate ? true : false}
                              wfpOnly
                              className={"wfp--react-select-container" as any}
                              category={
                                currentSchoolEnabledTHR
                                  ? watch(`commodities[${index}].category`)
                                  : deliveryCategory
                              }
                              required
                              key={`commodities[${index}].commodity`}
                              onChange={(e: {
                                value: string;
                                measure_unit: any;
                              }) => {
                                onChange(e.value);
                                setSelectedCommodityId(e.value);
                                setValue(
                                  `commodities[${index}].measure_unit`,
                                  e.measure_unit,
                                  {
                                    shouldDirty: true,
                                  }
                                );
                              }}
                              onBlur={onBlur as any}
                              value={value}
                              setValue={setValue as any}
                              invalidOverride={
                                errors[`commodities[${index}].commodity`] !==
                                undefined
                              }
                              invalidTextOverride={
                                ERROR_MESSAGES_INCIDENTS["missingCommodity"]
                              }
                            />
                          );
                        }}
                        control={control}
                        name={`commodities[${index}].commodity`}
                      />
                      <Controller
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <QuantityInput
                              control={control}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              labelText={
                                <FormattedMessage
                                  id="DeliveryEdit.quantity"
                                  defaultMessage="Quantity"
                                />
                              }
                              setValue={setValue}
                              name={`commodities[${index}].quantity`}
                              invalid={errors[`commodities[${index}].quantity`]}
                              invalidText={
                                errors[`commodities[${index}].quantity`]
                                  ?.message
                              }
                              measureUnit={watch(
                                `commodities[${index}].measure_unit`
                              )}
                            />
                          );
                        }}
                        control={control}
                        name={`commodities[${index}].quantity`}
                      />

                      <RadioButtonGroup
                        labelText={
                          <FormattedMessage
                            id="DeliveriesEdit.qualityIssue"
                            defaultMessage="Quality issue"
                          />
                        }
                      >
                        {deliveryIssues.map((issue) => {
                          return (
                            <Controller
                              render={({ onChange, value }) => (
                                <Checkbox
                                  labelText={issue.description}
                                  id={`commodities[${index}].issues.${issue.id}`}
                                  checked={value}
                                  onChange={(e: BaseSyntheticEvent) =>
                                    onChange(e.target.checked)
                                  }
                                />
                              )}
                              name={`commodities[${index}].issues.${issue.id}`}
                            />
                          );
                        })}
                      </RadioButtonGroup>
                      <OnlineOffline
                        offlineChildren={
                          <Blockquote kind="warning">
                            <p>
                              <FormattedMessage
                                id="DeliveriesEdit.OfflineError"
                                defaultMessage="You are offline and unable to load batch numbers. Please type the batch number."
                              />
                            </p>
                          </Blockquote>
                        }
                      />

                      <Controller
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <Input
                              formItemClassName={classnames(
                                "wfp--form-item commodity-select",
                                "wfp--react-select-container"
                              )}
                              labelText={
                                <FormattedMessage
                                  id="DeliveriesEdit.batchNo"
                                  defaultMessage="Batch no."
                                />
                              }
                              name={`commodities[${index}].batch_no`}
                              invalid={
                                errors[`commodities[${index}].batch_no`] !==
                                undefined
                              }
                              invalidText={ERROR_MESSAGES.missingBatchNo}
                            >
                              {() => (
                                <div className="commodity-select__input">
                                  <CreateableSelect
                                    className="wfp--react-select-container"
                                    classNamePrefix="wfp--react-select"
                                    key={`commodities[${index}].batch_no`}
                                    styles={
                                      errors[
                                        `commodities[${index}].batch_no`
                                      ] !== undefined
                                        ? selectContainerStyles.invalid
                                        : selectContainerStyles.valid
                                    }
                                    onChange={(e) => onChange(e.value)}
                                    onBlur={onBlur}
                                    defaultValue={
                                      value?.value === 0
                                        ? defaultBatchNumberOption
                                        : value
                                    }
                                    options={
                                      batchNumbers.hasOwnProperty(
                                        getValues(
                                          `commodities[${index}].commodity`
                                        )
                                      )
                                        ? [
                                            ...defaultBatchNumberOption,
                                            ...batchNumbers[
                                              getValues(
                                                `commodities[${index}].commodity`
                                              )
                                            ].map(
                                              (e: {
                                                batch_number: string;
                                              }) => ({
                                                label: e.batch_number,
                                                value: e.batch_number,
                                              })
                                            ),
                                          ]
                                        : defaultBatchNumberOption
                                    }
                                    isDisabled={batchNumberIsFetching}
                                  />
                                </div>
                              )}
                            </Input>
                          );
                        }}
                        name={`commodities[${index}].batch_no`}
                        rules={{
                          required: true,
                        }}
                        invalid={errors[`commodities[${index}].batch_no`]}
                        invalidText={
                          errors[`commodities[${index}].batch_no`]?.message
                        }
                      />
                      <Controller
                        as={
                          <TextInput
                            maxLength={200}
                            labelText={
                              <>
                                <FormattedMessage
                                  id="DeliveriesEdit.comments"
                                  defaultMessage="Comments"
                                />{" "}
                                <FormattedMessage
                                  id="Form.optional"
                                  defaultMessage="(optional)"
                                />
                              </>
                            }
                          />
                        }
                        name={`commodities[${index}].comments`}
                      />
                    </RepeaterItem>
                  ))}
                </Repeater>
              </div>
              <CommoditiesAddButton
                onClick={() => {
                  append(makeNewCommodity());
                }}
                additional={
                  <QrCodeReader
                    getValues={getValues}
                    reset={reset}
                    qrCodeParam={urlParams["qrCode"] === "true" ? true : false}
                  />
                }
              />
            </>

            <button className="hidden-btn" ref={submitBtnRef as any} />
          </form>
        </FormProvider>
      )}
    </ModalExtended>
  );
};

export default DeliveriesEdit;
