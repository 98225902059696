import { getSelectedVendorName } from "components/CashToSchoolShow";
import moment from "moment";
import {
  getAllStores,
  getSchoolYearByDate,
  Store,
  DeliveryCommodity,
  PurchaseCommodity,
  Delivery,
  Incident,
  isDeliveryStore,
  isAttendanceStore,
  isPurchaseDetailStore,
  isIncidentStore,
  getStoreDate,
  getLastStartedSchoolYear,
  isDeletedStore,
  isStockMovementStore,
  isMealAttendanceStore,
  isTakeHomeRationAttendanceStore,
  compareByDateAndModel,
  StockMovementStore,
  MeasureUnit,
  Purchase,
} from "data-handler/ducks/stores";

import { RootState } from "data-handler/rootReducer";
import { store } from "data-handler/store";
import {
  deliveryCategory,
  mixedCategory,
  purchaseDetailCategory,
  takeHomeRationCategory,
} from "SCConstants";

// ************************************************************

/**
 * Adds 2 quantity values together using decimal precision of measure unit
 */
const addQuantity = (
  valueA: string,
  valueB: string,
  measureUnit: { id: number; name: string; symbol: string; decimals: number }
): string => (Number(valueA) + Number(valueB)).toFixed(measureUnit.decimals);

/**
 * Substract second value from the first one using decimal precision of measure unit
 * We are doing this because if value stays as a number it starts adding a floating point
 * error. So a value like 1.140 becomes 1.139999999.
 * If we turn the number into a string, floating point problems does not add up and we
 * stays on the right number.
 */
const subQuantity = (
  valueA: string,
  valueB: string,
  measureUnit: { id: number; name: string; symbol: string; decimals: number }
): string => (Number(valueA) - Number(valueB)).toFixed(measureUnit.decimals);

export type CommodityStatus = {
  [commodityId: string]: string;
};

export type StockStatus = {
  measureUnits: { [commodityId: string]: MeasureUnit };
  delivery: CommodityStatus;
  purchasedetail: CommodityStatus;
  takehomeration: CommodityStatus;
};

export const mergeStockMovements = (
  movementA: StockStatus,
  movementB: StockStatus
): StockStatus => {
  // merge measureUnits
  const measureUnits = { ...movementA.measureUnits, ...movementB.measureUnits };
  // merge delivery
  const delivery = { ...movementA.delivery };
  for (const commodityId of Object.keys(movementB.delivery)) {
    const quantityA = delivery[commodityId] || "0";
    const quantityB = movementB.delivery[commodityId];
    delivery[commodityId] = addQuantity(
      quantityA,
      quantityB,
      measureUnits[commodityId]
    );
  }
  // merge purchasedetail
  const purchasedetail = { ...movementA.purchasedetail };
  for (const commodityId of Object.keys(movementB.purchasedetail)) {
    const quantityA = purchasedetail[commodityId] || "0";
    const quantityB = movementB.purchasedetail[commodityId];
    purchasedetail[commodityId] = addQuantity(
      quantityA,
      quantityB,
      measureUnits[commodityId]
    );
  }
  // merge takehomeration
  const takehomeration = { ...movementA.takehomeration };
  for (const commodityId of Object.keys(movementB.takehomeration)) {
    const quantityA = takehomeration[commodityId] || "0";
    const quantityB = movementB.takehomeration[commodityId];
    takehomeration[commodityId] = addQuantity(
      quantityA,
      quantityB,
      measureUnits[commodityId]
    );
  }

  return {
    measureUnits,
    delivery,
    purchasedetail,
    takehomeration,
  };
};

/**
 * getStockMovement function returns stock movement by commodity id.
 * getDetailedStockMovement function returns stock movement by deliverycommodity_id and purchasedetail_commodity_id
 * What that means is it calculate stock status for each delivery seperately.
 */
export const getDetailedStockMovement = (
  store: StockMovementStore
): StockStatus => {
  const result: StockStatus = {
    measureUnits: {},
    delivery: {},
    purchasedetail: {},
    takehomeration: {},
  };

  if (isDeliveryStore(store)) {
    /*
     * - Delivery can have 2 kind of commodities inside.
     *   1) normal delivery commodity
     *   2) thr delivery commodity
     */
    for (const commodity of store.commodities) {
      const commodityId: string | undefined = commodity.id?.toString();
      // If commodity is just being created,
      // It might not have an id.
      // In that case ignore commodity.
      if (commodityId === undefined) {
        continue;
      }
      const commodityQuantity = commodity.quantity;
      const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
      const commodityCategory: string = commodity.category;
      if (commodityCategory === deliveryCategory) {
        // Update current delivery stock for this commodity
        const currentStock = result.delivery[commodityId] || "0";
        result.delivery[commodityId] = addQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      } else if (commodityCategory === takeHomeRationCategory) {
        // Update current thr stock for this commodity
        const currentStock = result.takehomeration[commodityId] || "0";
        result.takehomeration[commodityId] = addQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      }
    }
  } else if (isPurchaseDetailStore(store)) {
    for (const commodity of store.commodities) {
      const commodityId: string = commodity.id?.toString();
      // If commodity is just being created,
      // It might not have an id.
      // In that case ignore commodity.
      if (commodityId === undefined) {
        continue;
      }
      const commodityQuantity: string = commodity.quantity;
      const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
      const currentStock = result.purchasedetail[commodityId] || "0";

      result.purchasedetail[commodityId] = addQuantity(
        currentStock,
        commodityQuantity,
        commodityMeasureUnit
      );

      result.measureUnits[commodityId] = commodityMeasureUnit;
    }
  } else if (isAttendanceStore(store)) {
    if (isMealAttendanceStore(store)) {
      /*
       * Meal attendance has consumption inside and consumption
       * can have 4 kinds of commodities
       * 1) normal delivery commodity consumption.
       * 2) purchase detail commodity consumption.
       * 3) take home ration commodity consumption.
       * 4) non-wfp commodity consumption. This version is not added to stock since they are used in place.
       */
      for (const commodity of store?.consumption?.commodities || []) {
        const addCommodity = (
          commodityId: string,
          commodityQuantity: string
        ) => {
          const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
          const commodityCategory: string = commodity.category;

          if (commodityCategory === deliveryCategory) {
            const currentStock = result.delivery[commodityId] || "0";
            result.delivery[commodityId] = subQuantity(
              currentStock,
              commodityQuantity,
              commodityMeasureUnit
            );

            result.measureUnits[commodityId] = commodityMeasureUnit;
          } else if (commodityCategory === purchaseDetailCategory) {
            const currentStock = result.purchasedetail[commodityId] || "0";
            result.purchasedetail[commodityId] = subQuantity(
              currentStock,
              commodityQuantity,
              commodityMeasureUnit
            );
            result.measureUnits[commodityId] = commodityMeasureUnit;
          } else if (commodityCategory === takeHomeRationCategory) {
            const currentStock = result.takehomeration[commodityId] || "0";
            result.takehomeration[commodityId] = subQuantity(
              currentStock,
              commodityQuantity,
              commodityMeasureUnit
            );
            result.measureUnits[commodityId] = commodityMeasureUnit;
          }
          // We ignore commodity if it is non-wfp since they do not get added to stock.
        }; // addCommodity
        // Add deliveryCommodities
        const deliveryCommodities = commodity.delivery_commodities || {};
        for (const commodityId of Object.keys(deliveryCommodities)) {
          const commodityQuantity = deliveryCommodities[commodityId];
          addCommodity(commodityId, commodityQuantity.toString());
        }
        // Add purchaseDetailCommodities
        const purchaseDetailCommodities =
          commodity.purchase_detail_commodities || {};
        for (const commodityId of Object.keys(purchaseDetailCommodities)) {
          const commodityQuantity = purchaseDetailCommodities[commodityId];
          addCommodity(commodityId, commodityQuantity.toString());
        }
      }
    } else if (isTakeHomeRationAttendanceStore(store)) {
      // Take home ration attendance store only has single kind of consumption type
      // we just add them as consumption to thr stock type.
      for (const commodity of store?.consumption?.commodities || []) {
        // TODO: Currently this is not working as thr commodities are missing.
        // delivery link. this should start working after thr commodities are fixed.
        // https://dev.azure.com/worldfoodprogramme/SchoolMeals/_workitems/edit/280619
        const deliveryCommodities = commodity.delivery_commodities || {};
        for (const commodityId of Object.keys(deliveryCommodities)) {
          const commodityQuantity = deliveryCommodities[commodityId];
          const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
          const currentStock = result.takehomeration[commodityId] || "0";

          result.takehomeration[commodityId] = subQuantity(
            currentStock,
            commodityQuantity.toString(),
            commodityMeasureUnit
          );
          result.measureUnits[commodityId] = commodityMeasureUnit;
        }
      }
    }
    // We ignore student attendance store since it does not have any consumption on it.
  } else if (isIncidentStore(store)) {
    /*
     * Incidents has 3 kind of entries.
     *   1) normal delivery incident.
     *   2) takehomeration delivery incident.
     *   3) purchase detail incident.
     */

    for (const commodity of store?.commodities || []) {
      const commodityQuantity: string = commodity.quantity;
      const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
      const commodityCategory: string = commodity.category;

      if (commodityCategory === deliveryCategory) {
        const commodityId: string = commodity.delivery_commodity!.toString();
        const currentStock = result.delivery[commodityId] || "0";
        result.delivery[commodityId] = subQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      } else if (commodityCategory === purchaseDetailCategory) {
        const commodityId: string = commodity.purchase_detail_commodity!.toString();
        const currentStock = result.purchasedetail[commodityId] || "0";
        result.purchasedetail[commodityId] = subQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      } else if (commodityCategory === takeHomeRationCategory) {
        const commodityId: string = commodity.delivery_commodity!.toString();
        const currentStock = result.takehomeration[commodityId] || "0";
        result.takehomeration[commodityId] = subQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      }
    }
  }
  return result;
};

/**
 * Get stock movement for a single store.
 */
export const getStockMovement = (store: StockMovementStore): StockStatus => {
  const result: StockStatus = {
    measureUnits: {},
    delivery: {},
    purchasedetail: {},
    takehomeration: {},
  };

  if (isDeliveryStore(store)) {
    /*
     * - Delivery can have 2 kind of commodities inside.
     *   1) normal delivery commodity
     *   2) thr delivery commodity
     */
    for (const commodity of store.commodities) {
      const commodityId: string = commodity.commodity.toString();
      const commodityQuantity = commodity.quantity;
      const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
      const commodityCategory: string = commodity.category;
      if (commodityCategory === deliveryCategory) {
        // Update current delivery stock for this commodity
        const currentStock = result.delivery[commodityId] || "0";
        result.delivery[commodityId] = addQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      } else if (commodityCategory === takeHomeRationCategory) {
        // Update current thr stock for this commodity
        const currentStock = result.takehomeration[commodityId] || "0";
        result.takehomeration[commodityId] = addQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      }
    }
  } else if (isPurchaseDetailStore(store)) {
    for (const commodity of store.commodities) {
      const commodityId: string = commodity.commodity.toString();
      const commodityQuantity: string = commodity.quantity;
      const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
      const currentStock = result.purchasedetail[commodityId] || "0";

      result.purchasedetail[commodityId] = addQuantity(
        currentStock,
        commodityQuantity,
        commodityMeasureUnit
      );

      result.measureUnits[commodityId] = commodityMeasureUnit;
    }
  } else if (isAttendanceStore(store)) {
    if (isMealAttendanceStore(store)) {
      /*
       * Meal attendance has consumption inside and consumption
       * can have 4 kinds of commodities
       * 1) normal delivery commodity consumption.
       * 2) purchase detail commodity consumption.
       * 3) take home ration commodity consumption.
       * 4) non-wfp commodity consumption. This version is not added to stock since they are used in place.
       */
      for (const commodity of store?.consumption?.commodities || []) {
        const commodityId: string = commodity.commodity.toString();
        const commodityQuantity = commodity.quantity;
        const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
        const commodityCategory: string = commodity.category;

        if (commodityCategory === deliveryCategory) {
          const currentStock = result.delivery[commodityId] || "0";
          result.delivery[commodityId] = subQuantity(
            currentStock,
            commodityQuantity,
            commodityMeasureUnit
          );

          result.measureUnits[commodityId] = commodityMeasureUnit;
        } else if (commodityCategory === purchaseDetailCategory) {
          const currentStock = result.purchasedetail[commodityId] || "0";
          result.purchasedetail[commodityId] = subQuantity(
            currentStock,
            commodityQuantity,
            commodityMeasureUnit
          );
          result.measureUnits[commodityId] = commodityMeasureUnit;
        } else if (commodityCategory === takeHomeRationCategory) {
          const currentStock = result.takehomeration[commodityId] || "0";
          result.takehomeration[commodityId] = subQuantity(
            currentStock,
            commodityQuantity,
            commodityMeasureUnit
          );
          result.measureUnits[commodityId] = commodityMeasureUnit;
        }
        // We ignore commodity if it is non-wfp since they do not get added to stock.
      }
    } else if (isTakeHomeRationAttendanceStore(store)) {
      // Take home ration attendance store only has single kind of consumption type
      // we just add them as consumption to thr stock type.
      for (const commodity of store?.consumption?.commodities || []) {
        const commodityId: string = commodity.commodity.toString();
        const commodityQuantity: string = commodity.quantity;
        const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
        const currentStock = result.takehomeration[commodityId] || "0";

        result.takehomeration[commodityId] = subQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      }
    }
    // We ignore student attendance store since it does not have any consumption on it.
  } else if (isIncidentStore(store)) {
    /*
     * Incidents has 3 kind of entries.
     *   1) normal delivery incident.
     *   2) takehomeration delivery incident.
     *   3) purchase detail incident.
     */

    for (const commodity of store?.commodities || []) {
      const commodityId: string = commodity.commodity.toString();
      const commodityQuantity: string = commodity.quantity;
      const commodityMeasureUnit: MeasureUnit = commodity.measure_unit;
      const commodityCategory: string = commodity.category;

      if (commodityCategory === deliveryCategory) {
        const currentStock = result.delivery[commodityId] || "0";
        result.delivery[commodityId] = subQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      } else if (commodityCategory === purchaseDetailCategory) {
        const currentStock = result.purchasedetail[commodityId] || "0";
        result.purchasedetail[commodityId] = subQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      } else if (commodityCategory === takeHomeRationCategory) {
        const currentStock = result.takehomeration[commodityId] || "0";
        result.takehomeration[commodityId] = subQuantity(
          currentStock,
          commodityQuantity,
          commodityMeasureUnit
        );
        result.measureUnits[commodityId] = commodityMeasureUnit;
      }
    }
  }
  return result;
};

const getStockMovementStores = (date: string) => (
  state: RootState
): StockMovementStore[] => {
  const schoolYear = getLastStartedSchoolYear(date)(state);

  if (!schoolYear) {
    return [];
  }

  const allStores = getAllStores(state);
  const startDate = schoolYear.starts_on;
  const endDate = date;
  // All stock stores between start of last school year and given date.
  const filteredStockStores = allStores
    .filter(isStockMovementStore)
    .filter(
      (s: StockMovementStore) =>
        !isDeletedStore(s) &&
        moment(getStoreDate(s)).isBetween(startDate, endDate, "days", "[]")
    )
    .sort(compareByDateAndModel);

  return filteredStockStores;
};

/**
 * Calculates stock status of current date by tallying all stock movement stores.
 * Stock movement stores can be delivery, purchasedetail, attendance or incident.
 * Delivery and purchase detail are plus stock movements.
 * Consumption and incidents are minus stock movements.
 *
 * Here is all commodity types those stores might have:
 * - Delivery can have 2 kind of commodities inside.
 *   1) normal delivery commodity
 *   2) thr delivery commodity
 * - Purchase detail can have only one kind of commodity.(purchase detail)
 * - Consumptions are stored in attendance and then can have 4 kinds of commodities.
 *   1) normal delivery commodity consumption.
 *   2) purchase detail commodity consumption.
 *   3) take home ration commodity consumption.
 *   4) non-wfp commodity consumption. This version is not added to stock since they are used in place.
 * - Incidents has 3 kind of commodities.
 *   1) normal delivery incident commodity.
 *   2) takehomeration delivery incident commodity.
 *   3) purchase detail incident commodity.
 *   This function will return 3 types of stock statuses.
 *   - delivery stock status
 *   - purchase detail stock status
 *   - take home ration stock status
 */
export const getStockStatus = (date: string) => (
  state: RootState
): StockStatus => {
  const stockMovementStores = getStockMovementStores(date)(state);

  let result: StockStatus = {
    measureUnits: {},
    delivery: {},
    purchasedetail: {},
    takehomeration: {},
  };

  for (const store of stockMovementStores) {
    const stockMovement = getStockMovement(store);
    result = mergeStockMovements(result, stockMovement);
  }
  return result;
};

/*
 * Calculates stock values for each delivery seperately.
 * Difference between getStockStatus and getDetailedStockStatus is
 * in getStockStatus, commodity_id is normal commodity id and
 * in getDetailedStockStatus commodity_id is delivery_commodity_id or purchase_detail_commodity_id
 */
export const getDetailedStockStatus = (date: string) => (
  state: RootState
): StockStatus => {
  const stockMovementStores = getStockMovementStores(date)(state);

  let result: StockStatus = {
    measureUnits: {},
    delivery: {},
    purchasedetail: {},
    takehomeration: {},
  };

  for (const store of stockMovementStores) {
    const stockMovement = getDetailedStockMovement(store);
    result = mergeStockMovements(result, stockMovement);
  }
  return result;
};

// ************************************************************

function sortByDateAndModel(a: Store, b: Store): number {
  // Function used to sort stores by date and model
  const dateA =
    (a as any).occurred_on ||
    (a as any).occurred_at ||
    (a as any).purchased_at ||
    (a as any).delivered_at;

  const dateB =
    (b as any).occurred_on ||
    (b as any).occurred_at ||
    (b as any).purchased_at ||
    (b as any).delivered_at;

  const modelA = a.model;
  const modelB = b.model;

  if (moment(dateA).isAfter(dateB, "days")) return 1;
  if (moment(dateA).isBefore(dateB, "days")) return -1;
  // if the date is the same, prioritize delivery or purchasedetail models
  if (
    ["delivery", "purchasedetail"].includes(modelA) &&
    !["delivery", "purchasedetail"].includes(modelB)
  )
    return -1;
  return 0;
}

const getFilteredDeliveriesOrPuchasesWithCommodities = (
  stores: Store[],
  commodityId: number,
  category: string,
  startDate: moment.MomentInput,
  endDate: moment.MomentInput
) => {
  return stores.filter(
    (store) =>
      (store.model === "delivery" || store.model === "purchasedetail") &&
      store.type !== "delete" &&
      moment(
        (store as any).delivered_at || (store as any).purchased_at
      ).isBetween(startDate, endDate, "days", "[]") &&
      store.commodities &&
      (store.commodities as (DeliveryCommodity | PurchaseCommodity)[]).find(
        (commodity: DeliveryCommodity | PurchaseCommodity) => {
          return (
            commodity.commodity === commodityId &&
            commodity.category === category
          );
        }
      )
  );
};

const sortDeliveryOrPurchaseStores = (stores: Store[], order = "ascending") => {
  return stores.sort((a, b) => {
    const tempStoreA = (a as any)?.delivered_at || (a as any)?.purchased_at;
    const tempStoreB = (b as any)?.delivered_at || (a as any)?.purchased_at;
    return order === "descending"
      ? moment(tempStoreA).isAfter(tempStoreB)
        ? -1
        : 1
      : moment(tempStoreA).isAfter(tempStoreB)
      ? 1
      : -1;
  });
};

const getFilteredStoresWithConsumptionAndIncidents = (
  deliveryCommodityId: number,
  category: string,
  startDate: moment.MomentInput,
  endDate: moment.MomentInput,
  stores: Store[]
) => {
  const commodityIdKey =
    category === deliveryCategory
      ? "delivery_commodity"
      : "purchase_detail_commodity";
  return stores
    .filter((store) => {
      const commodities =
        (store as any).commodities ||
        ((store as any).consumption &&
          (store as any).consumption.commodities) ||
        [];

      return (
        store.type !== "delete" &&
        moment(
          (store as any).occurred_on || (store as any).occurred_at
        ).isBetween(startDate, endDate, "days", "[]") &&
        commodities &&
        commodities.find((commodity: DeliveryCommodity | PurchaseCommodity) => {
          if (store.model === "attendance") {
            return true;
          } else if (
            store.model === "incident" &&
            deliveryCommodityId !== undefined
          ) {
            return (
              (commodity as any)[commodityIdKey] === deliveryCommodityId &&
              commodity.category === category
            );
          } else {
            return false;
          }
        })
      );
    })
    .sort(sortByDateAndModel);
};

const getQuantityUsed = (
  stores: Store[],
  deliveryCommodityId: number,
  commodityId: number,
  category: string,
  allowConsumptionsNotMatched = false
) => {
  let quantityUsed = 0;

  // Stores should only contain incidents and consumptions at this point
  stores.forEach((store) => {
    const commodities: (DeliveryCommodity | PurchaseCommodity)[] =
      (store as any).commodities ||
      ((store as any).consumption && (store as any).consumption.commodities);

    const commodityIdKeyEnding = store.model === "attendance" ? "ies" : "y";

    const commodityIdKey =
      category === deliveryCategory
        ? `delivery_commodit${commodityIdKeyEnding}`
        : `purchase_detail_commodit${commodityIdKeyEnding}`;

    commodities
      // We only care about the quantity used for the given commodity
      .filter(
        (item) => item.commodity === commodityId && item.category === category
      )
      .forEach((item) => {
        if (store.model === "attendance") {
          // Consumption case
          if (allowConsumptionsNotMatched) {
            quantityUsed = parseFloat(
              (quantityUsed + parseFloat(item.quantity)).toFixed(10)
            );
          } else if (
            !allowConsumptionsNotMatched &&
            Object.keys(item).includes(commodityIdKey) &&
            Object.keys((item as any)[commodityIdKey]).includes(
              deliveryCommodityId?.toString()
            )
          ) {
            quantityUsed = parseFloat(
              (
                quantityUsed +
                parseFloat(
                  (item as any)[commodityIdKey][deliveryCommodityId.toString()]
                )
              ).toFixed(10)
            );
          }
        } else if (
          store.model === "incident" &&
          Object.keys(item).includes(commodityIdKey) &&
          (item as any)[commodityIdKey] === deliveryCommodityId
        ) {
          // Incident case
          quantityUsed = parseFloat(
            (quantityUsed + parseFloat(item.quantity)).toFixed(10)
          );
        }
      });
  });
  return quantityUsed;
};

/**
 * Returns the Deliveries (List) in Stock for the given commodityId
 */
export const deliveriesInStockByCommodityByCategory = (
  stores: Store[],
  commodityId: number,
  category: string,
  deliveryCommodityId: string | number | undefined = undefined,
  startDate = moment().format("YYYY-MM-DD"),
  endDate = moment().format("YYYY-MM-DD")
) => {
  const state = store.getState();
  const schoolYear = getSchoolYearByDate(startDate)(state);

  const deliveries: Store[] = [];
  // filter to stores which deal with commodities, particularly with commodityId
  const filteredDeliveries = getFilteredDeliveriesOrPuchasesWithCommodities(
    stores,
    commodityId,
    category,
    schoolYear?.starts_on,
    endDate
  );

  // Get the deliveries, sorted
  const sortedArray = sortDeliveryOrPurchaseStores(filteredDeliveries);

  // Only keep the deliveries that have stock for the given commodityId
  sortedArray
    .filter((item) =>
      moment(
        (item as any).delivered_at || (item as any).purchased_at
      ).isSameOrBefore(startDate, "days")
    )
    .forEach((store, index) => {
      ((store as any).commodities as (DeliveryCommodity | PurchaseCommodity)[])
        .filter(
          (item) => item.commodity === commodityId && item.category === category
        )
        .forEach(({ quantity, id, batch_no, commodity, measure_unit }) => {
          const consumptionsAndIncidentStores = getFilteredStoresWithConsumptionAndIncidents(
            id,
            category,
            store.model === "delivery"
              ? store.delivered_at
              : (store as any).purchased_at,
            endDate,
            stores.filter(
              (item) => item.model === "attendance" || item.model === "incident"
            )
          );

          const consumptionsUnsynced =
            (index >= 1 || (index === 0 && sortedArray.length > 1)) &&
            consumptionsAndIncidentStores.find(
              (item) => item.model === "attendance" && item.sync === false
            )
              ? true
              : false;

          let quantityUsed = getQuantityUsed(
            consumptionsAndIncidentStores,
            id as any,
            commodityId,
            category,
            index === 0 && sortedArray.length === 1
          );

          // Return deliveries with quantity available 0 if the delivery commodity id
          // matches the delivery commodity id param
          if (
            (deliveryCommodityId &&
              (deliveryCommodityId as unknown) === id &&
              parseFloat(quantity) >= parseFloat(quantityUsed as any)) ||
            ((!deliveryCommodityId ||
              (deliveryCommodityId &&
                deliveryCommodityId !== (id as unknown))) &&
              parseFloat(quantity) > parseFloat(quantityUsed as any))
          ) {
            deliveries.push({
              waybill_no: (store as any).waybill_no,
              vendor_category: (store as any).vendor_category,
              delivered_at: (store as any).delivered_at,
              purchased_at: (store as any).purchased_at,
              sync: (store as any).sync,
              objectCommodityId: id,
              commodityId: commodity,
              consumptionsUnsynced,
              quantityAvailable: (quantity as any) - quantityUsed,
              measure_unit: measure_unit,
              batch_no: batch_no,
            } as any);
          }
        });
    });
  return sortDeliveryOrPurchaseStores(deliveries, "descending");
};

/**
 * Takes in a ID from a commodity delivery, returns the delivery
 */
export const getDeliveryByCommodityAndDeliveryID = (
  stores: Store[],
  commodityDeliveryID: number
) => {
  return stores
    .filter(
      (store) =>
        store.model === "delivery" &&
        store.commodities &&
        store.type !== "delete"
    )
    .find((store) => {
      return (store as any).commodities.find(
        (e: PurchaseCommodity | DeliveryCommodity) =>
          e.id === commodityDeliveryID
      );
    });
};
/**
 * Takes in a ID from a commodity delivery, returns the delivery
 */
export const getPurchaseByCommodityPurchaseID = (
  stores: Store[],
  commodityPurchaseID: number
) => {
  return stores
    .filter(
      (store) =>
        store.model === "purchasedetail" &&
        store.commodities &&
        store.type !== "delete"
    )
    .find((store) => {
      return ((store as any).commodities as (
        | PurchaseCommodity
        | DeliveryCommodity
      )[]).find((e) => e.id === commodityPurchaseID);
    });
};

/**
 * Takes in a ID from a commodity delivery, returns the waybill of the delivery
 */
export const getWaybillByCommodityAndDeliveryID = (
  stores: Store[],
  commodityDeliveryID: number
) => {
  return (getDeliveryByCommodityAndDeliveryID(
    stores,
    commodityDeliveryID
  ) as any)?.waybill_no;
};
/**
 * Takes in a ID from a commodity purchase and a list of vendorCategories
 * and returns the name of the corresponding purchase
 */
export const getPurchaseNameByCommodityPurchaseID = (
  stores: Store[],
  commodityPurchaseID: number,
  vendorCategories: string[]
) => {
  const purchase = getPurchaseByCommodityPurchaseID(
    stores,
    commodityPurchaseID
  );
  const vendorCategoryName = getSelectedVendorName(
    (purchase as any)?.vendor_category,
    vendorCategories
  );

  return `${vendorCategoryName}: ${moment(
    (purchase as any)?.purchased_at
  ).format("dddd, DD.MM.YYYY")}`;
};

export const getCommoditiesInStoreByCategory = (
  stores: Store[],
  commoditiesIndex: { [x: string]: any },
  isWfp: boolean,
  category: string,
  date: string | undefined
) => {
  let startDate: string | undefined = undefined;
  let endDate: string | undefined = undefined;
  if (date) {
    const state = store.getState();
    const schoolYear = getSchoolYearByDate(date)(state);
    startDate = schoolYear?.starts_on;
    endDate = date;
  }
  const filteredStores = stores.filter(
    (store) =>
      (store.category === category || store.category === mixedCategory) &&
      (store as any).commodities &&
      (startDate && endDate
        ? moment(
            (store as any).delivered_at ||
              (store as any).occurred_at ||
              (store as any).occurred_on ||
              (store as any).purchased_at
          ).isBetween(startDate, endDate, "days", "[]")
        : true)
  );
  let commoditiesList: number[] = [];
  filteredStores.forEach((item) => {
    ((item as any).commodities as (
      | PurchaseCommodity
      | DeliveryCommodity
    )[]).forEach((commodity) => {
      if (
        !commoditiesList.includes(commodity.commodity) &&
        category === commodity.category
      ) {
        commoditiesList.push(commodity.commodity);
      }
    });
  });

  const mappedCommoditiesList = commoditiesList.map(
    (id) => commoditiesIndex[id]
  );

  return isWfp !== undefined
    ? mappedCommoditiesList.filter((item) => item?.is_wfp === isWfp)
    : mappedCommoditiesList;
};

export const getPurchasedCommodities = (
  stores: Store[],
  commoditiesIndex: { [key: number]: PurchaseCommodity | DeliveryCommodity },
  date0: moment.MomentInput,
  date1: moment.MomentInput
) => {
  const filteredStores = stores.filter((store) => {
    return (
      (store as any).commodities &&
      store.model === "purchasedetail" &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
    );
  });
  let commoditiesList: number[] = [];

  filteredStores.forEach((item) => {
    (item as any).commodities.forEach(
      (commodity: PurchaseCommodity | DeliveryCommodity) => {
        if (!commoditiesList.includes(commodity.commodity)) {
          commoditiesList.push(commodity.commodity);
        }
      }
    );
  });

  const commodities: (
    | PurchaseCommodity
    | DeliveryCommodity
  )[] = commoditiesList.map((id) => commoditiesIndex[id]);

  return commodities;
};

export const getOtherCostsInStore = (
  stores: Store[],
  purchaseOtherCosts: any,
  date0: moment.MomentInput,
  date1: moment.MomentInput
) => {
  const filteredStores = stores.filter(
    (store) =>
      store.model === "purchasedetail" &&
      store.commodities &&
      moment(store.purchased_at).isBetween(date0, date1, "days", "[]")
  );
  let otherCostList: any[] = [];

  filteredStores.forEach((item: any) => {
    item.other_costs.forEach((other_cost: any) => {
      if (
        other_cost.other_cost &&
        !otherCostList.includes(other_cost.other_cost)
      ) {
        otherCostList.push(other_cost.other_cost);
      }
    });
  });

  const purchaseOtherCostsOptions = purchaseOtherCosts
    ? (Object as any).assign(
        ...Object?.values(purchaseOtherCosts?.results).map(
          ({ ...rest }: any) => ({
            [rest.id]: rest,
          })
        ),
        {}
      )
    : null;

  const otherCostsInStore =
    otherCostList
      .map((id) => purchaseOtherCostsOptions[id])
      .filter((i) => i !== undefined) || [];

  return otherCostsInStore;
};

/**
 * Returns a snapshot of the initial carry over stock on the given `date`
 *
 * in the shape of: {
 *   <commodity_id>: {
 *     "totalQuantity": x,
 *     "quantityPerBatchNo": {
 *       <batch_no>: {
 *         "quantity": y,
 *         "date": z,
 *       },
 *       ...
 *     }
 *   },
 *   ...
 * }
 */
export const getInitialCarryOverStock = (
  state: RootState,
  date: moment.MomentInput,
  currentStoreData: Store
) => {
  const deliveries = getAllStores(state).filter((store) =>
    !currentStoreData
      ? store.model === "delivery" &&
        moment(store.delivered_at).isSameOrBefore(date, "day")
      : store.model === "delivery" &&
        moment(store.delivered_at).isSame(date, "day") &&
        (store as Delivery).is_initial_stock === true
  );

  const accumulator: any = {};

  deliveries.forEach((stock) => {
    const deliveredAt = (stock as any).delivered_at;
    (stock as any).commodities
      .filter(
        (i: any) =>
          i.is_commodity_wfp || (i.is_carry_over && !i.is_commodity_wfp)
      )
      .forEach((item: any) => {
        const quantity = !currentStoreData
          ? parseFloat(item.quantity_available)
          : parseFloat(item.quantity);
        let { commodity, category, batch_no, measure_unit } = item;
        if (category === "delivery" && quantity > 0) {
          if (batch_no === "") {
            batch_no = "unknown";
          }
          // this is just to accomodate the legacy data while creating school years in Sept 2022
          // TODO: remove this line after school years in 2022 have started
          batch_no = "unknown";
          if (!Object.keys(accumulator).includes(commodity.toString())) {
            accumulator[commodity] = {
              totalQuantity: quantity,
              measure_unit: measure_unit,
              quantityPerBatchNo: {
                [batch_no]: {
                  quantity: quantity,
                  date: deliveredAt,
                },
              },
            };
          } else {
            accumulator[commodity]["totalQuantity"] = parseFloat(
              (
                parseFloat(accumulator[commodity]["totalQuantity"] || 0) +
                parseFloat(quantity as any)
              ).toFixed(10)
            );

            if (
              Object.keys(
                accumulator[commodity]["quantityPerBatchNo"]
              ).includes(batch_no)
            ) {
              accumulator[commodity]["quantityPerBatchNo"][batch_no][
                "quantity"
              ] = parseFloat(
                (
                  parseFloat(
                    accumulator[commodity]["quantityPerBatchNo"][batch_no][
                      "quantity"
                    ] || 0
                  ) + parseFloat(quantity as any)
                ).toFixed(10)
              );
            } else {
              accumulator[commodity]["quantityPerBatchNo"][batch_no] = {
                quantity: quantity,
                date: deliveredAt,
              };
            }
            accumulator[commodity]["quantityPerBatchNo"][batch_no][
              "date"
            ] = deliveredAt;
          }
        }
      });
  });

  return accumulator;
};

export const getInitialStockIncidents = (
  state: RootState,
  date: moment.MomentInput
) => {
  const incidents: Incident[] = getAllStores(state).filter(
    (store) =>
      store.model === "incident" &&
      store.type !== "delete" &&
      store.is_initial_stock_incident === true &&
      moment(store.occurred_at).isSame(date, "day")
  ) as Incident[];
  const accumulator: any = {};

  incidents.forEach((incident) => {
    const occurredAt = incident.occurred_at;
    incident.commodities.forEach(
      ({ commodity, batch_no, quantity, measure_unit }: any) => {
        if (!Object.keys(accumulator).includes(commodity.toString())) {
          accumulator[commodity] = {
            totalQuantity: parseFloat(quantity),
            measure_unit: measure_unit,
            quantityPerBatchNo: {
              [batch_no]: {
                quantity: parseFloat(quantity),
                date: occurredAt,
              },
            },
          };
        } else {
          accumulator[commodity]["totalQuantity"] = parseFloat(
            (
              parseFloat(accumulator[commodity]["totalQuantity"] || 0) +
              parseFloat(quantity)
            ).toFixed(10)
          );

          if (
            Object.keys(accumulator[commodity]["quantityPerBatchNo"]).includes(
              batch_no
            )
          ) {
            accumulator[commodity]["quantityPerBatchNo"][batch_no][
              "quantity"
            ] = parseFloat(
              (
                parseFloat(
                  accumulator[commodity]["quantityPerBatchNo"][batch_no][
                    "quantity"
                  ] || 0
                ) + parseFloat(quantity)
              ).toFixed(10)
            );
          } else {
            accumulator[commodity]["quantityPerBatchNo"][batch_no] = {
              quantity: quantity,
              date: occurredAt,
            };
          }
          accumulator[commodity]["quantityPerBatchNo"][batch_no][
            "date"
          ] = occurredAt;
        }
      }
    );
  });

  return { quantityPerCommodity: accumulator, incidentObjects: incidents };
};

export const getInitialStockDeliveryByDate = (
  state: RootState,
  date: moment.MomentInput
) => {
  return getAllStores(state).find(
    (store) =>
      store.model === "delivery" &&
      moment(store.delivered_at).isSame(date, "day") &&
      (store as any).is_initial_stock === true
  );
};
