import React from "react";

import { MeasureUnit, ReportCommodity } from "data-handler/ducks/stores";

import MeasureUnitValue from "components/MeasureUnitValue";

import { FormattedMessage } from "react-intl";

import {
  deliveryCategory,
  purchaseDetailCategory,
  takeHomeRationCategory,
} from "SCConstants";

import styles from "./school-report.module.scss";

export const schoolFeedingModalities = {
  [deliveryCategory]: (
    <FormattedMessage
      id="Common.onsiteSchoolFeedingModality"
      defaultMessage="Deliveries for on-site meals"
    />
  ),
  [takeHomeRationCategory]: (
    <FormattedMessage
      id="Common.thrSchoolFeedingModality"
      defaultMessage="Deliveries for Take-Home rations"
    />
  ),
  [purchaseDetailCategory]: (
    <FormattedMessage
      id="Common.purchasesSchoolFeedingModality"
      defaultMessage="School purchases"
    />
  ),
};

export interface ReportStockMovementsRow {
  batch_nos?: Array<string>;
  commodity: number;
  qty_lost: string | number;
  qty_final: string | number;
  qty_initial: string | number;
  qty_received: string | number;
  qty_distributed: string | number;
  qty_returned?: string | number;
}

interface ReportStockMovementsTotals {
  name: "total";
  measure_unit: MeasureUnit;
  qty_lost: string | number;
  qty_final: string | number;
  qty_initial: string | number;
  qty_received: string | number;
  qty_distributed: string | number;
  qty_returned?: string | number;
}

type StockMovementsCategorySectionProps = {
  rows: ReportStockMovementsRow[];
  totals: ReportStockMovementsTotals;
  getCommodity: (id: number) => ReportCommodity;
  title: React.ReactElement;
};

const StockMovementsCategorySection: React.ComponentType<StockMovementsCategorySectionProps> = ({
  rows,
  totals,
  getCommodity,
  title,
}) =>
  rows.length === 0 ? null : (
    <>
      {rows.map((row, i) => {
        const commodity = getCommodity(row.commodity);

        return (
          <tr key={i} className={""}>
            {i === 0 && (
              <td
                className={`${styles.boldText} ${styles.modality}`}
                rowSpan={rows.length + 1}
              >
                {title}
              </td>
            )}
            <td className={styles.headerData}>{i + 1}</td>
            <td className={styles.headerData}>
              {commodity.name.replace(" (WFP)", "")}
            </td>
            <td className={styles.headerData}>{row.batch_nos?.join(", ")}</td>
            <td className={`${styles.headerData} ${styles.numCell}`}>
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {row.qty_initial}
              </MeasureUnitValue>
            </td>
            <td className={`${styles.headerData} ${styles.numCell}`}>
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {row.qty_received}
              </MeasureUnitValue>
            </td>
            <td className={`${styles.headerData} ${styles.numCell}`}>
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {row.qty_distributed}
              </MeasureUnitValue>
            </td>
            <td className={`${styles.headerData} ${styles.numCell}`}>
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {row.qty_lost}
              </MeasureUnitValue>
            </td>
            <td className={`${styles.headerData} ${styles.numCell}`}>
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {row.qty_returned || 0}
              </MeasureUnitValue>
            </td>
            <td className={`${styles.headerData} ${styles.numCell}`}>
              <MeasureUnitValue measureUnit={commodity.measure_unit}>
                {row.qty_final}
              </MeasureUnitValue>
            </td>
          </tr>
        );
      })}

      <tr key={rows.length} className={styles.boldText}>
        <td className={styles.headerData}></td>
        <td className={styles.headerData}>Total</td>
        <td className={styles.headerData}></td>
        <td className={`${styles.headerData} ${styles.numCell}`}>
          {totals.measure_unit && totals.qty_initial ? (
            <MeasureUnitValue measureUnit={totals.measure_unit}>
              {totals.qty_initial}
            </MeasureUnitValue>
          ) : (
            totals.qty_initial
          )}
        </td>
        <td className={`${styles.headerData} ${styles.numCell}`}>
          {totals.measure_unit && totals.qty_received ? (
            <MeasureUnitValue measureUnit={totals.measure_unit}>
              {totals.qty_received}
            </MeasureUnitValue>
          ) : (
            totals.qty_received
          )}
        </td>
        <td className={`${styles.headerData} ${styles.numCell}`}>
          {totals.measure_unit && totals.qty_distributed ? (
            <MeasureUnitValue measureUnit={totals.measure_unit}>
              {totals.qty_distributed}
            </MeasureUnitValue>
          ) : (
            totals.qty_distributed
          )}
        </td>
        <td className={`${styles.headerData} ${styles.numCell}`}>
          {totals.measure_unit && totals.qty_lost ? (
            <MeasureUnitValue measureUnit={totals.measure_unit}>
              {totals.qty_lost}
            </MeasureUnitValue>
          ) : (
            totals.qty_lost
          )}
        </td>
        <td className={`${styles.headerData} ${styles.numCell}`}>
          {totals.measure_unit && totals.qty_returned ? (
            <MeasureUnitValue measureUnit={totals.measure_unit}>
              {totals.qty_returned}
            </MeasureUnitValue>
          ) : (
            totals.qty_returned
          )}
        </td>
        <td className={`${styles.headerData} ${styles.numCell}`}>
          {totals.measure_unit && totals.qty_final ? (
            <MeasureUnitValue measureUnit={totals.measure_unit}>
              {totals.qty_final}
            </MeasureUnitValue>
          ) : (
            totals.qty_final
          )}
        </td>
      </tr>
    </>
  );

export default StockMovementsCategorySection;
