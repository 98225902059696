import React, {
  useRef,
  useState,
  useEffect,
  BaseSyntheticEvent,
  useMemo,
} from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import {
  Controller,
  FormProvider,
  useForm,
  useFieldArray,
} from "react-hook-form";
import { isEmpty } from "lodash";
import Select, { components } from "react-select";
import {
  TextInput,
  RadioButtonGroup,
  Blockquote,
  RadioButton,
  Input,
  InlineLoading,
} from "@wfp/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWheatAwn } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { RepeaterItem } from "components/Repeater";
import RepeaterAddButton from "components/RepeaterAddButton";
import CommoditySelect from "components/CommoditySelect";
import DateInput from "components/DateInput";
import Empty from "components/Empty";
import ModalExtended from "components/ModalExtended";
import QuantityInput from "components/QuantityInput";
import ErrorList from "components/ErrorList";
import Camera from "components/Camera";

import incidentCausesDuck from "data-handler/ducks/incidentCauses";
import useStore from "helpers/useStore";
import {
  deliveryCategory,
  purchaseDetailCategory,
  selectContainerStyles,
  takeHomeRationCategory,
} from "SCConstants";

import "./_incidents-edit.scss";

import {
  getAllStores,
  checkIncidentDateIsUniquePerDate,
  getCurrentSchoolYear,
  getPreviousSchoolYears,
  SchoolYear,
  Store,
  Incident,
  Delivery,
  MeasureUnit,
} from "data-handler/ducks/stores";

import {
  deliveriesInStockByCommodityByCategory,
  getDeliveryByCommodityAndDeliveryID,
  getPurchaseByCommodityPurchaseID,
  getPurchaseNameByCommodityPurchaseID,
  getStockStatus,
  getWaybillByCommodityAndDeliveryID,
} from "helpers/stock";

import {
  clearDeliveryOptions,
  getDeliveries,
  setDeliveries,
} from "data-handler/ducks/deliveries";

import {
  getCurrentSchoolProfile,
  requestAllCommoditySources,
  getAllCommoditySources,
  getAllCommoditySourcesFetching,
  getCurrentSchoolProfileWfpCommodities,
} from "data-handler/ducks/schools";

import { getVendorCategories } from "data-handler/ducks/vendors";
import { getSelectedVendorName } from "components/CashToSchoolShow";
import { getReportByYearMonthDay } from "data-handler/ducks/reports";
import { RootState } from "data-handler/rootReducer";
import { getCategory } from "helpers/category";
import { CommoditySource } from "data-handler/ducks/schools2";
import { getLoggedInOffline } from "data-handler/ducks/auth";
import MeasureUnitValue from "components/MeasureUnitValue";

export const isReportClosedOrRejected = (
  state: RootState,
  selectedDate: string
) => {
  const splitDate = selectedDate?.split("-");
  const year = splitDate ? parseInt(splitDate[0]) : undefined;
  const month = splitDate ? parseInt(splitDate[1]) : undefined;
  const day = splitDate ? parseInt(splitDate[2]) : undefined;
  const reportBySelectedDate = getReportByYearMonthDay(
    state,
    year!,
    month!,
    day!
  );

  return reportBySelectedDate
    ? reportBySelectedDate.state === "closed" ||
        reportBySelectedDate.state === "rejected"
    : undefined;
};

export const isReportApprovedOrSubmitted = (
  state: RootState,
  selectedDate: string
) => {
  const splitDate = selectedDate?.split("-");
  const year = splitDate ? parseInt(splitDate[0]) : undefined;
  const month = splitDate ? parseInt(splitDate[1]) : undefined;
  const day = splitDate ? parseInt(splitDate[2]) : undefined;
  const reportBySelectedDate = getReportByYearMonthDay(
    state,
    year!,
    month!,
    day!
  );

  return reportBySelectedDate
    ? reportBySelectedDate.state === "approved" ||
        reportBySelectedDate.state === "submitted"
    : undefined;
};

export const schoolYearDateValidation = (
  date: string,
  currentYearStore: SchoolYear,
  previousSchoolYears: SchoolYear[]
) => {
  let isSelectedDateInSchoolYear = true;
  const previousSchoolYearsStartsOn =
    previousSchoolYears?.length > 0
      ? previousSchoolYears.map(({ starts_on, ends_on }) => ({
          starts_on,
          ends_on,
        }))
      : undefined;

  if (
    previousSchoolYearsStartsOn &&
    previousSchoolYearsStartsOn.length > 0 &&
    date &&
    !currentYearStore
  ) {
    isSelectedDateInSchoolYear = !!previousSchoolYearsStartsOn.find((item) => {
      return (
        moment(date).isBetween(item?.starts_on, item?.ends_on) ||
        moment(date).isSame(item?.starts_on) ||
        moment(date).isSame(item?.ends_on)
      );
    });
  } else if (currentYearStore) {
    isSelectedDateInSchoolYear =
      moment(date).isBetween(
        currentYearStore?.starts_on,
        currentYearStore?.ends_on
      ) ||
      moment(date).isSame(currentYearStore?.starts_on) ||
      moment(date).isSame(currentYearStore?.ends_on);
  }
  return isSelectedDateInSchoolYear;
};

export const currentSchoolYearDateValidation = (
  currentYearStore: SchoolYear,
  previousSchoolYears?: SchoolYear[]
) => {
  let minDate;
  let maxDate;
  const todaysDate = moment().format("YYYY-MM-DD");
  if (currentYearStore) {
    minDate = currentYearStore?.starts_on;
    maxDate = moment(todaysDate).isSameOrBefore(currentYearStore?.ends_on)
      ? todaysDate
      : currentYearStore?.ends_on;
  } else if (!currentYearStore && previousSchoolYears) {
    minDate = previousSchoolYears[0]?.starts_on;
    maxDate = previousSchoolYears[0]?.ends_on;
  } else {
    minDate = undefined;
    maxDate = moment().format("YYYY-MM-DD");
  }
  return {
    min: minDate,
    max: maxDate,
  };
};

export const ERROR_MESSAGES_INCIDENTS = {
  causesSome: (
    <FormattedMessage
      id="IncidentEditError.causesSome"
      defaultMessage="Please select at least one reason or enter a custom reason"
    />
  ),
  missingDate: (
    <FormattedMessage
      id="IncidentEditError.missingDate"
      defaultMessage="Enter incident date"
    />
  ),
  missingCommodity: (
    <FormattedMessage
      id="IncidentEditError.missingCommodity"
      defaultMessage="Select a commodity"
    />
  ),
  missingQuantity: (
    <FormattedMessage
      id="IncidentEditError.missingQuantity"
      defaultMessage="Enter a quantity"
    />
  ),
  missingIncidentType: (
    <FormattedMessage
      id="IncidentEditError.missingIncidentType"
      defaultMessage="Enter a incident type"
    />
  ),
  incorrectIncidentType: (
    <FormattedMessage
      id="IncidentEditError.incorrectIncidentType"
      defaultMessage="You can't select Return to WFP when you have selected a purchased commodity "
    />
  ),
  missingWaybill: (
    <FormattedMessage
      id="IncidentEditError.missingWaybill"
      defaultMessage="Enter a number here"
    />
  ),
  insufficientStock: (
    <FormattedMessage
      id="MealsEdit.insufficientStock"
      defaultMessage="The meal quantity exceeds the stock for this commodity, please reduce."
    />
  ),
  missingCategory: (
    <FormattedMessage
      id="IncidentEditError.selectCategory"
      defaultMessage="Select category"
    />
  ),
  incorrectReason: (
    <FormattedMessage
      id="IncidentEditError.incorrectReason"
      defaultMessage="You cannot select a purchased commodity and return to WFP as a reason"
    />
  ),
  invalidDate: (
    <FormattedMessage
      id="IncidentEditError.date-not-valid"
      defaultMessage="Selected date is not in a school year, please change date"
    />
  ),
  reportClosedOrRejected: (
    <FormattedMessage
      id="IncidentEditError.report-closed-rejected"
      defaultMessage="The report of your chosen date is already closed or rejected. Either reopen the report or change the selected date."
    />
  ),
  reportApprovedOrSubmitted: (
    <FormattedMessage
      id="IncidentEditError.report-approved-submitted"
      defaultMessage="The report of your chosen date is already submitted. Please change the date or contact your school feeding focal point to reopen the report"
    />
  ),
};

// This needs to corrospond to the report functions that check for loss / return.
// Example: report.js:142. This checks is incident is 1 / loss or 2 / return
export const incidentTypes: { [id: string]: React.ReactNode } = {
  1: <FormattedMessage id="IncidentEdit.loss" defaultMessage="Loss" />,
  2: (
    <FormattedMessage
      id="IncidentEdit.returnToWfp"
      defaultMessage="Food Will be returned to WFP"
    />
  ),
};

/** Handles validation for ALL form values */
const validationResolver = (
  values: any,
  {
    getWaybillOptions,
    state,
    defaultValues,
    currentSchoolEnabledPurchases,
    currentYearStore,
    previousSchoolYears,
  }: any
) => {
  const errors: {
    [fieldName: string]: {
      message: React.ReactNode;
      requiredMessage?: React.ReactNode;
    };
  } = {};
  const isSelectedDateInSchoolYearValid = schoolYearDateValidation(
    values.date,
    currentYearStore,
    previousSchoolYears
  );
  if (!isSelectedDateInSchoolYearValid && values.date) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["invalidDate"],
    };
  }
  if (isReportClosedOrRejected(state, values.date) && values.date) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["reportClosedOrRejected"],
    };
  }
  if (isReportApprovedOrSubmitted(state, values.date) && values.date) {
    errors["date"] = {
      message: ERROR_MESSAGES_INCIDENTS["reportApprovedOrSubmitted"],
    };
  }
  if (!values.date) {
    errors[`date`] = {
      message: ERROR_MESSAGES_INCIDENTS["missingDate"],
    };
  }
  // At least one of `causes` or `other_cause`
  if (!values.causes && !values.other_cause) {
    errors["causes"] = {
      message: ERROR_MESSAGES_INCIDENTS["causesSome"],
    };
  }
  // `commodity` is required on each commodity row
  values.commodities.forEach(
    (
      commodity: {
        commodity: number;
        quantity: number;
        category: string;
        waybill_no?: string;
        purchase_commodity_id?: string;
      },
      i: number
    ) => {
      // TODO I am not sure usage of variable i is correct here.
      if (currentSchoolEnabledPurchases && !commodity.category) {
        errors["category"] = {
          message: ERROR_MESSAGES_INCIDENTS["missingCategory"],
          requiredMessage: ERROR_MESSAGES_INCIDENTS["missingCategory"],
        };
      }
      if (!commodity.commodity) {
        errors[`commodities[${i}].commodity`] = {
          message: ERROR_MESSAGES_INCIDENTS["missingCommodity"],
        };
      }

      // `quantity` is required on each commodity row
      if (!commodity.quantity) {
        errors[`commodities[${i}].quantity`] = {
          message: ERROR_MESSAGES_INCIDENTS["missingQuantity"],
        };
      }

      if (values.reason && commodity.category) {
        let reasonIsIncorrect = false;
        Object.keys(values?.reason).forEach(function (key) {
          if (values.reason[key] === "2") {
            reasonIsIncorrect = true;
          }
        });

        if (
          reasonIsIncorrect &&
          commodity.category === purchaseDetailCategory
        ) {
          errors["reason"] = {
            message: ERROR_MESSAGES_INCIDENTS["incorrectReason"],
          };
        }
      }

      // `waybill` is required on each commodity row
      if (
        !commodity.waybill_no &&
        (commodity.category === deliveryCategory ||
          !currentSchoolEnabledPurchases)
      ) {
        errors[`commodities[${i}].waybill_no`] = {
          message: ERROR_MESSAGES_INCIDENTS["missingWaybill"],
        };
      }
      if (
        !commodity.purchase_commodity_id &&
        commodity.category === purchaseDetailCategory
      ) {
        errors[`commodities[${i}].purchase_commodity_id`] = {
          message: ERROR_MESSAGES_INCIDENTS["missingWaybill"],
        };
      }

      let waybillStock =
        parseFloat(
          getWaybillOptions(i).find(
            (item: { value: string }) =>
              item.value &&
              item.value?.toString() === commodity.waybill_no?.toString()
          )?.stock
        ) || undefined;

      if (
        waybillStock !== undefined &&
        commodity.commodity &&
        Object.keys(defaultValues)?.includes("commodities") &&
        defaultValues.commodities[i] !== undefined &&
        commodity.commodity === defaultValues.commodities[i].commodity &&
        commodity.waybill_no === defaultValues.commodities[i].waybill_no &&
        Object.keys(defaultValues.commodities[i]).includes("quantity")
      ) {
        waybillStock += parseFloat(defaultValues.commodities[i].quantity);
      }

      if (parseFloat(commodity.quantity as any) > waybillStock!) {
        errors[`commodities[${i}].quantity`] = {
          message: ERROR_MESSAGES_INCIDENTS["insufficientStock"],
        };
      }
    }
  );

  if (!values.reason) {
    errors["reason"] = {
      message: ERROR_MESSAGES_INCIDENTS["missingIncidentType"],
    };
  }
  return {
    values: !isEmpty(errors) ? {} : values,
    errors,
  };
};

type FormCategory = {
  category: string;
  delivery_commodity: number;
  purchase_detail_commodity: number;

  waybill_no?: string;
  purchase_commodity_id?: string;
};

type FormValues = {
  category: string;
  occurred_at?: string;

  causes: number[];
  commodities: FormCategory[];
  reason: string;
};

const storeToFormValues = (
  allStores: Store[],
  store: FormValues,
  vendorCategories: string[]
) => {
  const values: FormValues = JSON.parse(JSON.stringify(store)); // deep copy
  const category = values.category;
  // Substitute `occurred_at` (a datetime) with `date`
  const date = moment(values.occurred_at).format("YYYY-MM-DD");
  delete values.occurred_at;

  // Convert causes from an array of ints (fks) to an integer by taking the first value
  // The array of ints should only have one value
  const causes = store.causes?.length > 0 ? store.causes[0].toString() : "";

  values.commodities.forEach((commodity) => {
    if (
      commodity.category === deliveryCategory ||
      commodity.category === takeHomeRationCategory
    ) {
      const deliveryID = commodity.delivery_commodity;
      const waybill_no = getWaybillByCommodityAndDeliveryID(
        allStores,
        deliveryID
      );
      commodity.waybill_no = waybill_no ? deliveryID.toString() : undefined;
    } else if (commodity.category === purchaseDetailCategory) {
      const purchaseId = commodity.purchase_detail_commodity;
      const purchase_no = getPurchaseNameByCommodityPurchaseID(
        allStores,
        purchaseId,
        vendorCategories
      );
      commodity.purchase_commodity_id = purchase_no
        ? purchaseId.toString()
        : undefined;
    }
  });

  const reasonValue = values.reason;
  const reason = { label: incidentTypes[reasonValue], value: reasonValue };

  return { ...values, causes, date, reason, category };
};

type RawFormCommodity = {
  category: string;
  waybill_no?: string;
  delivery_commodity: { value: string };
  purchase_commodity_id?: string;
  purchase_detail_commodity: string;
};

type RawFormValues = {
  category: string;
  //occurred_at?: string,
  date?: string;
  causes: string;
  commodities: RawFormCommodity[];
  reason: { value: string } | string;
};

const formValuesToStore = (
  values: RawFormValues,
  incidentCauses: { id: number }[],
  getValues: () => { commodities: { category: string }[] },
  currentSchoolEnabledPurchases: boolean,
  currentSchoolEnabledTHR: boolean
): {
  causes: number[];
  occurred_at: string;
  category: any;
  // We seem to be using occured_at for data but in html we seem to use the value date.
  date?: string;
  commodities: FormCategory[];
  reason: string;
} => {
  // Substitute `date` with `occurred_at` (datetime)
  const occurred_at = moment(values.date).format("YYYY-MM-DD");
  delete values.date;

  // Convert causes from an integer to an array of one integer
  // (the causes are stored as a list on a backend, but there should only be one cause)
  const causes = incidentCauses
    .map((cause) => cause.id)
    .filter((cause) => cause === parseInt(values.causes));

  const reason = values.reason;
  values.reason = reason.hasOwnProperty("value")
    ? (reason as any).value
    : reason;

  values.category = getCategory(getValues);
  if (
    !currentSchoolEnabledPurchases &&
    !currentSchoolEnabledTHR &&
    values.commodities
  ) {
    values.commodities.forEach((commodity) => {
      commodity.category = deliveryCategory;
    });
  }
  // This displayed as waybill, for selecting the delivery, although we are storing the delivery ID.
  // Waybill is used to allow for users an easy way to identify deliveries.
  // ID are stored as waybill are only unique per school and not within the whole system.
  values.commodities.forEach((commodity) => {
    if (
      commodity.category === deliveryCategory ||
      commodity.category === takeHomeRationCategory
    ) {
      const deliveryCommodity = commodity.waybill_no;
      commodity.delivery_commodity = deliveryCommodity?.hasOwnProperty("value")
        ? (deliveryCommodity as any).value
        : deliveryCommodity;
      delete commodity.waybill_no;
    } else if (commodity.category === purchaseDetailCategory) {
      const purchaseCommodity = commodity.purchase_commodity_id;
      commodity.purchase_detail_commodity = purchaseCommodity?.hasOwnProperty(
        "value"
      )
        ? (purchaseCommodity as any).value
        : purchaseCommodity;
      delete commodity.purchase_commodity_id;
      commodity.category = purchaseDetailCategory;
    }
  });

  return { ...values, causes, occurred_at } as any;
};

const deliveryOptions = (
  allStores: Store[],
  commodityId: number,
  category: string,
  deliveryCommodityId?: string,
  date?: string
) => {
  return commodityId
    ? deliveriesInStockByCommodityByCategory(
        allStores,
        commodityId,
        category,
        deliveryCommodityId,
        date
      )
    : [];
};

const commodityCategoryId = (commodityId: number, category: string) =>
  `${commodityId}-${category}`;

const SingleValue = ({ children, ...props }: any) => {
  const value = props?.data?.singleValueStock;
  const measureUnit = props.data?.singleValueMeasureUnit;
  return (
    <components.SingleValue {...props}>
      {children}
      <div className="commodity-select__stock stock-value">
        {!isNaN(value) && measureUnit && (
          <>
            <FormattedMessage
              id="IncidentEdit.availableQuantity"
              defaultMessage="Available quantity: "
            />
            <MeasureUnitValue measureUnit={measureUnit}>
              {value}
            </MeasureUnitValue>
          </>
        )}
      </div>
    </components.SingleValue>
  );
};

const OptionLabel = ({
  option,
}: {
  option: {
    label: string;
    stock: number;
    measure_unit: MeasureUnit;
  };
}) => (
  <>
    {option.label}
    {option.stock && (
      <>
        {" "}
        -{" "}
        <MeasureUnitValue measureUnit={option.measure_unit}>
          {option.stock}
        </MeasureUnitValue>
      </>
    )}
  </>
);

type IncidentEditProps = {
  currentStoreData: Incident;
};

const IncidentEdit: React.ComponentType<IncidentEditProps> = ({
  currentStoreData,
}) => {
  const { updateStore, closeModal } = useStore();
  const dispatch = useDispatch();
  const submitBtnRef = useRef();
  const [incidentType, setIncidentType] = useState(currentStoreData?.reason);
  const incidentCauses = useSelector(incidentCausesDuck.getList);

  const currentYearStore = useSelector((state: RootState) =>
    getCurrentSchoolYear(state)
  );
  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  const currentSchoolYearDate = currentSchoolYearDateValidation(
    currentYearStore!,
    previousSchoolYears
  );
  const state = useSelector((state) => state) as RootState;
  const allStores = useSelector(getAllStores);
  const totalDeliveries = useSelector(getDeliveries);
  const [selectedCommodityId, setSelectedCommodityId] = useState<
    { commodityId: string; category: string } | undefined
  >(undefined);
  const vendorCategories = useSelector(getVendorCategories);
  const currentSchool = useSelector(getCurrentSchoolProfile);
  const stockStatus = useSelector(
    getStockStatus(moment().format("YYYY-MM-DD"))
  );
  const commoditiesListWfpFromSchoolProfile = useSelector(
    getCurrentSchoolProfileWfpCommodities
  );

  // TODO figure out if we need to only check wfp commodities or if we can actualy just show thr if there is any thr commodity present.
  // There are thr wfp commodities that have Nonzero values.
  const thrInStock = commoditiesListWfpFromSchoolProfile.some(
    (commodity: { id: number }) =>
      Number(stockStatus.takehomeration[commodity.id] || "0") > 0
  );
  // There are purchasedetail wfp commodities that have Nonzero values.
  const purchaseInStock = commoditiesListWfpFromSchoolProfile.some(
    (commodity: { id: number }) =>
      Number(stockStatus.purchasedetail[commodity.id] || "0") > 0
  );

  const currentSchoolEnabledPurchases =
    purchaseInStock || currentSchool.enable_cash_purchases;

  const currentSchoolEnabledTHR =
    thrInStock || currentSchool.enable_take_home_rations;
  const categoryWatch = (category: string, index: number) =>
    watch(`commodities[${index}].category`) === category;
  // Use refresh key to force the waybill selects
  const [refreshKey, setRefreshKey] = useState(new Date().valueOf());

  // Get Options for all pre-filled commodities when "editing".
  useEffect(() => {
    if (!currentStoreData) return;
    const currentDataCommodities = currentStoreData.commodities;
    const initialStockWaybillDeliveries: {
      commodity: string;
      delivery: Delivery[];
    }[] = [];
    currentDataCommodities.forEach((incidentCommodity) => {
      const category = incidentCommodity.category;
      const options = deliveryOptions(
        allStores,
        incidentCommodity?.commodity,
        category,
        incidentCommodity?.delivery_commodity?.toString(),
        selectedDate
      );
      const commodityCategory = currentSchoolEnabledPurchases
        ? commodityCategoryId(
            incidentCommodity?.commodity,
            incidentCommodity?.category
          )
        : commodityCategoryId(incidentCommodity?.commodity, deliveryCategory);
      const commodityIdKey = !currentSchoolEnabledPurchases
        ? "deliveryCommodity"
        : category === deliveryCategory || category === takeHomeRationCategory
        ? "delivery_commodity"
        : category === purchaseDetailCategory
        ? "purchase_detail_commodity"
        : undefined;

      if (options?.length > 0) {
        dispatch(setDeliveries({ [commodityCategory]: options }));
      } else {
        const commodityCategory =
          !currentSchoolEnabledPurchases && !currentSchoolEnabledTHR
            ? "deliveryCommodity"
            : category === deliveryCategory ||
              category === takeHomeRationCategory
            ? "delivery_commodity"
            : category === purchaseDetailCategory
            ? "purchase_detail_commodity"
            : undefined;
        const delivery = getDeliveryByCommodityAndDeliveryID(
          allStores,
          (incidentCommodity as any)[commodityIdKey as any]
        );
        const purchase = getPurchaseByCommodityPurchaseID(
          allStores,
          (incidentCommodity as any)[commodityIdKey as any]
        );
        const commodityDeliveryOrPurchase = currentSchoolEnabledPurchases
          ? category === deliveryCategory
            ? delivery
            : purchase
          : delivery;

        if (!commodityDeliveryOrPurchase) return;

        const correspondingDelivery = initialStockWaybillDeliveries?.find(
          (delivery) =>
            (delivery.commodity as any) === incidentCommodity?.commodity &&
            currentSchoolEnabledPurchases
              ? (delivery as any).category === incidentCommodity?.category
              : (delivery as any).category === deliveryCategory
        );

        if (!correspondingDelivery) {
          initialStockWaybillDeliveries.push({
            commodity: commodityCategory!,
            delivery: [commodityDeliveryOrPurchase as any],
          });
        } else {
          correspondingDelivery.delivery = [
            ...correspondingDelivery?.delivery,
            commodityDeliveryOrPurchase as any,
          ];
        }
      }
    });
    if (initialStockWaybillDeliveries.length > 0) {
      initialStockWaybillDeliveries.forEach((deliveryObject) => {
        dispatch(
          setDeliveries({
            [deliveryObject.commodity]: deliveryObject.delivery,
          })
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores, currentStoreData]);

  // TO-DO: "mixed" delivery category
  const getWaybillOptions = (index: number) => {
    const indexCommodityID = getValues(`commodities[${index}].commodity`);
    const indexCategory =
      currentSchoolEnabledPurchases || currentSchoolEnabledTHR
        ? getValues(`commodities[${index}].category`)
        : deliveryCategory;
    const commodityCategoryId = `${indexCommodityID}-${indexCategory}`;
    return (totalDeliveries?.hasOwnProperty(commodityCategoryId)
      ? totalDeliveries[commodityCategoryId]
      : []
    ).map((option: any) => {
      const waybillLabel = `${option.waybill_no}: ${moment(
        option.delivered_at
      ).format("dddd, DD.MM.YYYY")}`;
      const vendorCategoryName = getSelectedVendorName(
        option.vendor_category,
        vendorCategories
      );
      const purchaseLabel = `${vendorCategoryName}: ${moment(
        option.purchased_at
      ).format("dddd, DD.MM.YYYY")}`;

      return {
        label:
          indexCategory === deliveryCategory ||
          indexCategory === takeHomeRationCategory
            ? waybillLabel
            : purchaseLabel,
        value: option.objectCommodityId,
        date: moment(option.delivered_at).format("YYYY-MM-DD"),
        sync: option.sync,
        consumptionsUnsynced: option.consumptionsUnsynced,
        stock: option.quantityAvailable,
        measure_unit: option.measure_unit,
      };
    });
  };

  const methods = useForm({
    resolver: validationResolver,
    defaultValues: currentStoreData
      ? storeToFormValues(allStores, currentStoreData as any, vendorCategories)
      : { commodities: [{}] },
    context: {
      getWaybillOptions,
      state,
      currentSchoolEnabledPurchases,
      currentYearStore,
      previousSchoolYears,
      defaultValues: currentStoreData
        ? storeToFormValues(
            allStores,
            currentStoreData as any,
            vendorCategories
          )
        : { commodities: [{}] },
    },
  });
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    watch,
    setValue,
    register,
    reset,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "commodities",
  });

  const selectedDate = watch("date");
  const isInitialStockIncident = currentStoreData?.is_initial_stock_incident;
  const isSelectedDateInSchoolYearValid = schoolYearDateValidation(
    selectedDate,
    currentYearStore!,
    previousSchoolYears
  );
  const isErrorListEmpty = isEmpty(errors);
  const isCommoditySelected = watch("commodities").find(
    (i: any) => i.commodity !== undefined
  );
  const onlyIncidentOnDate = useSelector(
    checkIncidentDateIsUniquePerDate(
      moment(selectedDate).format("YYYY-MM-DD"),
      currentStoreData
    )
  );

  const allCommoditySources: CommoditySource[] =
    useSelector(getAllCommoditySources) || [];

  const isAllCommoditySourcesFetching = useSelector(
    getAllCommoditySourcesFetching
  );

  const isOffline = useSelector(getLoggedInOffline);

  const isIncidentUnique = isInitialStockIncident ? true : onlyIncidentOnDate;

  let prevDate = useRef();

  // When selecting commodity, get the available delivery options and send them to redux store.
  // This allows for the page to be correctly rerendered without loosing the delivery options for each commodity
  useEffect(() => {
    if (selectedCommodityId) {
      const commodityCategory = commodityCategoryId(
        selectedCommodityId.commodityId as any,
        selectedCommodityId.category
      );

      const options = deliveryOptions(
        allStores,
        selectedCommodityId.commodityId as any,
        selectedCommodityId.category,
        undefined,
        selectedDate
      );
      if (options.length > 0) {
        dispatch(setDeliveries({ [commodityCategory]: options }));
      }
    }
  }, [allStores, dispatch, selectedCommodityId, selectedDate]);

  useEffect(() => {
    if (!prevDate.current ? (prevDate.current = selectedDate) : prevDate) {
      if (prevDate.current !== selectedDate) {
        setSelectedCommodityId(undefined);
        dispatch(clearDeliveryOptions());
        setValue("commodities", []);
        reset({
          date: selectedDate,
          commodities: [
            {
              waybill_no: undefined,
              commodity: undefined,
              measure_unit: undefined,
              quantity: undefined,
            },
          ],
        });
        prevDate.current = selectedDate;
        setRefreshKey(new Date().valueOf());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, setValue, reset]);
  const id =
    currentStoreData && currentStoreData.client_id
      ? currentStoreData.client_id
      : undefined;

  const onSubmit = (values: any) => {
    dispatch(clearDeliveryOptions());
    updateStore({
      id: id,
      values: formValuesToStore(
        values,
        incidentCauses,
        getValues,
        currentSchoolEnabledPurchases,
        currentSchoolEnabledTHR
      ),
      model: "incident",
      type: id ? "update" : "create",
      category:
        currentSchoolEnabledPurchases || currentSchoolEnabledTHR
          ? getCategory(getValues) // ONLY UPDATED AFTER ADDING ANOTHER COMMODITY
          : deliveryCategory,
    });
  };

  const submitViaBtnRefClick = () => {
    (submitBtnRef.current as any).click();
  };

  const customStyles = (invalid: boolean) => ({
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color:
          state.data?.stock &&
          parseFloat(state.data?.stock) > 0 &&
          !state.isSelected
            ? "#689e18"
            : "inherit",
      };
    },
    control: (styles: any) =>
      invalid
        ? selectContainerStyles.invalid.control(styles)
        : selectContainerStyles.valid.control(styles),
  });

  const categoryOptions = useMemo(
    () => {
      if (allCommoditySources.length === 0) {
        return [];
      }

      const allCommoditySourcesMap: {
        [categoryCode: string]: CommoditySource;
      } = {};
      for (let source of allCommoditySources) {
        allCommoditySourcesMap[source.code] = source;
      }

      const categoryOptionCodes = [deliveryCategory];

      if (currentSchoolEnabledPurchases) {
        categoryOptionCodes.push(purchaseDetailCategory);
      }
      if (currentSchoolEnabledTHR) {
        categoryOptionCodes.push(takeHomeRationCategory);
      }

      const commoditySources = categoryOptionCodes.map(
        (code: string) => allCommoditySourcesMap[code]
      );
      commoditySources.sort((a, b) => a.order - b.order);

      const categoryOptions = commoditySources.map(
        (commoditySource: CommoditySource) => ({
          value: commoditySource.code,
          name: commoditySource.code,
          label: commoditySource.name,
        })
      );
      return categoryOptions;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allCommoditySources.length]
  );

  useEffect(() => {
    if (!isOffline) {
      dispatch(requestAllCommoditySources());
    }
  }, [dispatch, isOffline]);

  return (
    <ModalExtended
      onRequestSubmit={submitViaBtnRefClick}
      onRequestClose={() => closeModal()}
      modalLabel={
        <FormattedMessage
          id="IncidentEdit.modalTitle"
          defaultMessage="Reporting"
        />
      }
      modalHeading={
        currentStoreData?.is_initial_stock_incident ? (
          <FormattedMessage
            id="IncidentEdit.modalSubtitleInitialStockIncident"
            defaultMessage="Report initial stock incident"
          />
        ) : (
          <FormattedMessage
            id="IncidentEdit.modalSubtitle"
            defaultMessage="Report incident"
          />
        )
      }
      primaryButtonText={
        <FormattedMessage
          id="IncidentEdit.saveIncident"
          defaultMessage="Save incident"
        />
      }
      primaryButtonDisabled={!isIncidentUnique}
      className="incidents-edit"
    >
      {isAllCommoditySourcesFetching ? (
        <InlineLoading />
      ) : (
        <FormProvider {...methods}>
          {!isIncidentUnique && (
            <Blockquote kind="warning" className="not-unique-error">
              <p>
                <FormattedMessage
                  id="errorGeneric.incidentDateUnique"
                  defaultMessage="You can only create one incident per day. To add information for this day, please edit the existing incident."
                />
              </p>
            </Blockquote>
          )}
          {!isSelectedDateInSchoolYearValid && isErrorListEmpty && (
            <Blockquote kind="warning" className="date-not-valid">
              {ERROR_MESSAGES_INCIDENTS.invalidDate}
            </Blockquote>
          )}
          {isReportClosedOrRejected(state, selectedDate) && isErrorListEmpty && (
            <Blockquote kind="warning" className="date-not-valid">
              {ERROR_MESSAGES_INCIDENTS.reportClosedOrRejected}
            </Blockquote>
          )}
          {isReportApprovedOrSubmitted(state, selectedDate) &&
            isErrorListEmpty && (
              <Blockquote kind="warning" className="date-not-valid">
                {ERROR_MESSAGES_INCIDENTS.reportApprovedOrSubmitted}
              </Blockquote>
            )}
          {!selectedDate && !isCommoditySelected && (
            <Blockquote kind="warning" className="not-unique-error">
              <p>
                <FormattedMessage
                  id="errorGeneric.selectedDateCommodity"
                  defaultMessage="You need to select a date to see commodities options."
                />
              </p>
            </Blockquote>
          )}
          {!selectedDate && isCommoditySelected && (
            <Blockquote kind="warning" className="not-unique-error">
              <p>
                <FormattedMessage
                  id="errorGeneric.selectedDateWaybill"
                  defaultMessage="You need to select a date to see waybill options."
                />
              </p>
            </Blockquote>
          )}

          <ErrorList errors={errors} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="wfp--form wfp--form-long">
              <div className="wfp--form-item">
                <Controller
                  control={control}
                  render={({ onChange, value }) => (
                    <Camera onChange={onChange} image={value} />
                  )}
                  name={"picture"}
                />
              </div>
              <div className="wfp--form-item wfp--form-item--invalid date-input">
                <Controller
                  as={
                    <DateInput
                      labelText={
                        <FormattedMessage
                          id="IncidentEdit.incidentDate"
                          defaultMessage="Incident date"
                        />
                      }
                      min={currentSchoolYearDate.min}
                      max={currentSchoolYearDate.max}
                    />
                  }
                  rules={{
                    required: true,
                  }}
                  invalid={errors.date}
                  invalidText={errors.date && errors.date.message}
                  name="date"
                  control={control}
                />
              </div>
              <Controller
                render={({ onChange, onBlur, value }) => {
                  return (
                    <Input
                      formItemClassName={classnames(
                        "wfp--form-item",
                        "wfp--react-select-container"
                      )}
                      labelText={
                        <FormattedMessage
                          id="IncidentEdit.incidentType"
                          defaultMessage="Incident Type"
                        />
                      }
                      name={"reason"}
                      invalid={errors["reason"] !== undefined}
                      invalidText={
                        <FormattedMessage
                          id="IncidentEditError.incidentType"
                          defaultMessage="Enter a incident type"
                        />
                      }
                    >
                      {() => (
                        <div className="commodity-select__input">
                          <Select
                            className="wfp--react-select-container"
                            classNamePrefix="wfp--react-select"
                            key={"reason"}
                            styles={customStyles(
                              errors["reason"] !== undefined
                            )}
                            onChange={(e: { value: string }) => {
                              setIncidentType(e.value);
                              onChange(e.value);
                            }}
                            onBlur={onBlur}
                            defaultValue={value}
                            options={Object.keys(incidentTypes).map((key) => ({
                              label: incidentTypes[key],
                              value: key,
                            }))}
                          />
                        </div>
                      )}
                    </Input>
                  );
                }}
                name={"reason"}
              />
              {incidentType && (
                <RadioButtonGroup
                  labelText={
                    <FormattedMessage
                      id="IncidentEdit.Reason"
                      defaultMessage="Reason"
                    />
                  }
                >
                  {incidentCauses.map((cause) => {
                    if (
                      cause.reason === parseInt(incidentType) ||
                      cause.reason === 3
                    ) {
                      return (
                        <RadioButton
                          className="cause-radio-button"
                          key={cause.id}
                          labelText={cause.name}
                          id={cause.id}
                          name={`causes`}
                          value={cause.id}
                          ref={register({ required: true })}
                        />
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </RadioButtonGroup>
              )}

              <Controller
                as={
                  <TextInput
                    labelText={
                      <FormattedMessage
                        id="IncidentEdit.other_cause"
                        defaultMessage="Other reason"
                      />
                    }
                    maxLength={200}
                  />
                }
                name={"other_cause"}
                control={control}
              />
            </div>

            <>
              <br />
              <div>
                {fields.length === 0 && (
                  <Empty
                    title="No commodities added yet"
                    icon={<FontAwesomeIcon icon={faWheatAwn} size="1x" />}
                  >
                    <FormattedMessage
                      id="IncidentEdit.addaCommodityWhichIsAffectedByTheIncident"
                      defaultMessage="Add a commodity which is affected by the incident"
                    />
                  </Empty>
                )}
                {fields.map((field, index) => (
                  <RepeaterItem
                    key={field.id}
                    disableRemove={fields.length === 1}
                    removeAction={() => remove(index)}
                    extraTopRightChild={
                      <Controller
                        control={control}
                        render={({ onChange, value }) => (
                          <Camera onChange={onChange} image={value} />
                        )}
                        name={`commodities[${index}].picture`}
                      />
                    }
                  >
                    <div
                      className={
                        currentSchoolEnabledPurchases || currentSchoolEnabledTHR
                          ? "container"
                          : "disabled"
                      }
                    >
                      <>
                        <RadioButtonGroup
                          labelText={
                            <FormattedMessage
                              id="MealsEdit.commoditySource"
                              defaultMessage="Commodity Source"
                            />
                          }
                        >
                          {categoryOptions.map((category) => (
                            <RadioButton
                              key={category.value}
                              labelText={category.label}
                              id={`commodities[${index}][${category.value}]`}
                              name={`commodities[${index}].category`}
                              value={category.value}
                              defaultChecked={
                                category.value === deliveryCategory
                              }
                              onChange={(e: BaseSyntheticEvent) => {
                                setValue(
                                  `commodities[${index}].category`,
                                  e.target.value,
                                  {
                                    shouldDirty: true,
                                  }
                                );
                              }}
                              ref={
                                currentSchoolEnabledTHR ||
                                currentSchoolEnabledPurchases
                                  ? register({ required: true })
                                  : register({ required: false })
                              }
                            />
                          ))}
                        </RadioButtonGroup>
                      </>
                    </div>
                    <Controller
                      render={({ onChange, value }) => (
                        <CommoditySelect
                          isDisabled={!selectedDate ? true : false}
                          showStock={true}
                          date={selectedDate}
                          key={`commodities[${index}].commodity`}
                          category={
                            currentSchoolEnabledPurchases ||
                            currentSchoolEnabledTHR
                              ? watch(`commodities[${index}].category`)
                              : deliveryCategory
                          }
                          onChange={(e: any) => {
                            setValue(
                              `commodities[${index}].measure_unit`,
                              e.measure_unit
                            );
                            onChange(e.value);

                            setSelectedCommodityId({
                              commodityId: e.value,
                              category: watch(`commodities[${index}].category`),
                            });

                            setRefreshKey(new Date().valueOf());
                          }}
                          value={value}
                          invalidOverride={
                            errors[`commodities[${index}].commodity`] !==
                            undefined
                          }
                          invalidTextOverride={
                            ERROR_MESSAGES_INCIDENTS["missingCommodity"]
                          }
                        />
                      )}
                      name={`commodities[${index}].commodity`}
                      control={control}
                    />
                    {(!currentSchoolEnabledPurchases ||
                      categoryWatch(deliveryCategory, index) ||
                      categoryWatch(takeHomeRationCategory, index)) && (
                      <div className="waybill-select">
                        <Controller
                          key={refreshKey}
                          render={({ onChange, onBlur }) => {
                            const options = getWaybillOptions(index).filter(
                              (i: any) => {
                                return moment(i.date).isSameOrBefore(
                                  moment(selectedDate)
                                );
                              }
                            );

                            const allValues = getValues();
                            const waybillNumberId =
                              Object.keys(allValues).length > 0 &&
                              Object.keys(allValues).includes("commodities")
                                ? allValues?.commodities[index]?.waybill_no
                                : undefined;

                            const waybillOption = waybillNumberId
                              ? options.find(
                                  (item: any) => item.value === waybillNumberId
                                )
                              : undefined;

                            const fieldValue = waybillOption
                              ? {
                                  value: waybillOption?.value,
                                  label: waybillOption?.label,
                                  singleValueStock: waybillOption?.stock,
                                }
                              : undefined;
                            return (
                              <Input
                                formItemClassName={classnames(
                                  "wfp--form-item commodity-select",
                                  "wfp--react-select-container"
                                )}
                                labelText={
                                  <FormattedMessage
                                    id="IncidentEdit.selectWaybill"
                                    defaultMessage="Waybill"
                                  />
                                }
                                name={`commodities[${index}].waybill_no`}
                                invalid={
                                  errors[`commodities[${index}].waybill_no`] !==
                                  undefined
                                }
                                invalidText={
                                  <FormattedMessage
                                    id="IncidentEditError.missingWaybillStar"
                                    defaultMessage="Enter a waybill number"
                                  />
                                }
                              >
                                {() => (
                                  <div className="commodity-select__input ">
                                    <Select
                                      className="wfp--react-select-container"
                                      classNamePrefix="wfp--react-select"
                                      key={`commodities[${index}].waybill_no`}
                                      styles={customStyles(
                                        errors[
                                          `commodities[${index}].waybill_no`
                                        ] !== undefined
                                      )}
                                      onChange={(e: any) => {
                                        onChange(e.value);
                                      }}
                                      isDisabled={
                                        !selectedDate ||
                                        !isCommoditySelected ||
                                        (options.find(
                                          (e: any) => e.sync === false
                                        )
                                          ? true
                                          : false) ||
                                        options.find(
                                          (e: any) =>
                                            e.consumptionsUnsynced === true
                                        )
                                          ? true
                                          : false
                                      }
                                      style={{
                                        opacity:
                                          !selectedDate || !isCommoditySelected
                                            ? "0.5"
                                            : "",
                                      }}
                                      onBlur={onBlur}
                                      value={fieldValue}
                                      components={{ SingleValue }}
                                      options={options}
                                      getOptionLabel={(option: {
                                        label: string;
                                        stock: number;
                                        measure_unit: MeasureUnit;
                                      }) => <OptionLabel option={option} />}
                                    />
                                  </div>
                                )}
                              </Input>
                            );
                          }}
                          name={`commodities[${index}].waybill_no`}
                          control={control}
                        />
                        {getWaybillOptions(index).find(
                          (e: { sync: boolean }) => e.sync === false
                        ) && (
                          <Blockquote kind="warning">
                            <p>
                              <FormattedMessage
                                id="IncidentEdit.PleaseSyncDeliveries"
                                defaultMessage="Please sync deliveries before linking an incident"
                              />
                            </p>
                          </Blockquote>
                        )}
                        {getWaybillOptions(index).find(
                          (e: { consumptionsUnsynced: boolean }) => {
                            return e.consumptionsUnsynced === true;
                          }
                        ) && (
                          <Blockquote kind="warning">
                            <p>
                              <FormattedMessage
                                id="IncidentEdit.PleaseSyncConsumptions"
                                defaultMessage="Please sync consumptions before linking an incident"
                              />
                            </p>
                          </Blockquote>
                        )}
                      </div>
                    )}
                    {categoryWatch(purchaseDetailCategory, index) && (
                      <div className="waybill-select">
                        <Controller
                          key={refreshKey}
                          render={({ onChange, onBlur }) => {
                            const options = getWaybillOptions(index);
                            const allValues = getValues();

                            const purchaseNumberId =
                              Object.keys(allValues).length > 0 &&
                              Object.keys(allValues).includes("commodities")
                                ? allValues?.commodities[index]?.id
                                : undefined;

                            const purchaseOption = purchaseNumberId
                              ? options.find(
                                  (item: { value: string }) =>
                                    item.value === purchaseNumberId
                                )
                              : undefined;

                            const fieldValue = purchaseOption
                              ? {
                                  value: purchaseOption?.value,
                                  label: purchaseOption?.label,
                                  singleValueStock: purchaseOption?.stock,
                                  singleValueMeasureUnit:
                                    purchaseOption?.measure_unit,
                                }
                              : undefined;
                            return (
                              <Input
                                formItemClassName={classnames(
                                  "wfp--form-item commodity-select",
                                  "wfp--react-select-container"
                                )}
                                labelText={
                                  <FormattedMessage
                                    id="IncidentEdit.selectPurchase"
                                    defaultMessage="Purchase"
                                  />
                                }
                                name={`commodities[${index}].purchase_commodity_id`}
                                invalid={
                                  errors[
                                    `commodities[${index}].purchase_commodity_id`
                                  ] !== undefined
                                }
                                invalidText={
                                  <FormattedMessage
                                    id="IncidentEditError.missingPurchaseNoStar"
                                    defaultMessage="* Enter a purchase number"
                                  />
                                }
                              >
                                {() => (
                                  <div className="commodity-select__input ">
                                    <Select
                                      className="wfp--react-select-container"
                                      classNamePrefix="wfp--react-select"
                                      key={`commodities[${index}].purchase_commodity_id`}
                                      styles={customStyles(
                                        errors[
                                          `commodities[${index}].purchase_commodity_id`
                                        ] !== undefined
                                      )}
                                      onChange={(e: { value: string }) => {
                                        onChange(e.value);
                                      }}
                                      onBlur={onBlur}
                                      value={fieldValue}
                                      components={{ SingleValue }}
                                      options={options}
                                      getOptionLabel={(option: {
                                        label: string;
                                        stock: number;
                                        measure_unit: MeasureUnit;
                                      }) => <OptionLabel option={option} />}
                                      isDisabled={
                                        !selectedDate ||
                                        !isCommoditySelected ||
                                        (options.find(
                                          (e: { sync: boolean }) =>
                                            e.sync === false
                                        )
                                          ? true
                                          : false) ||
                                        options.find(
                                          (e: {
                                            consumptionsUnsynced: boolean;
                                          }) => e.consumptionsUnsynced === true
                                        )
                                          ? true
                                          : false
                                      }
                                      style={{
                                        opacity:
                                          !selectedDate || !isCommoditySelected
                                            ? "0.5"
                                            : "",
                                      }}
                                    />
                                  </div>
                                )}
                              </Input>
                            );
                          }}
                          name={`commodities[${index}].purchase_commodity_id`}
                          control={control}
                        />
                        {getWaybillOptions(index).find(
                          (e: { sync: boolean }) => {
                            return e.sync === false;
                          }
                        ) && (
                          <Blockquote kind="warning">
                            <p>
                              <FormattedMessage
                                id="IncidentEdit.PleaseSyncDeliveries"
                                defaultMessage="Please sync deliveries before linking an incident"
                              />
                            </p>
                          </Blockquote>
                        )}
                        {getWaybillOptions(index).find(
                          (e: { consumptionsUnsynced: boolean }) => {
                            return e.consumptionsUnsynced === true;
                          }
                        ) && (
                          <Blockquote kind="warning">
                            <p>
                              <FormattedMessage
                                id="IncidentEdit.PleaseSyncConsumptions"
                                defaultMessage="Please sync consumptions before linking an incident"
                              />
                            </p>
                          </Blockquote>
                        )}
                      </div>
                    )}

                    <Controller
                      render={({ onChange, onBlur, value }) => {
                        return (
                          <QuantityInput
                            control={control}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            labelText={
                              <FormattedMessage
                                id="DeliveriesEdit.quantity"
                                defaultMessage="Quantity"
                              />
                            }
                            setValue={setValue}
                            name={`commodities[${index}].quantity`}
                            invalid={errors[`commodities[${index}].quantity`]}
                            invalidText={
                              errors[`commodities[${index}].quantity`]?.message
                            }
                            measureUnit={watch(
                              `commodities[${index}].measure_unit`
                            )}
                          />
                        );
                      }}
                      control={control}
                      name={`commodities[${index}].quantity`}
                    />

                    <Controller
                      name={`commodities[${index}].measure_unit`}
                      control={control}
                    />
                  </RepeaterItem>
                ))}
              </div>
              <RepeaterAddButton onClick={() => append({})} />
            </>

            <button className="hidden-btn" ref={submitBtnRef as any} />
          </form>
        </FormProvider>
      )}
    </ModalExtended>
  );
};

export default IncidentEdit;
