import React from "react";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";

import MeasureUnitValue from "components/MeasureUnitValue";
import { getStockStatus } from "helpers/stock";

import {
  deliveryCategory,
  purchaseDetailCategory,
  takeHomeRationCategory,
} from "SCConstants";

import "./_current-stock.scss";

type CurrentStockProps = {
  commodityId: string;
  category: string;
  date?: Moment;
  measureUnit: { id: number; name: string; symbol: string; decimals: number };
};

const CurrentStock = ({
  commodityId,
  category,
  date,
  measureUnit,
}: CurrentStockProps) => {
  const stockStatusDate = (date || moment()).format("YYYY-MM-DD");
  const stockStatus = useSelector(getStockStatus(stockStatusDate));
  let quantity: string | undefined;
  // There are only 3 stock types. other categories does not correspond to any stock type.
  if (
    category === deliveryCategory ||
    category === takeHomeRationCategory ||
    category === purchaseDetailCategory
  ) {
    quantity = stockStatus[category][commodityId];
  }

  return (
    <div className="stock-value">
      {quantity !== undefined && (
        <>
          Current stock:{" "}
          <MeasureUnitValue measureUnit={measureUnit}>
            {quantity}
          </MeasureUnitValue>
        </>
      )}
    </div>
  );
};

export default CurrentStock;
