import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import {
  getCurrentUser,
  getIsViewer,
  getLoggedInOffline,
} from "data-handler/ducks/auth";
import {
  clearCurrentSchoolState,
  getCurrentSchool,
  getIsSchoolsFetching,
  getSchoolsError,
  requestCurrentSchool,
  setUserCachedSchool,
} from "data-handler/ducks/schools";
import {
  getIsUserFetching,
  getUserError,
  requestUsersFromSchool,
} from "data-handler/ducks/users";
import {
  GETStores,
  setUserCachedStores,
  getIsStoresFetching,
  getStoresError,
} from "data-handler/ducks/stores";
import reportsDuck from "data-handler/ducks/reports";
import { REPORT_FETCHER } from "components/Report";
import {
  getAgeGroupsError,
  getIsAgeGroupsFetching,
  requestLevelGroupMappings,
} from "data-handler/ducks/ageGroups";
import {
  getIsRolesFetching,
  getRolesError,
  requestCountryRoleMapping,
} from "data-handler/ducks/roles";
import {
  getIsVendorCategoriesFetching,
  getVendorCategoriesError,
  requestAllApprovedVendors,
} from "data-handler/ducks/vendors";
import SchoolSelect from "components/SchoolSelect";
import { useSchoolIdFromURL } from "helpers/schools";
import {
  getIsFoodSourcesFetching,
  requestFoodSources,
  getFoodSourcesError,
} from "data-handler/ducks/foodSources";

import {
  getIsNoMealReasonsFetching,
  getNoMealReasonsError,
  requestNoMealReasons,
} from "data-handler/ducks/noMealReasons";

import {
  FetchingData,
  setSchoolInitializerData,
  setSchoolInitializerHasErrors,
  setSchoolInitializerIsFetching,
} from "data-handler/ducks/initialization";

type Country = {
  id: number;
  name: string;
};

type School = {
  id: number;
  country: Country;
  name: string;
};

type User = {
  school: School | null;
  is_admin: boolean;
  is_approver: boolean;
  is_country_admin: boolean;
  is_school_group_admin: boolean;
};

const logout = () => window.location.replace("/logout");

type SchoolInitializerProps = {
  schoolIdFromURL: number;
};

/**
 * If user's currentSchool does not match with the schoolId in the url,
 * load the school in the url.
 */
const SchoolInitializer: React.ComponentType<SchoolInitializerProps> = ({
  schoolIdFromURL,
  children,
}) => {
  const dispatch = useDispatch();
  const [isFetchingStarted, setIsFetchingStarted] = useState<boolean>(false);

  const currentSchool: School | undefined = useSelector(getCurrentSchool);
  const isOffline = useSelector(getLoggedInOffline);
  const isSchoolsFetching = useSelector(getIsSchoolsFetching);
  const isStoresFetching = useSelector(getIsStoresFetching);
  const isReportsFetching = useSelector(reportsDuck.isFetching(REPORT_FETCHER));
  const isUserFetching = useSelector(getIsUserFetching);
  const isAgeGroupsFetching = useSelector(getIsAgeGroupsFetching);
  const isRolesFetching = useSelector(getIsRolesFetching);
  const isVendorsFetching = useSelector(getIsVendorCategoriesFetching);
  const isFoodSourcesFetching = useSelector(getIsFoodSourcesFetching);
  const isNoMealReasonsFetching = useSelector(getIsNoMealReasonsFetching);

  const schoolsError = useSelector(getSchoolsError);
  const storesError = useSelector(getStoresError);
  const reportsError = useSelector(reportsDuck.getError);
  const userError = useSelector(getUserError);
  const ageGroupsError = useSelector(getAgeGroupsError);
  const rolesError = useSelector(getRolesError);
  const vendorsError = useSelector(getVendorCategoriesError);
  const foodSourcesError = useSelector(getFoodSourcesError);
  const noMealReasonsError = useSelector(getNoMealReasonsError);

  const currentCountry = currentSchool?.country;

  const isInitializationRequired =
    (!currentSchool && !!schoolIdFromURL) ||
    (schoolIdFromURL && schoolIdFromURL !== currentSchool?.id);

  useEffect(() => {
    if (isInitializationRequired && !isFetchingStarted) {
      setIsFetchingStarted(true);
    }
  }, [isFetchingStarted, isInitializationRequired]);

  useEffect(() => {
    if (isFetchingStarted) {
      const fetchingData: FetchingData[] = [
        { name: "Schools", isFetching: isSchoolsFetching, error: schoolsError },
        { name: "Stores", isFetching: isStoresFetching, error: storesError },
        { name: "Reports", isFetching: isReportsFetching, error: reportsError },
        { name: "User", isFetching: isUserFetching, error: userError },
        {
          name: "AgeGroups",
          isFetching: isAgeGroupsFetching || !currentCountry,
          error: ageGroupsError,
        },
        {
          name: "Roles",
          isFetching: isRolesFetching || !currentCountry,
          error: rolesError,
        },
        {
          name: "Vendors",
          isFetching: isVendorsFetching || !currentCountry,
          error: vendorsError,
        },
        {
          name: "Food Sources",
          isFetching: isFoodSourcesFetching,
          error: foodSourcesError,
        },
        {
          name: "No Meal Reasons",
          isFetching: isNoMealReasonsFetching,
          error: noMealReasonsError,
        },
      ];

      const isFetching = fetchingData.some((p) => p.isFetching);
      const hasErrors = fetchingData.some((p) => p.error);
      const has401 = fetchingData.some(
        (p) => p.error?.response?.status === 401
      );
      if (has401) {
        logout();
      }
      dispatch(setSchoolInitializerData(fetchingData));
      dispatch(setSchoolInitializerIsFetching(isFetching));
      dispatch(setSchoolInitializerHasErrors(hasErrors));
      if (!isFetching) {
        setIsFetchingStarted(false);
        if (hasErrors) {
          // Fetching is complete but there are errors
          Sentry.configureScope((scope) => {
            scope.setExtra("error_location", "SchoolInitializer/index.tsx");

            for (const data of fetchingData) {
              scope.setExtra(data.name, JSON.stringify(data));
            }
            scope.setLevel(Sentry.Severity.Warning);
            Sentry.captureMessage(
              "School Initialization completed with errors."
            );
          });
        }
      }
    }
  }, [
    ageGroupsError,
    currentCountry,
    dispatch,
    foodSourcesError,
    isAgeGroupsFetching,
    isFetchingStarted,
    isFoodSourcesFetching,
    isInitializationRequired,
    isNoMealReasonsFetching,
    isReportsFetching,
    isRolesFetching,
    isSchoolsFetching,
    isStoresFetching,
    isUserFetching,
    isVendorsFetching,
    noMealReasonsError,
    reportsError,
    rolesError,
    schoolsError,
    storesError,
    userError,
    vendorsError,
  ]);

  // Load currentSchool if necessary
  // ********************************************************************************
  // After this point school we should be loading must be schoolIdFromURL

  // We initialize currentSchool if there is no currentSchool or if it does not match with URL
  useEffect(() => {
    if (isFetchingStarted) {
      //setSchoolLoadedFromSelect(undefined);
      // if in offline mode load the school from the the data cached in initializer
      if (isOffline) {
        dispatch(setUserCachedSchool(schoolIdFromURL));
        dispatch(setUserCachedStores(schoolIdFromURL));
      } else {
        dispatch(requestCurrentSchool(schoolIdFromURL));
        dispatch(GETStores(schoolIdFromURL));
        dispatch(
          reportsDuck.fetchList(REPORT_FETCHER, { school: schoolIdFromURL })
        );
        // TODO check why we need this to understand if we need this in offline mode.
        dispatch(requestUsersFromSchool(schoolIdFromURL));
      }
    }
  }, [dispatch, isFetchingStarted, isOffline, schoolIdFromURL]);

  useEffect(() => {
    // note that currentCountry will be assigned after currentSchool initialization is complete
    if (currentCountry?.id && !isOffline) {
      dispatch(requestLevelGroupMappings(currentCountry.id));
      dispatch(requestCountryRoleMapping(currentCountry.id));
      dispatch(requestAllApprovedVendors(currentCountry.id));
      dispatch(requestFoodSources(currentCountry.id));
      dispatch(requestNoMealReasons(currentCountry.id));
    }
  }, [currentCountry, dispatch, isOffline]);

  return <>{children}</>;
};

type SchoolInitializerWithURLParserProps = {};

const SchoolInitializerWithURLParser: React.ElementType<SchoolInitializerWithURLParserProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const schoolIdFromURL = useSchoolIdFromURL();
  const currentUser: User | undefined = useSelector(getCurrentUser);
  const currentSchool: School | undefined = useSelector(getCurrentSchool);
  const isUserSchoolStaff = currentUser?.school?.id && !currentUser.is_admin;

  const isViewer = useSelector(getIsViewer);

  const currentUserHasMultipleSchools =
    currentUser &&
    (currentUser.is_admin ||
      currentUser.is_approver ||
      currentUser.is_country_admin ||
      currentUser.is_school_group_admin ||
      isViewer);

  // If user is a school staff and they are looking into a school that is not theirs,
  // just redirect them to their own school.
  // TODO: Create allowedSchoolIds variable and check current school agains it.
  useEffect(() => {
    if (
      currentUser?.school &&
      isUserSchoolStaff &&
      (currentSchool?.id !== currentUser.school?.id || !schoolIdFromURL)
    ) {
      dispatch(clearCurrentSchoolState());
      history.push(`/school/${currentUser.school.id}`);
    }
  }, [
    currentSchool,
    currentUser,
    dispatch,
    history,
    isUserSchoolStaff,
    schoolIdFromURL,
  ]);

  const showSchoolSelect =
    !schoolIdFromURL && !currentSchool && currentUserHasMultipleSchools;

  if (showSchoolSelect) {
    return (
      <>
        <SchoolSelect />
      </>
    );
  } else if (schoolIdFromURL === undefined) {
    // If there is no school Id from url
    // this page is not one of the pages that we target.
    // it might be school select page.
    // just render the children
    return <>{children}</>;
  } else {
    return (
      <SchoolInitializer schoolIdFromURL={schoolIdFromURL}>
        {children}
      </SchoolInitializer>
    );
  }
};

export default SchoolInitializerWithURLParser;
