import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Select, { components } from "react-select";
import lodash from "lodash";
import classnames from "classnames";
import { useParams } from "react-router-dom";

import { Input } from "@wfp/ui";

import {
  getCommoditiesInStoreByCategory,
  getStockStatus,
  StockStatus,
} from "helpers/stock";

import {
  getCurrentSchoolProfileWfpCommodities,
  getCurrentSchoolProfileOtherCommodities,
} from "data-handler/ducks/schools";
import { getAllStores, MeasureUnit } from "data-handler/ducks/stores";
import commoditiesDuck from "data-handler/ducks/commodities";

import CurrentStock from "components/CurrentStock";

import {
  deliveryCategory,
  purchaseDetailCategory,
  takeHomeRationCategory,
  nonWfpCategory,
} from "SCConstants";

import "./_commodity-select.scss";

type SingleValueData = {
  value: string;
  category: string;
  measure_unit: MeasureUnit;
};

type SingleValueProps = {
  data: SingleValueData;
  selectProps?: {
    selectProps: {
      date: moment.Moment;
    };
  };
};

const SingleValue: React.ElementType<SingleValueProps> = ({
  children,
  ...props
}) => {
  return (
    <components.SingleValue {...props}>
      {children}
      <div className="commodity-select__stock">
        <CurrentStock
          commodityId={props.data.value}
          category={props.data.category}
          date={props.selectProps?.selectProps?.date}
          measureUnit={props.data.measure_unit}
        />
      </div>
    </components.SingleValue>
  );
};

function sortByStockAndLabel(
  a: { label: string; stock: number | string | undefined },
  b: { label: string; stock: number | string | undefined },
  showStock: boolean
): number {
  // function sortByStockAndLabel(a, b, showStock) {
  let aStock = typeof a.stock === "number" ? a.stock : 0;
  let bStock = typeof b.stock === "number" ? b.stock : 0;
  let aLabel = a.label;
  let bLabel = b.label;

  if (showStock) {
    if (aStock === bStock) {
      return aLabel < bLabel ? -1 : aLabel >= bLabel ? 1 : 0;
    } else {
      return aStock > bStock ? -1 : aStock <= bStock ? 1 : 0;
    }
  } else {
    return aLabel < bLabel ? -1 : aLabel >= bLabel ? 1 : 0;
  }
}

// TODO We should clean up commodity types and use the actual type here.
type CreateOptionsCommodity = {
  id: number;
  is_wfp: boolean;
  name: string;
  measure_unit: MeasureUnit;
  stock: number;
};

function createOptions(
  params: CommoditySelectParams,
  input: CreateOptionsCommodity[],
  category: string,
  wfpOnly: boolean,
  showStock: boolean,
  stockStatus: StockStatus
) {
  const wfpCommodities = input.filter((i) => i.is_wfp);
  const otherCommodities = input.filter((i) => !i.is_wfp);
  let commoditiesList: CreateOptionsCommodity[] = [];
  if (
    category === deliveryCategory ||
    wfpOnly ||
    category === takeHomeRationCategory
  ) {
    commoditiesList = wfpCommodities;
  } else if (category === purchaseDetailCategory) {
    commoditiesList = input;
  } else if (!wfpOnly || category === nonWfpCategory) {
    commoditiesList = otherCommodities;
  } else {
    return [];
  }

  let newCommoditiesList = commoditiesList.map((e: CreateOptionsCommodity) => {
    let stockValue;

    if (category === deliveryCategory) {
      stockValue = stockStatus.delivery[e.id];
    } else if (category === takeHomeRationCategory) {
      stockValue = stockStatus.takehomeration[e.id];
    } else if (category === purchaseDetailCategory) {
      stockValue = stockStatus.purchasedetail[e.id];
    }

    if (stockValue === undefined) {
      stockValue = "No stock";
    } else {
      stockValue = Number(stockValue);
    }

    return {
      value: e.id,
      label: e.name,
      ...(category !== nonWfpCategory && {
        stock: stockValue,
      }),
      category: category,
      measure_unit: e.measure_unit,
      isWfp: e.is_wfp,
    };
  });

  // filter the wfp categories by stock > 0, but only for some of the pages
  if (
    (params.details === "meals" ||
      params.details === "incident" ||
      params.details === "new-incident" ||
      params.details === "new-meals" ||
      params.details === "new-take-home-ration-data" ||
      params.details === "take-home-ration-data") &&
    (category === deliveryCategory ||
      category === purchaseDetailCategory ||
      category === takeHomeRationCategory)
  ) {
    newCommoditiesList = newCommoditiesList.filter(
      (item) => item.stock && typeof item.stock === "number" && item.stock > 0
    );
  }

  return newCommoditiesList
    ?.map((e) => ({
      value: e.value,
      label: e.label,
      stock: e.stock,
      isWfp: e.isWfp,
      measure_unit: e.measure_unit,
    }))
    .sort((a, b) => sortByStockAndLabel(a, b, showStock));
}

type CommoditySelectParams = {
  item: string;
  thrItem: string;
  studentItem: string;
  details: string;
};

type CommoditySelectOnChange = (args: {
  value: string;
  category: string;
  measure_unit: MeasureUnit;
}) => void;
type InvalidOptions = { commodity: string }[];

type CommoditySelectProps = {
  className?: string;
  control?: any;
  invalidOverride: boolean;
  invalidTextOverride: React.ReactElement;
  onChange: CommoditySelectOnChange;
  name?: string;
  value: string;
  wfpOnly?: boolean;
  category: string;
  showStock?: boolean;
  date?: moment.MomentInput | undefined;
  invalidOptions?: InvalidOptions;
  isDisabled?: boolean;
  required?: boolean;
  onBlur?: any;
  setValue?: any;
  inputRef?: any;
};

const CommoditySelect: React.ElementType<CommoditySelectProps> = ({
  className,
  control,
  invalidOverride,
  invalidTextOverride,
  onChange,
  name,
  value,
  wfpOnly,
  category,
  showStock,
  date,
  invalidOptions,
  isDisabled,
  // Note to self: I am adding those attributes,
  // because they are being sent as a property but not used.
  // Since they do not exist in the property list usage of them does not typecheck.
  // they are likely have no effect but I am not 100% sure. at some point we want to test.
  // if adding those attributes have any effect. if not we should just remove them.
  required,
  onBlur,
  setValue,
  inputRef,
}) => {
  const [
    singleCommodityOptionSelected,
    setSingleCommodityOptionSelected,
  ] = useState(false);
  // refreshKey used to refresh the commodity dropdown
  const [refreshKey, setRefreshKey] = useState(new Date().valueOf());

  const params: CommoditySelectParams = useParams();

  const newDate = useMemo(() => {
    return date
      ? moment(date)
      : params.item && params.item.split("+").length === 2
      ? moment(params.item.split("+")[1])
      : params.thrItem && params.thrItem.split("+").length === 2
      ? moment(params.thrItem.split("+")[1])
      : params.studentItem && params.studentItem.split("+").length === 2
      ? moment(params.studentItem.split("+")[1])
      : undefined;
  }, [date, params]);

  const allStores = useSelector(getAllStores);
  const commoditiesIndex = useSelector(commoditiesDuck.getIndex);
  const commoditiesListWfpFromSchoolProfile = useSelector(
    getCurrentSchoolProfileWfpCommodities
  );

  const stockStatusDate = (newDate || moment()).format("YYYY-MM-DD");
  const stockStatus = useSelector(getStockStatus(stockStatusDate));

  const isPurchaseConsumption = useMemo(() => {
    return (
      (params.details?.includes("new-meals") ||
        params.details?.includes("meals")) &&
      category === purchaseDetailCategory
    );
  }, [params.details, category]);

  const nonWfpCommoditiesInStore = useMemo(() => {
    return getCommoditiesInStoreByCategory(
      allStores,
      commoditiesIndex,
      false,
      category,
      newDate && newDate.format("YYYY-MM-DD")
    );
  }, [category, newDate, commoditiesIndex, allStores]);

  const wfpCommodities = useMemo(() => {
    return getCommoditiesInStoreByCategory(
      allStores,
      commoditiesIndex,
      true,
      category,
      newDate && newDate.format("YYYY-MM-DD")
    );
  }, [category, newDate, commoditiesIndex, allStores]);

  const commodityListOther = useSelector(
    getCurrentSchoolProfileOtherCommodities
  );

  const commodityListWfp = useMemo(() => {
    return params.details === "new-year" || params.details === "year"
      ? lodash.uniqBy(
          commoditiesListWfpFromSchoolProfile.concat(wfpCommodities),
          "id"
        )
      : params.details === "new-delivery" ||
        params.details === "delivery" ||
        params.details === "purchase-detail" ||
        params.details === "new-purchase" ||
        params.details === "new-take-home-ration-data" ||
        params.details === "take-home-ration-data"
      ? commoditiesListWfpFromSchoolProfile
      : wfpCommodities;
  }, [params, commoditiesListWfpFromSchoolProfile, wfpCommodities]);

  const commodityListAll = useMemo(() => {
    return commodityListWfp
      .concat(
        isPurchaseConsumption ? nonWfpCommoditiesInStore : commodityListOther
      )
      .filter(
        ({ id }: { id: string }) =>
          !invalidOptions?.find(({ commodity }) => id === commodity)
      );
  }, [
    commodityListWfp,
    isPurchaseConsumption,
    nonWfpCommoditiesInStore,
    commodityListOther,
    invalidOptions,
  ]);

  const options = useMemo(
    () =>
      createOptions(
        params,
        commodityListAll,
        category,
        wfpOnly!,
        showStock!,
        stockStatus
      ),
    [params, commodityListAll, category, wfpOnly, showStock, stockStatus]
  );

  useEffect(() => {
    setSingleCommodityOptionSelected(false);
    // Refresh the commodity dropdown as sometimes the SingleValue
    // children component is not automatically updating
    setRefreshKey(new Date().valueOf());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    /*
     * - If there is only one available option, the commodity
     *   dropdown gets pre-populated with its values
     *
     * - Because the main form is actually handled in the parent component,
     *   the onChange prop has to be called if the commodity dropdown is
     *   pre-populated (to prevent in infinite loop, we use the
     *   singleCommodityOptionSelected state value to only call the onChange prop once)
     */

    if (
      options.length === 1 &&
      singleCommodityOptionSelected === false &&
      category !== undefined
    ) {
      onChange({
        value: options[0].value.toString(),
        category: category,
        measure_unit: options[0].measure_unit,
      });
      setSingleCommodityOptionSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const invalid = control
    ? lodash.get(control.errorsRef.current, name!)
    : // I was unable to get Commodity to read from custom validation errors
      invalidOverride && { message: invalidTextOverride };

  const option =
    commodityListAll.find((item: { id: string }) => item.id === value) ||
    params.details === "delivery" ||
    params.details === "purchase-detail"
      ? commodityListAll.find((item: { id: string }) => item.id === value)
      : undefined;

  const defaultValue = option
    ? {
        value: option && option.id,
        label: option ? option.name : "label missing",
        category: category,
        measure_unit: option && option.measure_unit,
      }
    : options.length === 1 && category !== undefined
    ? {
        value: options[0].value,
        label: options[0].label,
        category: category,
        measure_unit: options[0].measure_unit,
      }
    : undefined;

  const classes = classnames(`wfp--form-item commodity-select`, className, {
    [`wfp--text-input--invalid`]: invalid,
  });

  // if only wfp commodities are needed, just return those
  const groupedOptions = useMemo(() => {
    if (category === deliveryCategory) {
      return [
        {
          label: (
            <FormattedMessage
              id="CommoditySelect.deliveredwfpCommodities"
              defaultMessage="Delivered WFP commodities"
            />
          ),
          options: options,
        },
      ];
    } else if (category === purchaseDetailCategory) {
      let tempOptions = [
        {
          label: (
            <FormattedMessage
              id="CommoditySelect.purchasedWfpCommodities"
              defaultMessage="Purchased WFP commodities"
            />
          ),
          options: options.filter((item) => item.isWfp),
        },
      ];
      if (!wfpOnly) {
        tempOptions.push({
          label: (
            <FormattedMessage
              id="CommoditySelect.otherCommodities"
              defaultMessage="Other commodities"
            />
          ),
          options: options.filter((item) => !item.isWfp),
        });
      }
      return tempOptions;
    } else if (category === takeHomeRationCategory) {
      return [
        {
          label: (
            <FormattedMessage
              id="CommoditySelect.thrWfpCommodities"
              defaultMessage="Take-home rations commodities"
            />
          ),
          options: options,
        },
      ];
    } else if (!wfpOnly || category === nonWfpCategory) {
      return [
        {
          label: (
            <FormattedMessage
              id="CommoditySelect.otherCommodities"
              defaultMessage="Other commodities"
            />
          ),
          options: options,
        },
      ];
    }
  }, [category, options, wfpOnly]);

  const customStyles = (showStock: boolean, invalid: boolean) => ({
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color:
          showStock &&
          state.data?.stock &&
          parseFloat(state.data?.stock) > 0 &&
          !state.isSelected
            ? "#689e18"
            : "inherit",
      };
    },
    control: (styles: any) =>
      invalid === false || invalid === undefined
        ? { ...styles, border: "1px solid #8ca4b5" }
        : { ...styles, borderColor: "#C5192D !important" },
  });

  return (
    <Input
      formItemClassName={classes}
      labelText={
        <FormattedMessage
          id="CommoditySelect.commodityInputLabel"
          defaultMessage="Commodity"
        />
      }
      name={name}
      invalid={invalid}
      invalidText={invalid && invalid.message}
    >
      {() => (
        <div className="commodity-select__input">
          <Select
            key={refreshKey}
            options={groupedOptions}
            name={name}
            value={defaultValue}
            styles={customStyles(showStock!, invalid)}
            className="wfp--react-select-container"
            classNamePrefix="wfp--react-select"
            selectProps={{ date: newDate }}
            components={{ SingleValue }}
            onChange={(selected: SingleValueData) => {
              onChange({
                value: selected.value,
                category: selected.category,
                measure_unit: selected.measure_unit,
              });
            }}
            isDisabled={isDisabled}
          />
        </div>
      )}
    </Input>
  );
};

export default CommoditySelect;
