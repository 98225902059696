import React, { BaseSyntheticEvent, useRef } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment";

import {
  NumberInput,
  Checkbox,
  RadioButtonGroup,
  Select,
  SelectItem,
  Input,
  Blockquote,
} from "@wfp/ui";

import ModalExtended from "components/ModalExtended";
import DateInput from "components/DateInput";
import { Repeater, RepeaterItem, RepeaterTitle } from "components/Repeater";
import AddButton from "components/RepeaterAddButton";
import ErrorList from "components/ErrorList";

import {
  studentKinds,
  allLevels,
  studentLevelValues,
  selectContainerStyles,
  deliveryCategory,
} from "SCConstants";
import useStore from "helpers/useStore";

import { schoolYearValidationResolver } from "./schoolYearValidation";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStores,
  getIsSyncableLocked,
  getPreviousSchoolYears,
  getCurrentSchoolYear,
  getSchoolYearByDate,
  resetStore,
  SchoolYear,
  Incident,
  SchoolYearLevel,
  IncidentCommodity,
} from "data-handler/ducks/stores";
import { getModelDateFormat } from "components/UneditableMessage";

import Gate from "../Gate";
import { getLevelAgeMappings } from "data-handler/ducks/ageGroups";
import CommoditySelect from "../CommoditySelect";
import QuantityInput from "components/QuantityInput";
import CommoditiesAddButton from "components/RepeaterAddButton";
import { getCurrentSchoolProfileWfpCommodities } from "../../data-handler/ducks/schools";

import {
  getInitialCarryOverStock,
  getInitialStockIncidents,
} from "helpers/stock";
import YearEditIncidentReasons from "components/YearEditIncidentReasons";
import incidentCausesDuck from "data-handler/ducks/incidentCauses";
import { incidentTypes } from "components/IncidentEdit/index";
import {
  getIsReportLocked,
  getReportByMoment,
} from "data-handler/ducks/reports";

import "./styles.scss";

import CommodityTextView from "components/CommodityTextView";
import { RootState } from "data-handler/rootReducer";

const requiredLabelCommodities = (
  <div className="wfp--form-required">
    <FormattedMessage
      id="DeliveriesEdit.commodityError"
      defaultMessage=" Select commodity"
    />
  </div>
);

type FormValues = SchoolYear & Record<"causes", boolean[]>;

const storeToFormValues = (store: SchoolYear) => {
  const _store: FormValues = JSON.parse(JSON.stringify(store)); // deep copy

  const causes: boolean[] = [];

  if ("causes" in _store) {
    // TODO find out if this is a valid code. School year does not have causes
    // _store is incident?
    ((_store.causes as unknown) as number[]).forEach(
      (causeFk: number) => (causes[causeFk] = true)
    );
  }

  // Cast initial enrolment figures to strings
  if ("levels" in _store && _store.levels) {
    _store.levels.forEach((level: SchoolYearLevel) => {
      studentKinds.forEach((kind) => {
        level[`initial_${kind.value}`] = `${
          level[`initial_${kind.value}`]
        }` as any;
      });
    });
  }

  const reasonValue = (_store as any)?.reason;
  const reason = { label: incidentTypes[reasonValue], value: reasonValue };

  return { ..._store, reason, causes };
};

const formValuesToStore = (values: any) => {
  // Cast initial enrolment figures to ints
  values.levels.forEach((level: any) => {
    studentKinds.forEach((kind) => {
      level[`initial_${kind.value}`] =
        parseInt(level[`initial_${kind.value}`]) || 0;
    });
  });

  // Sort levels in-place according to business rules (tl;dr: increasing student age)
  values.levels.sort(
    (a: SchoolYearLevel, b: SchoolYearLevel) =>
      studentLevelValues.indexOf(a.level) - studentLevelValues.indexOf(b.level)
  );

  return values;
};

const generateIncidentObjects = (
  quantity: number,
  list: { quantity: number; batch_no: string }[],
  incidentTemplate: any
) => {
  let totalQuantity = quantity;
  let incidentObjects: any[] = [];
  list.forEach((item) => {
    if (totalQuantity <= 0) return;
    const deliveryNewQuantity =
      parseInt((item.quantity as unknown) as string) -
      parseInt((totalQuantity as unknown) as string);
    const quantityUsed =
      deliveryNewQuantity > 0 ? totalQuantity : item.quantity;
    const commodities = [
      {
        ...incidentTemplate.commodities,
        quantity: quantityUsed,
        batch_no: item.batch_no,
      },
    ];
    incidentObjects.push({ ...incidentTemplate, commodities });
    totalQuantity = totalQuantity - quantityUsed;
  });

  return incidentObjects;
};

const combineCommoditiesWithQuantity = (
  originalCommodity: IncidentCommodity,
  secondaryCommodity: IncidentCommodity
): IncidentCommodity => {
  return {
    ...originalCommodity,
    quantity: parseFloat(secondaryCommodity.quantity).toFixed(3),
  };
};

const checkCommodityIdAndBatchNo = (
  originalCommodity: IncidentCommodity,
  secondaryCommodity: IncidentCommodity
) => {
  return (
    originalCommodity.commodity === secondaryCommodity.commodity &&
    originalCommodity.batch_no === secondaryCommodity.batch_no
  );
};

const aggregateCommoditiesByQuantityAndBatchNo = (
  original: Incident,
  secondary: Incident
) => {
  let allCommodities = [...original.commodities, ...secondary.commodities];
  let newCommodities: IncidentCommodity[] = [];

  original.commodities.forEach((originalCommodity) => {
    const correspondingCommodity = secondary.commodities.find(
      (secondaryCommodity) =>
        checkCommodityIdAndBatchNo(originalCommodity, secondaryCommodity)
    );

    if (!correspondingCommodity) return;
    allCommodities = allCommodities.filter(
      (item) =>
        !checkCommodityIdAndBatchNo(item, originalCommodity) ||
        !checkCommodityIdAndBatchNo(item, correspondingCommodity)
    );
    const alreadyCombinedQuantity = newCommodities.find((newCommodity) =>
      checkCommodityIdAndBatchNo(originalCommodity, newCommodity)
    );
    const newCommodityObject = combineCommoditiesWithQuantity(
      originalCommodity,
      correspondingCommodity
    );
    if (alreadyCombinedQuantity) {
      newCommodities.push(
        combineCommoditiesWithQuantity(
          newCommodityObject,
          alreadyCombinedQuantity
        )
      );
    } else {
      newCommodities.push(newCommodityObject);
    }
  });

  return {
    ...original,
    commodities: [...newCommodities, ...allCommodities],
  };
};

const aggregateCommodities = (filteredList: Incident[]) => {
  if (filteredList.length === 0) return;

  let newCauses: any = [];
  let reasonID;
  let newCommodities: any = [];
  let occurred_at;
  filteredList.forEach((incident) => {
    if (!incident) return;
    const { causes, commodities, reason, occurred_at: date } = incident;
    commodities.forEach((item) => newCommodities.push(item));
    causes.forEach((item) => newCauses.push(item));
    reasonID = reason;
    occurred_at = date;
  });

  return {
    reason: reasonID,
    causes: [...new Set(newCauses)],
    commodities: newCommodities,
    is_initial_stock_incident: true,
    occurred_at,
  };
};

const createIncident = (
  values: any,
  carryOverStock: any,
  dateTime: any,
  incidentCauses: any,
  initialStockIncidents: any
) => {
  // If there are no causes, incident does not need to be created.
  if (
    values.commodities === undefined ||
    values.commodities.map((commodity: any) => commodity.reason).length === 0
  )
    return;

  // creates incident if value less than carry over is inputted
  const formattedIncidentObjects = values?.commodities
    .filter((object: any) => object.reason)
    .reduce((acc: any, cur: any) => {
      const commodityId = cur.commodity;
      if (!Object.keys(carryOverStock).includes(commodityId.toString())) {
        return null;
      }
      const carryOverStockObject = carryOverStock[commodityId];

      const oldQuantity = carryOverStockObject["totalQuantity"].toFixed(3);
      if (cur.quantity > oldQuantity) return acc;
      const incidentQuantity = parseFloat(
        (
          parseFloat(oldQuantity) -
          parseFloat(cur.quantity) -
          parseFloat(
            initialStockIncidents["quantityPerCommodity"][commodityId]
              ?.totalQuantity || 0
          )
        ).toFixed(3)
      );
      const deliveryCommodities = Object.keys(
        carryOverStockObject["quantityPerBatchNo"]
      )
        .map((batch_no) => ({
          batch_no: batch_no,
          quantity: parseFloat(
            (
              parseFloat(
                carryOverStockObject["quantityPerBatchNo"][batch_no]["quantity"]
              ) -
              parseFloat(
                initialStockIncidents["quantityPerCommodity"][commodityId]
                  ?.quantityPerBatchNo[batch_no]?.quantity || 0
              )
            ).toFixed(3)
          ),
          date: carryOverStockObject["quantityPerBatchNo"][batch_no]["date"],
        }))
        .sort((a, b) => (moment(b.date).isAfter(a.date) ? -1 : 1));

      let incidents = [];

      if (deliveryCommodities?.length !== 0) {
        // sorts the corresponding deliveries by date
        const causes = [parseInt(cur.cause)];
        const reason = parseInt(cur.reason);
        const incidentTemplate = {
          reason,
          causes,
          commodities: {
            commodity: commodityId,
            category: deliveryCategory,
            is_initial_stock: true,
          },
          occurred_at: moment(dateTime).format("YYYY-MM-DD"),
          is_initial_stock_incident: true,
          issues: [],
        };

        incidents = generateIncidentObjects(
          incidentQuantity,
          deliveryCommodities,
          incidentTemplate
        );
      }

      // Due to requirement change, causes are only 1 per incident. Not multiple selected.
      // Therefore still requires to be a list.
      const deliveryQuantity =
        incidentQuantity > 0 ? [...acc, ...incidents] : acc;
      return deliveryQuantity;
    }, []);

  const incidentList = incidentCauses?.reduce((acc: any, { id }: any) => {
    const filteredCause = formattedIncidentObjects.filter((object: any) => {
      return object.causes[0] === id;
    });
    const finalFilter: any = [];

    Object.keys(incidentTypes).forEach((reason) => {
      const filteredReason = filteredCause.filter(
        (object: any) => parseInt(object.reason) === parseInt(reason)
      );

      // [{reason 1, cause 1}, {reason 1, cause 1}]
      // combines commodities into one object
      finalFilter.push(aggregateCommodities(filteredReason));
    });

    return finalFilter.length > 0 ? [...acc, ...finalFilter] : acc;
  }, []);

  return incidentList.filter((item: any) => item !== undefined);
};

const createInitialStock = (
  values: any,
  carryOverStock: any,
  dateTime: any,
  firstSchoolYear: any
) => {
  // Create the delivery object together with the appropriate commodities objects

  if (values?.commodities !== undefined) {
    const newCommodities = values?.commodities
      .filter((item: any) => item.quantity >= 0)
      .reduce((acc: any, cur: any) => {
        const deliveriesList = [...acc];
        const commodityId = cur.commodity;

        // If the commodity is not in the carry over, just
        // create a new delivery commodity for it
        // This can only happen in the case of the school's first school year
        if (
          !Object.keys(carryOverStock).includes(commodityId.toString()) ||
          firstSchoolYear
        ) {
          if (parseFloat(cur.quantity) > 0) {
            const newDeliveryCommodity = {
              commodity: commodityId,
              quantity: cur.quantity,
              category: deliveryCategory,
              is_carry_over: true,
              batch_no: "unknown",
            };

            deliveriesList.push(newDeliveryCommodity);
          }
          return deliveriesList;
        }

        const batchNos = carryOverStock[commodityId]["quantityPerBatchNo"];
        Object.keys(batchNos).forEach((batchNo) => {
          const oldDeliveryCommodityQuantity =
            parseFloat(
              parseFloat(batchNos[batchNo]["quantity"] || 0).toFixed(3)
            ) || 0;
          // If there is carry over stock for the commodity, create a delivery
          // commodity dict with the flag is_carry_over set to True
          if (oldDeliveryCommodityQuantity > 0) {
            const existingDeliveryCommodity = {
              commodity: commodityId,
              quantity: oldDeliveryCommodityQuantity,
              category: deliveryCategory,
              is_carry_over: true,
              batch_no: batchNo,
            };

            deliveriesList.push(existingDeliveryCommodity);
          }
        });

        return deliveriesList;
      }, [])
      .map((item: any) => {
        item.issues = [];
        return item;
      });

    return {
      commodities: newCommodities,
      delivered_at: moment(dateTime, "YYYY-MM-DD HH:mm").toISOString(),
      waybill_no: "Initial stock",
      is_initial_stock: true,
      category: deliveryCategory,
    };
  }
};

const updateExistingIncidents = (
  incidentList: any,
  values: any,
  carryOverStock: any,
  initialCarryOverStock: any,
  initialStockIncidents: any
) => {
  // Check if stock has been increased after the last adjustment
  let increasedStockCommodities = values?.commodities
    .filter((item: any) => item.quantity > carryOverStock[item.commodity])
    .reduce((acc: any, cur: any) => {
      acc[cur.commodity] = cur.quantity - carryOverStock[cur.commodity];
      return acc;
    }, {});

  const updatedIncidents = initialStockIncidents["incidentObjects"]
    .sort((a: any, b: any) =>
      moment(b.last_edit).isAfter(a.last_edit) ? -1 : 1
    )
    .map((item: any) => {
      let incidentCommodities = item.commodities
        .sort((a: any, b: any) => {
          const firstCommodityDate = Object.keys(
            initialCarryOverStock
          ).includes(a.commodity.toString())
            ? initialCarryOverStock[a.commodity]["quantityPerBatchNo"][
                a.batch_no
              ]?.date
            : undefined;
          const secondCommodityDate = Object.keys(
            initialCarryOverStock
          ).includes(b.commodity.toString())
            ? initialCarryOverStock[b.commodity]["quantityPerBatchNo"][
                b.batch_no
              ]?.date
            : undefined;
          return moment(secondCommodityDate?.date).isAfter(
            firstCommodityDate?.date
          )
            ? 1
            : -1;
        })
        .map((incidentCommodity: any) => {
          const currentQuantity = incidentCommodity.quantity;
          const increasedQuantity =
            increasedStockCommodities[incidentCommodity.commodity] || 0;

          const updatedQuantity =
            currentQuantity <= increasedQuantity
              ? currentQuantity
              : increasedQuantity;

          increasedStockCommodities[incidentCommodity.commodity] = parseFloat(
            (
              parseFloat(increasedQuantity) - parseFloat(updatedQuantity)
            ).toFixed(3)
          );
          return {
            ...incidentCommodity,
            quantity: parseFloat(
              (
                parseFloat(currentQuantity) - parseFloat(updatedQuantity)
              ).toFixed(3)
            ),
          };
        });

      const { causes, is_initial_stock_incident, occurred_at, reason } = item;

      return {
        causes,
        is_initial_stock_incident,
        occurred_at,
        reason,
        commodities: incidentCommodities,
      };
    });

  // With the current implementation, updatedIncidents will have all the incidents existing no matter what
  // if not actually updated, the objects in updatedIncidents will have the previous values
  // Therefore, we need to handle further stock deductions here
  updatedIncidents.forEach((item: any) => {
    const correspondingIncidentIndex = incidentList.findIndex(
      (incident: any) =>
        incident.causes[0] === item.causes[0] &&
        parseInt(incident.reason) === parseInt(item.reason) &&
        incident.occurred_at === item.occurred_at &&
        incident.is_initial_stock_incident === true &&
        item.is_initial_stock_incident === true
    );

    if (correspondingIncidentIndex !== -1) {
      const commoditiesAffected = item.commodities.map((i: any) => i.commodity);

      let oldIncidentCommodities = incidentList[
        correspondingIncidentIndex
      ].commodities.filter(
        (i: any) => !commoditiesAffected.includes(i.commodity)
      );

      const matchingCommoditiesIncidents = incidentList[
        correspondingIncidentIndex
      ].commodities.filter((i: any) =>
        commoditiesAffected.includes(i.commodity)
      );

      matchingCommoditiesIncidents.forEach((i: any) => {
        const matchingCommodityEntry = item.commodities.find(
          (j: any) => i.commodity === j.commodity
        );

        if (matchingCommodityEntry) {
          if (matchingCommodityEntry.batch_no === i.batch_no) {
            oldIncidentCommodities.push({
              ...matchingCommodityEntry,
              quantity: matchingCommodityEntry.quantity + i.quantity,
            });
          } else {
            oldIncidentCommodities.push(i);
          }
        }
      });

      incidentList[correspondingIncidentIndex] = {
        ...incidentList[correspondingIncidentIndex],
        commodities: [...oldIncidentCommodities, ...item.commodities],
      };
    } else {
      incidentList.push(item);
    }
  });
  return incidentList;
};

type YearEditProps = {
  currentStoreData: SchoolYear;
};
const YearEdit: React.ElementType<YearEditProps> = ({ currentStoreData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const previousSchoolYears = useSelector(getPreviousSchoolYears);
  const currentYear = useSelector((state: RootState) =>
    getCurrentSchoolYear(state)
  );

  const firstSchoolYear = previousSchoolYears.length === 0;

  const carryOverDate =
    previousSchoolYears.length > 0 && !currentStoreData
      ? previousSchoolYears[0].ends_on
      : currentStoreData?.starts_on;

  // Previous school years without the one that is being edited
  const filteredPreviousSchoolYears = currentStoreData
    ? previousSchoolYears.filter(
        (year) =>
          year.starts_on !== currentStoreData.starts_on &&
          year.ends_on !== currentStoreData.ends_on
      )
    : previousSchoolYears;

  const previousYearEndDate =
    filteredPreviousSchoolYears.length > 0
      ? moment(filteredPreviousSchoolYears[0].ends_on)
          .add(1, "days")
          .format("YYYY-MM-DD")
      : null;

  const yearStartsOn = moment(currentStoreData?.starts_on);
  const firstReport = useSelector((state: RootState) =>
    getReportByMoment(state, yearStartsOn)
  );

  const selectedSchoolYear = useSelector(
    getSchoolYearByDate(
      getModelDateFormat(currentStoreData) || moment().format("YYYY-MM-DD")
    )
  );

  const isLocked = useSelector((state: RootState) =>
    getIsSyncableLocked(state, currentStoreData)
  );

  // We let users edit inital stock if
  // - They did not close the report for the first month.
  // - They are updating current school year.
  const isInitialStockEditable =
    !getIsReportLocked(firstReport) &&
    (currentYear === null || currentYear === selectedSchoolYear);

  const initialCarryOverStock = useSelector((state: RootState) =>
    getInitialCarryOverStock(state, carryOverDate, currentStoreData)
  );

  const initialStockIncidents = useSelector((state: RootState) =>
    getInitialStockIncidents(state, currentStoreData?.starts_on)
  );

  const carryOverStock: any = {};

  for (let commodityId of Object.keys(initialCarryOverStock)) {
    const commodity = initialCarryOverStock[commodityId];
    const quantity = parseFloat(
      (
        parseFloat(commodity["totalQuantity"]) -
        parseFloat(
          initialStockIncidents["quantityPerCommodity"][commodityId]
            ?.totalQuantity || 0
        )
      ).toFixed(3)
    );
    carryOverStock[commodityId] = {
      measure_unit: commodity.measure_unit,
      quantity,
    };
  }

  const updatedIncidentQuantities = useRef(
    Object.keys(initialStockIncidents["quantityPerCommodity"]).reduce(
      (acc: any, cur: any) => {
        acc[cur] =
          initialStockIncidents["quantityPerCommodity"][cur]?.totalQuantity ||
          0;
        return acc;
      },
      {}
    )
  );

  const showIncidentForCommodity: any = useRef();
  const showQuantityExceededWarning: any = useRef();
  const incidentCauses = useSelector(incidentCausesDuck.getList);
  /**
   * Using Age group levels therefore we only display levels that are mapped to comet age groups.
   * This also allows us to use custom names
   */
  const ageGroupLevels = useSelector(getLevelAgeMappings);
  const levels = ageGroupLevels.length > 0 ? ageGroupLevels : allLevels;

  // All the active levels
  const activeLevels =
    ageGroupLevels.length > 0
      ? ageGroupLevels.filter((item: any) => item.active)
      : allLevels;

  // The levels that are in the current school year, but not active
  // (they were deactivated after the current school year was created)
  const currentSchoolYearLevels = levels.filter(
    (item: any) =>
      currentStoreData?.levels?.find((level) => level.level === item.level) &&
      !activeLevels.find((level: any) => level.level === item.level)
  );

  // The options to be used in the Level Select dropdown
  const levelsOptions = activeLevels
    .concat(currentSchoolYearLevels)
    .sort((a: any, b: any) => (a.level < b.level ? -1 : 1));

  const {
    updateStore,
    closeModal,
    submitBtnRef,
    submitViaBtnRefClick,
  } = useStore();

  const type =
    currentStoreData && currentStoreData.client_id ? "update" : "create";

  const allStores = useSelector(getAllStores);
  const commodityListWfpInProfile = useSelector(
    getCurrentSchoolProfileWfpCommodities
  );

  // We don't need stock rebalancing for now
  // const wfpCommoditiesIndex = useSelector(commoditiesDuck.getIndex);
  // const commoditiesListAll = getCommoditiesInStoreByCategory(
  //   allStores,
  //   wfpCommoditiesIndex,
  //   true,
  //   deliveryCategory
  // );

  const correspondingDelivery = allStores.find((store: any) => {
    const date = store?.delivered_at?.split("T")[0];
    const startsOn = moment(currentStoreData?.starts_on).format("YYYY-MM-DD");
    return (
      store.model === "delivery" &&
      date === startsOn &&
      store.is_initial_stock === true &&
      store.type !== "delete"
    );
  });

  // We don't need stock rebalancing for now
  // const correspondingRebalance = allStores.find((store) => {
  //   const date = store?.delivered_at?.split("T")[0];
  //   const startsOn = moment(currentStoreData?.starts_on).format("YYYY-MM-DD");
  //   return (
  //     store.model === "delivery" &&
  //     date === startsOn &&
  //     store.is_negative_stock_rebalance === true &&
  //     store.type !== "delete"
  //   );
  // });

  const correspondingIncidentByCommodity = (
    commodities: any,
    reason: any,
    causes: any
  ) =>
    allStores.find((store) => {
      if (store.model !== "incident") return false;
      const date = store?.occurred_at?.split("T")[0];
      const occurred_at = moment(currentStoreData?.starts_on).format(
        "YYYY-MM-DD"
      );
      let matchedCommodity = false;
      store.commodities.forEach((storeCommodities) => {
        commodities.forEach((newIncidentCommodities: any) => {
          if (storeCommodities.commodity === newIncidentCommodities.commodity) {
            matchedCommodity = true;
          }
        });
      });
      let matchedCause = false;
      store.causes.forEach((storeCauses) => {
        causes.forEach((newIncidentCauses: any) => {
          if (storeCauses === newIncidentCauses) {
            matchedCause = true;
          }
        });
      });
      return (
        date === occurred_at &&
        store.is_initial_stock_incident === true &&
        matchedCommodity &&
        matchedCause &&
        parseInt(store?.reason) === parseInt(reason) &&
        store.type !== "delete"
      );
    });

  // We don't need stock rebalancing for now
  // const existingStocks = commoditiesListAll.map((commodity) => {
  //   const stocks = stockValueByDateByCommodityByCategory(
  //     allStores,
  //     moment(),
  //     commodity.id,
  //     deliveryCategory
  //   );
  //   return { commodity: commodity.id, quantity: stocks };
  // });

  // We don't need stock rebalancing for now
  // const negativeExistingStocks = existingStocks
  //   .map((item) => {
  //     item.quantity = parseFloat(item.quantity.toFixed(3));
  //     return item;
  //   })
  //   .filter((item) => item.quantity < 0);

  const commoditiesList =
    !firstSchoolYear || (firstSchoolYear && currentStoreData)
      ? Object.keys(carryOverStock)
          .map((commodityId) => {
            const commodity = carryOverStock[commodityId];
            // const quantity = carryOverStock[commodityId];
            return {
              commodity: parseInt(commodityId),
              quantity: commodity.quantity,
              measure_unit: commodity.measure_unit,
              category: deliveryCategory,
            };
          })
          .filter((item) => item.quantity >= 0)
      : [{}];

  const commodityListWfpIds = commodityListWfpInProfile.map(
    (item: any) => item.id
  );
  const {
    control,
    handleSubmit,
    errors,
    register,
    watch,
    getValues,
    setValue,
  } = useForm({
    defaultValues: currentStoreData
      ? storeToFormValues({
          ...currentStoreData,
          commodities: commoditiesList,
        } as any)
      : { levels: [{}], commodities: commoditiesList || [{}] },
    resolver: schoolYearValidationResolver,
    context: {
      currentStoreData,
      allStores,
      commodityListWfpIds,
      isLocked,
    },
  } as any) as any;
  const { fields: levelRows, append, remove } = useFieldArray({
    control,
    name: "levels",
  });

  let { fields: openingStockCommodityRows } = useFieldArray({
    control,
    name: "commodities",
  });

  let { fields: newCommodities, append: appendNewCommodity } = useFieldArray({
    control,
    name: "newCommodities",
  });

  const morningChecked = watch("has_morning_classes");
  const afternoonChecked = watch("has_afternoon_classes");
  const startsOnWatch = watch("starts_on");

  const onSubmit = (formValues: any) => {
    const values = {
      ...formValues,
      commodities: [],
    };

    const date = moment(values.starts_on).format("YYYY-MM-DD");
    const time = "13:00";
    const dateTime = moment(date + " " + time);
    if (isInitialStockEditable) {
      if (formValues.hasOwnProperty("commodities")) {
        values.commodities = [...formValues.commodities];
      }

      if (formValues.hasOwnProperty("newCommodities")) {
        values.commodities = [
          ...values.commodities,
          ...formValues.newCommodities,
        ];
      }

      if (
        !currentStoreData ||
        (currentStoreData && currentStoreData.starts_on !== startsOnWatch)
      ) {
        // Only perform this when the schoolYear is initially created or when the starts_on is changed
        const initialStock = createInitialStock(
          values,
          initialCarryOverStock,
          dateTime,
          firstSchoolYear
        );

        if (initialStock?.commodities && initialStock?.commodities.length > 0) {
          updateStore({
            id:
              (correspondingDelivery && correspondingDelivery.client_id) ||
              undefined,
            values: initialStock,
            model: "delivery",
            type:
              correspondingDelivery && correspondingDelivery.client_id
                ? "update"
                : "create",
            category: deliveryCategory,
          });
        }
      }
      // We don't need stock rebalancing for now
      // const stockRebalance = createStockRebalance(
      //   values,
      //   carryOverStock,
      //   dateTime,
      //   negativeExistingStocks
      // );

      // if (stockRebalance?.commodities.length > 0) {
      //   updateStore({
      //     id:
      //       (correspondingRebalance && correspondingRebalance.client_id) ||
      //       undefined,
      //     values: stockRebalance,
      //     model: "delivery",
      //     is_negative_stock_rebalance: true,
      //     type:
      //       correspondingRebalance && correspondingRebalance.client_id
      //         ? "update"
      //         : "create",
      //     category: deliveryCategory,
      //   });
      // }

      let incidentList = createIncident(
        values,
        initialCarryOverStock,
        dateTime,
        incidentCauses,
        initialStockIncidents
      );

      // Check for any incidents' updates after the last adjustment
      incidentList = updateExistingIncidents(
        incidentList,
        values,
        carryOverStock,
        initialCarryOverStock,
        initialStockIncidents
      );

      if (incidentList?.length > 0) {
        incidentList.forEach((incident: any) => {
          if (!incident) return;
          const correspondingIncident: any = correspondingIncidentByCommodity(
            incident.commodities,
            incident.reason,
            incident.causes
          );

          let updatedIncidentValues = correspondingIncident
            ? aggregateCommoditiesByQuantityAndBatchNo(
                correspondingIncident as Incident,
                incident
              )
            : incident;

          updatedIncidentValues.commodities = updatedIncidentValues?.commodities.filter(
            (item: any) => parseFloat(parseFloat(item.quantity).toFixed(3)) > 0
          );

          if (updatedIncidentValues?.commodities?.length === 0) {
            // destroy the incident object
            correspondingIncident?.sync === false && !correspondingIncident?.id
              ? dispatch(resetStore(correspondingIncident.client_id))
              : updateStore({
                  id: correspondingIncident.client_id,
                  values: { ...correspondingIncident, occurred_at: date },
                  model: correspondingIncident.model,
                  type: "delete",
                });
          } else {
            // update the incident object
            updateStore({
              id: correspondingIncident && correspondingIncident?.client_id,
              values: { ...updatedIncidentValues, occurred_at: date },
              model: "incident",
              is_initial_stock_incident: true,
              type:
                correspondingIncident && correspondingIncident?.client_id
                  ? "update"
                  : "create",
              category: deliveryCategory,
            });
          }
        });
      }
    }

    delete values.commodities;
    updateStore({
      id: (currentStoreData && currentStoreData.client_id) || undefined,
      values: formValuesToStore(values),
      model: "year",
      type: type,
      category: deliveryCategory,
    });
  };

  const weekdays = moment.weekdays();
  weekdays.push(weekdays.shift()!);

  const weekdayNotChecked = () => {
    if (moment().day() === ("null" as any)) {
      return { requiredLabelClasses };
    }
  };

  const requiredLabelClasses = (
    <div
      className="wfp--form-required"
      style={{ borderColor: " 2px solid #C5192D" }}
    >
      <FormattedMessage
        id="DeliveriesEdit.commodityErrorOptions"
        defaultMessage="* Select at least one of these options"
      />
    </div>
  );

  const setSelectedStockCommodity = (id: any, key: any, value: any) => {
    let record: any = newCommodities.find((item) => item.id === id);
    record[key] = value;
  };

  const setSelectedStockQuantity = (
    id: any,
    key: any,
    value: any,
    index: any
  ) => {
    const record = !firstSchoolYear
      ? openingStockCommodityRows.find((item) => item.id === id)
      : undefined;

    if (!record) {
      return;
    }

    const initialCarryOverStockQuantity = Object.keys(
      initialCarryOverStock
    ).includes(record.commodity.toString())
      ? parseFloat(
          parseFloat(
            initialCarryOverStock[record.commodity]["totalQuantity"] || 0
          ).toFixed(3)
        )
      : undefined;

    const adjustedCarryOverStockQuantity = Object.keys(carryOverStock).includes(
      record?.commodity.toString()
    )
      ? parseFloat(
          parseFloat(carryOverStock[record.commodity].quantity || 0).toFixed(3)
        )
      : undefined;

    switch (true) {
      // If the value is greater than the initial value, mark the index as true in
      // showQuantityExceededWarning and as false in the showIncidentForCommodity
      case value > (initialCarryOverStockQuantity || 0):
        showQuantityExceededWarning.current = {
          ...showQuantityExceededWarning.current,
          [index]: true,
        };
        showIncidentForCommodity.current = {
          ...showIncidentForCommodity.current,
          [index]: false,
        };

        break;
      // If the value is the same as the initial value, mark the index as false in
      // showQuantityExceededWarning and as false in the showIncidentForCommodity
      case value <= (initialCarryOverStockQuantity || 0) &&
        value >= (adjustedCarryOverStockQuantity || 0):
        showQuantityExceededWarning.current = {
          ...showQuantityExceededWarning.current,
          [index]: false,
        };
        showIncidentForCommodity.current = {
          ...showIncidentForCommodity.current,
          [index]: false,
        };
        break;
      // If the value is smaller than the adjusted value, mark the index as false in
      // showQuantityExceededWarning and as true in the showIncidentForCommodity
      case value < (adjustedCarryOverStockQuantity || 0):
        showQuantityExceededWarning.current = {
          ...showQuantityExceededWarning.current,
          [index]: false,
        };
        showIncidentForCommodity.current = {
          ...showIncidentForCommodity.current,
          [index]: true,
        };
        break;
      default:
        break;
    }

    if (value > initialCarryOverStockQuantity!) {
      value = initialCarryOverStockQuantity;
    }
    updatedIncidentQuantities.current = {
      ...updatedIncidentQuantities.current,

      [record.commodity]: parseFloat(
        (
          parseFloat((initialCarryOverStockQuantity as unknown) as string) -
          parseFloat(value)
        ).toFixed(3)
      ),
    };
  };

  const checkCurrentQuantityAgainstInitialQuantity = (
    commodity: number,
    index: number
  ) => {
    // Checks if the current quantity (from the input field) is smaller than the
    // initial quantity
    return (
      parseFloat(
        parseFloat(
          initialCarryOverStock[commodity]?.totalQuantity || 0
        ).toFixed(3)
      ) >
      parseFloat(
        parseFloat(
          Object.keys(getValues()).includes("commodities")
            ? getValues()["commodities"][index]?.quantity || 0
            : initialCarryOverStock[commodity]?.totalQuantity || 0
        ).toFixed(3)
      )
    );
  };

  return (
    <ModalExtended
      onRequestSubmit={submitViaBtnRefClick}
      onRequestClose={() => closeModal()}
      modalLabel={intl.formatMessage({
        id: `YearEdit.edit_school_year`,
        defaultMessage: `Edit school year`,
      })}
      modalHeading={intl.formatMessage({
        id: `YearEdit.edit_school_year`,
        defaultMessage: `Edit school year`,
      })}
      primaryButtonText={intl.formatMessage({
        id: `YearEdit.save_school_year`,
        defaultMessage: `Save school year`,
      })}
      primaryButtonDisabled={
        !!Object.keys(
          showQuantityExceededWarning.current
            ? showQuantityExceededWarning.current
            : {}
        ).find((index) => showQuantityExceededWarning.current[index] === true)
      }
      className="year-edit"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="wfp--form wfp--form-long">
          <ErrorList errors={errors} />
          <Blockquote kind="info">
            <p className={isInitialStockEditable ? "enabled" : "disabled"}>
              <FormattedMessage
                id="YearEdit.newYearMessage"
                defaultMessage="When a new school year is created, you are no longer able to edit previous years data."
              />
            </p>
            <p className={!isInitialStockEditable ? "enabled" : "disabled"}>
              <FormattedMessage
                id="YearEdit.newYearMessageUneditable"
                defaultMessage="Here you can edit the end date of the school year, if required. If you need to change any other school year information, please close this school year (by setting the end date to today or any day in the past without excluding days for which you already entered data) and create a new one."
              />
            </p>
          </Blockquote>

          <Controller
            as={<DateInput />}
            min={previousYearEndDate}
            max={null}
            name="starts_on"
            labelText={
              <FormattedMessage
                id="yearShow.start_date"
                defaultMessage="Start date"
              />
            }
            control={control}
            disabled={isLocked || !isInitialStockEditable}
          />
          <Controller
            as={<DateInput />}
            min={startsOnWatch || previousYearEndDate}
            max={null}
            name="ends_on"
            labelText={
              <FormattedMessage
                id="yearShow.end_date"
                defaultMessage="End date"
              />
            }
            control={control}
          />
          <div className={isInitialStockEditable ? "enabled" : "disabled"}>
            <div className="classes-info">
              <RadioButtonGroup
                required
                labelText={
                  <FormattedMessage
                    id="yearShow.school_days"
                    defaultMessage="School days"
                  />
                }
                helperText={
                  <FormattedMessage
                    id="YearEdit.on_which_days_is_school_open"
                    defaultMessage="On which days is the school open"
                  />
                }
              >
                {weekdays.map((weekday, i) => (
                  <Controller
                    control={control}
                    render={({ onChange, value }) => (
                      <Checkbox
                        key={weekday}
                        labelText={weekday}
                        id={`weekdays[${i}]`}
                        name={`weekdays[${i}]`}
                        disabled={isLocked}
                        checked={value || false}
                        onChange={(e: BaseSyntheticEvent) =>
                          onChange(e.target.checked)
                        }
                      />
                    )}
                    defaultValue={false}
                    name={`weekdays[${i}]`}
                  />
                ))}
              </RadioButtonGroup>
              <div>
                {errors?.weekdaysSome &&
                  weekdayNotChecked() &&
                  requiredLabelClasses}
              </div>
            </div>

            <div className="classes-info">
              <RadioButtonGroup
                labelText={intl.formatMessage({
                  id: `YearEdit.classes`,
                  defaultMessage: `Classes`,
                })}
                name="shiftsSome"
              >
                <Checkbox
                  labelText={
                    <FormattedMessage
                      id="YearEdit.morning_classes"
                      defaultMessage="Morning classes"
                    />
                  }
                  inputRef={register}
                  id="has_morning_classes"
                  name="has_morning_classes"
                  disabled={isLocked}
                />
                <Checkbox
                  labelText={
                    <FormattedMessage
                      id="YearEdit.afternoon_classes"
                      defaultMessage="Afternoon classes"
                    />
                  }
                  inputRef={register}
                  id="has_afternoon_classes"
                  name={"has_afternoon_classes"}
                  disabled={isLocked}
                />
              </RadioButtonGroup>
              <div>
                {errors?.shiftsSome &&
                  !morningChecked &&
                  !afternoonChecked &&
                  requiredLabelClasses}
              </div>
            </div>
          </div>

          {morningChecked && afternoonChecked && (
            <div className={isInitialStockEditable ? "enabled" : "disabled"}>
              <RadioButtonGroup
                labelText={
                  <FormattedMessage
                    id="YearEdit.afternoon_classes"
                    defaultMessage="Afternoon classes"
                  />
                }
              >
                <Checkbox
                  labelText={
                    <FormattedMessage
                      id="YearEdit.same_students_in_afternoon_as_morning"
                      defaultMessage="Same students in afternoon as in morning classes"
                    />
                  }
                  id="has_same_students_in_both_shifts"
                  name="has_same_students_in_both_shifts"
                  inputRef={register}
                  disabled={isLocked}
                />
              </RadioButtonGroup>
            </div>
          )}

          <div className={isInitialStockEditable ? "enabled" : "disabled"}>
            <Gate isForbidden={isLocked}>
              <Repeater>
                <RepeaterTitle>
                  <FormattedMessage
                    id="YearEdit.school_levels"
                    defaultMessage="Level Selection"
                  />
                </RepeaterTitle>

                {levelRows.map((levelRow, i) => {
                  const inactiveLevelButInSchoolYear = currentSchoolYearLevels.find(
                    (item: any) => item.level === levelRow.level
                  );
                  return (
                    <RepeaterItem
                      key={levelRow.id}
                      className="year-edit-repeater"
                      disableRemove={levelRows.length === 1}
                      removeAction={() => remove(i)}
                    >
                      <div className="repeater-wrapper">
                        {inactiveLevelButInSchoolYear && (
                          <Blockquote kind="warning">
                            <FormattedMessage
                              id="YearEdit.inactiveLevelWarning"
                              defaultMessage="This level is currently inactive."
                            />
                          </Blockquote>
                        )}
                        <div className={styles.yearRepeaterLevel}>
                          <Input
                            invalid={errors[`levels[${i}].level`] !== undefined}
                            invalidText={
                              errors[`levels[${i}].level`] &&
                              errors[`levels[${i}].level`].message
                            }
                          >
                            {() => (
                              <Select
                                labelText={
                                  <FormattedMessage
                                    id="YearEdit.levelsSelectLabelText"
                                    defaultMessage="Select"
                                  />
                                }
                                inputRef={register()}
                                className="level"
                                name={`levels[${i}].level`}
                              >
                                <SelectItem
                                  value=""
                                  text={intl.formatMessage({
                                    id: `YearEdit.select__level`,
                                    defaultMessage: `Levels`,
                                  })}
                                />
                                {levelsOptions.map(
                                  ({ value, level, custom_name }: any) => {
                                    let label = intl.formatMessage({
                                      id: `Common.${value ? value : level}`,
                                    });

                                    if (custom_name) {
                                      label = custom_name;
                                    }

                                    return (
                                      <SelectItem
                                        key={value ? value : level}
                                        value={value ? value : level}
                                        text={label}
                                      />
                                    );
                                  }
                                )}
                              </Select>
                            )}
                          </Input>
                        </div>

                        <h4 className={styles.yearRepeaterTitle}>
                          <FormattedMessage
                            id="YearEdit.initial_enrolment"
                            defaultMessage="Initial enrolment"
                          />
                        </h4>

                        <div className={styles.yearRepeaterEnrolment}>
                          {studentKinds.map((kind) => (
                            <Controller
                              key={kind.key}
                              as={<NumberInput />}
                              hideControls
                              control={control}
                              styles={
                                errors?.hasOwnProperty(kind.key)
                                  ? selectContainerStyles.invalid
                                  : selectContainerStyles.valid
                              }
                              labelText={kind.labelTrans}
                              inputRef={register()}
                              min={0}
                              name={`levels[${i}].initial_${kind.key}`}
                              invalid={
                                errors[`levels[${i}].initial_${kind.key}`]
                              }
                              invalidText={
                                errors[`levels[${i}].initial_${kind.key}`] &&
                                errors[`levels[${i}].initial_${kind.key}`]
                                  .message
                              }
                            />
                          ))}
                        </div>
                      </div>
                    </RepeaterItem>
                  );
                })}
              </Repeater>

              <AddButton
                text={intl.formatMessage({
                  id: `YearEdit.add_school_level`,
                  defaultMessage: `Add school level`,
                })}
                onClick={() => append({})}
              />
            </Gate>
          </div>
          <div className={isInitialStockEditable ? "enabled" : "disabled"}>
            <div>
              <Repeater>
                <RepeaterTitle>
                  <FormattedMessage
                    id="YearEdit.openingStock"
                    defaultMessage="Opening stock"
                  />
                </RepeaterTitle>
                <Blockquote kind="info">
                  <p>
                    <FormattedMessage
                      id="YearEdit.openingStockInfo"
                      defaultMessage="Please insert the food stock levels on the school year start date, which you entered above. This is needed to ensure, that the stock levels in the system always correspond to your real stock levels."
                    />
                  </p>
                </Blockquote>
                <Blockquote kind="warning">
                  <p>
                    <FormattedMessage
                      id="YearEdit.openingStockRebalance"
                      defaultMessage="Please note, that the system is also showing you any remaining carry-over stock from last school year. You can adjust these stock levels here, if needed. If for any reason you had a negative stock level in your previous school year, it will automatically be rebalanced to zero and not show up here."
                    />
                  </p>
                </Blockquote>
                {(!firstSchoolYear || (firstSchoolYear && currentStoreData)) &&
                  openingStockCommodityRows.map((commodityRow, index) => (
                    <RepeaterItem
                      key={commodityRow.id}
                      disableRemove={openingStockCommodityRows.length === 1}
                      className="delivery-edit__commodities"
                    >
                      <Controller
                        render={({ onChange }) => {
                          return (
                            <CommodityTextView
                              className="wfp--react-select-container"
                              category={deliveryCategory}
                              key={`commodities[${index}].commodity`}
                              name={`commodities[${index}].commodity`}
                              //onChange={(e: {value: string}) => {
                              //  onChange(e.value);
                              //  setSelectedStockCommodity(
                              //    commodityRow.id,
                              //    "commodity",
                              //    e.value
                              //  );
                              //}}
                              value={commodityRow?.commodity}
                              control={control}
                              invalidOverride={
                                errors?.commodities &&
                                errors?.commodities[index]
                                  ? errors.commodities[index].commodity
                                      ?.message !== undefined
                                  : false
                              }
                              invalidTextOverride={requiredLabelCommodities}
                            />
                          );
                        }}
                        name={`commodities[${index}].commodity`}
                        control={control}
                        rules={{
                          required: intl.formatMessage({
                            id: "Form.required",
                            defaultMessage: "Required",
                          }),
                        }}
                      />
                      <div className="quantity-container">
                        <Controller
                          render={({ onChange, onBlur, value }) => {
                            return (
                              <QuantityInput
                                control={control}
                                value={value}
                                onChange={(e) => {
                                  watch(`commodities[${index}].quantity`);
                                  onChange(
                                    parseFloat(
                                      (parseFloat(e.target.value) || 0).toFixed(
                                        3
                                      )
                                    )
                                  );
                                  setSelectedStockQuantity(
                                    commodityRow.id,
                                    "quantity",
                                    parseFloat(
                                      (parseFloat(e.target.value) || 0).toFixed(
                                        3
                                      )
                                    ),
                                    index
                                  );
                                }}
                                onBlur={onBlur}
                                labelText={
                                  !firstSchoolYear &&
                                  checkCurrentQuantityAgainstInitialQuantity(
                                    commodityRow?.commodity,
                                    index
                                  ) ? (
                                    <FormattedMessage
                                      id="YearEdit.adjustedCarryOverQuantity"
                                      defaultMessage="Adjusted carry-over quantity"
                                    />
                                  ) : (
                                    <FormattedMessage
                                      id="DeliveriesEdit.quantity"
                                      defaultMessage="Quantity"
                                    />
                                  )
                                }
                                setValue={setValue}
                                name={`commodities[${index}].quantity`}
                                invalid={
                                  errors[`commodities[${index}].quantity`]
                                }
                                invalidText={
                                  errors[`commodities[${index}].quantity`]
                                    ?.message
                                }
                                measureUnit={watch(
                                  `commodities[${index}].measure_unit`
                                )}
                              />
                            );
                          }}
                          control={control}
                          name={`commodities[${index}].quantity`}
                        />
                        {!firstSchoolYear &&
                          checkCurrentQuantityAgainstInitialQuantity(
                            commodityRow?.commodity,
                            index
                          ) && (
                            <div className="wfp--form-item initial-stock-label">
                              <div className="wfp--label">
                                <FormattedMessage
                                  id="YearEdit.initalCarryOverQuantity"
                                  defaultMessage="Initial carry-over quantity"
                                />
                              </div>
                              <div className="inner-label-container">
                                <label>
                                  {parseFloat(
                                    parseFloat(
                                      initialCarryOverStock[
                                        commodityRow?.commodity
                                      ]?.totalQuantity || 0
                                    ).toFixed(3)
                                  )}
                                </label>
                              </div>
                            </div>
                          )}
                        {!firstSchoolYear &&
                          parseFloat(
                            parseFloat(
                              updatedIncidentQuantities.current[
                                commodityRow?.commodity
                              ] || 0
                            ).toFixed(3)
                          ) > 0 && (
                            <div className="wfp--form-item  incidents-quantity-label">
                              <div className="wfp--label">
                                <FormattedMessage
                                  id="YearEdit.incidentsQuantity"
                                  defaultMessage="Incidents quantity"
                                />
                              </div>
                              <div className="inner-label-container">
                                <label>
                                  {parseFloat(
                                    parseFloat(
                                      updatedIncidentQuantities.current[
                                        commodityRow?.commodity
                                      ] || 0
                                    ).toFixed(3)
                                  )}
                                </label>
                              </div>
                            </div>
                          )}
                      </div>
                      {showIncidentForCommodity?.current?.hasOwnProperty(
                        index
                      ) &&
                        showIncidentForCommodity.current[index] === true && (
                          <div>
                            <Blockquote kind="warning">
                              <p>
                                <FormattedMessage
                                  id="YearEdit.incidentCreationOpeningStock"
                                  defaultMessage="An incident will be created as you have inputted a value less than the carry-over stock."
                                />
                              </p>
                            </Blockquote>
                            <YearEditIncidentReasons
                              control={control}
                              errors={errors}
                              index={index}
                              register={register}
                            />
                          </div>
                        )}
                      {showQuantityExceededWarning?.current?.hasOwnProperty(
                        index
                      ) &&
                        showQuantityExceededWarning.current[index] === true && (
                          <div>
                            <Blockquote kind="error">
                              <p>
                                <FormattedMessage
                                  id="YearEdit.quantityExceededWarning"
                                  defaultMessage="You cannot increase the quantity of a carry-over stock."
                                />
                              </p>
                            </Blockquote>
                          </div>
                        )}
                    </RepeaterItem>
                  ))}
                {firstSchoolYear &&
                  newCommodities.map((commodityRow, index) => (
                    <RepeaterItem
                      key={commodityRow.id}
                      disableRemove={newCommodities.length === 1}
                      className="delivery-edit__commodities"
                    >
                      <Controller
                        render={({ onChange }) => {
                          return (
                            <CommoditySelect
                              className={"wfp--react-select-container" as any}
                              wfpOnly
                              category={deliveryCategory}
                              required
                              key={`newCommodities[${index}].commodity`}
                              onChange={(e: any) => {
                                setValue(
                                  `newCommodities[${index}].commodity`,
                                  e.commodity
                                );
                                setValue(
                                  `newCommodities[${index}].measure_unit`,
                                  e.measure_unit
                                );
                                onChange(e.value);
                                setSelectedStockCommodity(
                                  commodityRow.id,
                                  "commodity",
                                  e.value
                                );
                              }}
                              value={commodityRow?.commodity}
                              control={control}
                              invalidOverride={
                                errors?.commodities &&
                                errors?.commodities[index]
                                  ? errors.commodities[index].commodity
                                      ?.message !== undefined
                                  : false
                              }
                              invalidTextOverride={requiredLabelCommodities}
                              invalidOptions={openingStockCommodityRows as any}
                            />
                          );
                        }}
                        name={`newCommodities[${index}].commodity`}
                        control={control}
                        rules={{
                          required: intl.formatMessage({
                            id: "Form.required",
                            defaultMessage: "Required",
                          }),
                        }}
                      />
                      <Controller
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <QuantityInput
                              control={control}
                              value={value}
                              onChange={(e) => {
                                watch(`commodities[${index}].quantity`);
                                onChange(
                                  parseFloat(
                                    (parseFloat(e.target.value) || 0).toFixed(3)
                                  )
                                );
                                setSelectedStockQuantity(
                                  commodityRow.id,
                                  "quantity",
                                  parseFloat(
                                    (parseFloat(e.target.value) || 0).toFixed(3)
                                  ),
                                  index
                                );
                              }}
                              onBlur={onBlur}
                              labelText={
                                <FormattedMessage
                                  id="DeliveriesEdit.quantity"
                                  defaultMessage="Quantity"
                                />
                              }
                              setValue={setValue}
                              name={`newCommodities[${index}].quantity`}
                              invalid={
                                errors[`newCommodities[${index}].quantity`]
                              }
                              invalidText={
                                errors[`newCommodities[${index}].quantity`]
                                  ?.message
                              }
                              measureUnit={watch(
                                `newCommodities[${index}].measure_unit`
                              )}
                            />
                          );
                        }}
                        control={control}
                        name={`newCommodities[${index}].quantity`}
                      />

                      <Controller
                        name={`newCommodities[${index}].measure_unit`}
                        control={control}
                      />
                      {showIncidentForCommodity?.current?.hasOwnProperty(
                        index
                      ) &&
                        showIncidentForCommodity.current[index] === true && (
                          <div>
                            <Blockquote kind="warning">
                              <p>
                                <FormattedMessage
                                  id="YearEdit.incidentCreationOpeningStock"
                                  defaultMessage="An incident will be created as you have inputted a value less than the carry-over stock."
                                />
                              </p>
                            </Blockquote>
                            <YearEditIncidentReasons
                              control={control}
                              errors={errors}
                              index={index}
                              register={register}
                            />
                          </div>
                        )}
                    </RepeaterItem>
                  ))}
              </Repeater>
              {firstSchoolYear && (
                <CommoditiesAddButton
                  onClick={() => {
                    appendNewCommodity({});
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <button className="hidden-btn" ref={submitBtnRef as any} />
      </form>
    </ModalExtended>
  );
};

export default YearEdit;
